<div class="flex items-center justify-between">
    <span class="flex flex-grow flex-col">
        <span class="text-base font-medium leading-6 text-gray-900" id="availability-label">Form Status</span>
        <span class="text-sm text-gray-500" id="availability-description">Control if the form should be available for
            participants to use or not.</span>
    </span>
  
    <div class="inline-flex justify-center items-center gap-x-1">
      <span *ngIf="saving">
        <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none"
          viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
          </path>
        </svg>
      </span>
      <button type="button" (click)="toggle()" [ngClass]="{
        'bg-main': isEnabled,
        'bg-gray-200': !isEnabled
      }"
        class="bg-gray-200 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-main focus:ring-offset-2"
        role="switch" aria-checked="false" aria-labelledby="availability-label"
        aria-describedby="availability-description">
        <span aria-hidden="true" [ngClass]="{
          'translate-x-5': isEnabled,
          'translate-x-0': !isEnabled
        }"
            class="translate-x-0 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"></span>
    </button>
    </div>
    
</div>