import { NewTicketRequest } from "./NewTicketRequest";

export class EditTicketRequest extends NewTicketRequest {
    public constructor(
        public override formId: string = '',
        public ticketId: number = 0,
        public override name: string = '',
        public override description: string = '',
        public override regular_price: string = '',
        public override stock_quantity: number = 1,
        public override includes: string[] = [],
        public override mostPopular: boolean = false,
        public override currency: string = 'USD'
    ) {
        super();
    }
}

