import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NotificationService } from 'src/app/shared/components/notifications/notification.service';
import { defaultImageTypesSupported } from 'src/app/shared/model/accepted-upload-formats';
import { NotificationType } from 'src/app/shared/model/enum/notification-type.enum';
import { Event } from 'src/app/shared/model/event/event.model';
import { AppSettingService } from 'src/app/shared/service/app-setting.service';
import { EventService } from 'src/app/shared/service/event.service';
import { FileUploaderService } from 'src/app/shared/service/file-uploader.service';
import { LastUpdatesTrackerService } from 'src/app/shared/service/last-updates-tracker.service';

@Component({
  selector: 'event-cover',
  templateUrl: './event-cover.component.html',
  styleUrls: ['./event-cover.component.scss', '../event-information.component.scss']
})
export class EventCoverComponent implements OnInit, OnDestroy {
  event: Event;
  eventSub: Subscription;
  saving: boolean = false;

  constructor(
    private fileUploader: FileUploaderService,
    private eventService: EventService,
    private appSettings: AppSettingService,
    private notificationService: NotificationService,
    private updateTracker: LastUpdatesTrackerService
  ) { }

  ngOnInit(): void {
    this.eventSub = this.eventService.selectedEvent.subscribe({
      next: event => {
        this.event = event;
      },
    });
  }

  ngOnDestroy(): void {
    if (this.eventSub) this.eventSub.unsubscribe();
  }

  onSelectLogo(event: any) {
    this.saving = true;
    const endpoint: string = `event/upload-logo`;
    this.uploadCoverImage(event, endpoint);
  }

  onSelectBanner(event: any) {
    this.saving = true;
    const endpoint: string = `event/upload-banner`;
    this.uploadCoverImage(event, endpoint);
  }

  onSelectBackground(event: any) {
    this.saving = true;
    const endpoint: string = `event/upload-background`;
    this.uploadCoverImage(event, endpoint);
  }

  uploadCoverImage(selected: any, url: string) {
    const eventId: string = this.eventService.selectedEvent.value.id;
    const endpoint: string = `${url}/${eventId}`;
    this.fileUploader.onFileSelect<Event>(selected, endpoint, this.appSettings.settings.maxImageSizeMo, defaultImageTypesSupported).subscribe({
      next: event => {
        this.saving = false;
        if (event) {
          this.eventService.setSelectedEvent(event);
          this.notificationService.notify('Update Cover', 'Event saved', NotificationType.Success);
        };
      },
      error: error => {
        this.saving = false;
        this.notificationService.notify('Update Cover', 'Event not saved', NotificationType.Error);
        console.log(error);
      }
    });
  }

  isLogoExists() {
    return this.event?.theme?.logo?.name?.length > 0;
  }

  isBannerExists() {
    return this.event?.theme?.banner && this.event?.theme?.banner?.name?.length > 0;
  }

  isBackgroundExists() {
    return this.event?.theme?.background?.name?.length > 0;
  }

  trackUpdate(menu: string) {
    this.updateTracker.newUpdate(menu);
  }
}
