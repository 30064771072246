<div>
  <div class="space-y-12">
    <div class="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-1">
        <div class="flex flex-col justify-between items-start gap-y-2.5 lg:flex-row">
            <div>
              <h2
                class="text-xl font-semibold leading-7 text-gray-900">Form Builder</h2>
              <p class="mt-1 text-sm leading-6 text-gray-600">This form will be displayed publicly so be careful what
                you share.</p>
            </div>
              <button
              (click)="toggleSlideOver()"
                type="button"
                class="inline-flex items-center justify-center gap-x-1.5 w-full lg:w-32 rounded-md bg-main px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-main/75 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main">
                <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                  stroke-width="1.5" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                </svg>
                New Field
              </button>
          </div>
      <div class="relative flex justify-start gap-x-5">
        <div
          *ngIf="ready"
          class="w-full">
          <div>
            <form
              [formGroup]="formDefinition"
              (submit)="onSubmit()">
              <formly-form
                [model]="model"
                [fields]="fields"
                [form]="formDefinition">
              </formly-form>
            </form>
          </div>

          <div class="mt-7 flex justify-center">
            <button
              (click)="submit()"
              type="button"
              class="w-2/3 rounded-md bg-main px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-main/70 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>


<div class="relative z-50" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
    <div *ngIf='isSlideOver' class="fixed inset-0 bg-slate-200 opacity-60"></div>

    <div @translateX *ngIf='isSlideOver' class="fixed inset-0 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden">
        <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
          <div @translateX *ngIf='isSlideOver' class="pointer-events-auto w-screen max-w-md">
            <div class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
              <div class="px-4 sm:px-6">
                <div class="flex items-start justify-between">
                  <h2 class="text-base font-semibold leading-6 text-slate-700" id="slide-over-title">New Field</h2>
                  <div class="ml-3 flex h-7 items-center">
                    <button type="button" (click)="toggleSlideOver()" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      <span class="absolute -inset-2.5"></span>
                      <span class="sr-only">Close panel</span>
                      <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div class="relative mt-6 flex-1 px-4 sm:px-6">
                <div class="w-[400px] overflow-y-auto border border-gray-200 rounded-md px-4 py-6">
                  <div class="flex justify-center">
                    <span class="isolate inline-flex rounded-md">
                      <button (click)="onSelectTab('predefined')" type="button" [ngClass]="{'bg-main text-white': selectedTab == 'predefined', 'bg-white text-slate-700': selectedTab != 'predefined'}" class="field-toggle rounded-l-full">Predefined</button>
                      <button (click)="onSelectTab('custom')" type="button" [ngClass]="{'bg-main text-white': selectedTab == 'custom', 'bg-white text-slate-700': selectedTab != 'custom'}" class="field-toggle rounded-r-full">Custom</button>
                    </span>
                  </div>
                  <div class="mt-5">
                    @if (selectedTab == 'predefined') {
                      <form-predefined-field></form-predefined-field>
                    } @else {
                      <form-custom-field></form-custom-field>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
