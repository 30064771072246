import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'floating-banners',
  templateUrl: './floating-banners.component.html',
  styleUrl: './floating-banners.component.scss'
})
export class FloatingBannersComponent {
  @Input('title') title: string;
  @Input('text') text: string;
  @Input('bgColor') bgColor: string = 'bg-gray-900';
  @Input('textColor') textColor: string = 'text-white';
  @Output('onAction') onAction: EventEmitter<boolean> = new EventEmitter(false);

  takeAction(){
    this.onAction.emit(true);
  }

}
