import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { SharedModule } from '../shared/shared.module';

import { ArrayTypeComponent } from './custom/inputs/array.type';
import { CustomCheckboxComponent } from './custom/inputs/custom-checkbox/custom-checkbox.component';
import { CustomGroupedMultiSelectType } from './custom/inputs/custom-grouped-multiselect';
import { CustomMultiSelectType } from './custom/inputs/custom.multi-select';
import { CustomRadioType } from './custom/inputs/custom.radio';
import { CustomUniqueSelectType } from './custom/inputs/custom.unique-select';
import { CustomFieldWrapper } from './custom/inputs/custom.wrapper';
import { FormlyConfirmationComponent } from './custom/inputs/formly-confirmation/formly-confirmation.component';
import { FormlyCountryInputComponent } from './custom/inputs/formly-country-input/formly-country-input.component';
import { FormlyCustomInputComponent } from './custom/inputs/formly-custom-input/formly-custom-input.component';
import { FormlyCustomTextreaComponent } from './custom/inputs/formly-custom-textrea/formly-custom-textrea.component';
import { FormlyIndustryInputComponent } from './custom/inputs/formly-industry-input/formly-industry-input.component';
import { FormlyInterestsInputComponent } from './custom/inputs/formly-interests-input/formly-interests-input.component';
import { FormlyLookingforInputComponent } from './custom/inputs/formly-lookingfor-input/formly-lookingfor-input.component';
import { FormlyOccupationInputComponent } from './custom/inputs/formly-occupation-input/formly-occupation-input.component';
import { FormlyOfferInputComponent } from './custom/inputs/formly-offer-input/formly-offer-input.component';
import { ObjectTypeComponent } from './custom/inputs/object.type';
import { StepperWrapperComponent } from './custom/inputs/stepper-wrapper/stepper-wrapper.component';



@NgModule({
  declarations: [
    CustomUniqueSelectType,
    CustomMultiSelectType,
    CustomGroupedMultiSelectType,
    CustomRadioType,
    StepperWrapperComponent,
    FormlyIndustryInputComponent,
    FormlyLookingforInputComponent,
    FormlyInterestsInputComponent,
    FormlyOfferInputComponent,
    FormlyConfirmationComponent,
    FormlyCountryInputComponent,
    CustomCheckboxComponent,
    ObjectTypeComponent,
    ArrayTypeComponent,
    CustomCheckboxComponent,
    FormlyCustomInputComponent,
    CustomFieldWrapper,
    FormlyCustomTextreaComponent,
    FormlyOccupationInputComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormlyMaterialModule,
    FormlyModule.forRoot({
      wrappers: [
        { name: 'field-wrapper', component: CustomFieldWrapper }
      ],
      types: [
        { name: "custom-input", component: FormlyCustomInputComponent, wrappers: ['field-wrapper'] },
        { name: "formly-custom-textarea", component: FormlyCustomTextreaComponent, wrappers: ['field-wrapper'] },
        { name: "custom-unique-select", component: CustomUniqueSelectType, wrappers: ['field-wrapper'] },
        { name: "custom-multi-select", component: CustomMultiSelectType, wrappers: ['field-wrapper'] },
        { name: "custom-grouped-multi-select", component: CustomGroupedMultiSelectType, wrappers: ['field-wrapper'] },
        { name: "custom-radio-type", component: CustomRadioType, wrappers: ['field-wrapper'] },
        { name: "formly-industry-input", component: FormlyIndustryInputComponent, wrappers: ['field-wrapper'] },
        { name: "formly-lookingfor-input", component: FormlyLookingforInputComponent, wrappers: ['field-wrapper'] },
        { name: "formly-interests-input", component: FormlyInterestsInputComponent, wrappers: ['field-wrapper'] },
        { name: "formly-offer-input", component: FormlyOfferInputComponent, wrappers: ['field-wrapper'] },
        { name: "formly-country-input", component: FormlyCountryInputComponent, wrappers: ['field-wrapper'] },
        { name: "stepper-wrapper", component: StepperWrapperComponent, wrappers: ['field-wrapper'] },
        { name: "formly-confirmation", component: FormlyConfirmationComponent, wrappers: ['field-wrapper'] },
        { name: "custom-checkbox-type", component: CustomCheckboxComponent, wrappers: ['field-wrapper'] },
        { name: "object", component: ObjectTypeComponent, wrappers: ['field-wrapper'] },
        { name: "array", component: ArrayTypeComponent, wrappers: ['field-wrapper'] },
        { name: "formly-occupation-input", component: FormlyOccupationInputComponent, wrappers: ['field-wrapper'] },
      ]
    }),
  ],
  exports: [
    FormlyModule,
  ]
})
export class DynamicFormModule { }
