import { Component, Input, OnInit } from '@angular/core';
import { FormStatus } from 'src/app/shared/model/form/form-configuration.model';
import { Form } from 'src/app/shared/model/form/form.model';
import { UpdateFormConfigurationRequest } from 'src/app/shared/model/form/update-form-configuration.request';
import { RegistrationFormService } from 'src/app/shared/service/registration-form.service';

@Component({
  selector: 'update-form-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit {
  @Input('form') form: Form;
  isEnabled = false;
  saving: boolean = false;

  constructor(
    private registrationFormService: RegistrationFormService,
  ) { }

  ngOnInit(): void {
    this.isEnabled = this.form.formConfiguration.formStatus == FormStatus.Enabled;
  }

  toggle() {
    this.saving = true;
    const request: UpdateFormConfigurationRequest = new UpdateFormConfigurationRequest(this.form.id, 'status');
    this.registrationFormService
      .updateFormConfiguration(request)
      .subscribe({
        next: (form) => {
          this.saving = false;
          this.form = form;
          this.isEnabled = this.form.formConfiguration.formStatus == FormStatus.Enabled;
          this.registrationFormService.setFormToEdit(this.form);
        },
        error: (error) => {
          this.saving = false;
          console.log(error);
        }
      });
    this.isEnabled = !this.isEnabled;
  }
}
