import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FieldBuilderService } from './field-builder.service';

@Injectable({
  providedIn: 'root',
})
export class SchemaSkeletonService {
  private fieldGroupClassName: string = 'mt-10 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-1 pb-8';
  private totalSteps: number = 4;


  constructor(
    private fieldBuilder: FieldBuilderService,
  ){

  }

  private _formFields: FormlyFieldConfig[] = [
    {
      type: 'stepper-wrapper',
      fieldGroup: [
        {
          fieldGroupClassName: this.fieldGroupClassName,
          props: { stepTitle: 'Personal Information', stepSubTitle: 'Personal Infromation SubTitle', totalSteps: this.totalSteps },
          fieldGroup: []
        },
        {
          fieldGroupClassName: this.fieldGroupClassName,
          props: { stepTitle: 'Professional Information', stepSubTitle: 'Pro Infromation SubTitle', totalSteps: this.totalSteps },
          fieldGroup: [],
        },
        {
          fieldGroupClassName: this.fieldGroupClassName,
          props: { stepTitle: 'Preferences Information', stepSubTitle: 'Preferences Infromation SubTitle', totalSteps: this.totalSteps },
          fieldGroup: []
        },
        {
          fieldGroupClassName: this.fieldGroupClassName,
          props: { stepTitle: 'Confirmation', stepSubTitle: 'Review your information', totalSteps: this.totalSteps },
          fieldGroup: [
            this.fieldBuilder.confirmationStep(),
          ]
        },
      ]
    }];

  private _initialFormFields = JSON.stringify(this._formFields);

  get skeleton() {
    return this._formFields;
  }

  get baseSkeleton() {
    return this._initialFormFields;
  }

}
