<dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
  <div class="sm:col-span-1">
    <dt class="text-sm font-medium text-gray-500">Created At</dt>
    <dd class="mt-1 text-sm text-gray-900">
      <time>{{ attendee.createdAt | date: 'MMMM d, y, h:mm:ss a zzzz'}}</time>
    </dd>
  </div>
  <div class="sm:col-span-1">
    <dt class="text-sm font-medium text-gray-500">ID</dt>
    <dd class="mt-1 text-sm text-gray-900">
      {{ attendee.id }}
    </dd>
  </div>
  <div class="sm:col-span-1">
    <dt class="text-sm font-medium text-gray-500">External Reference</dt>
    <dd class="mt-1 text-sm text-gray-900">
      {{ attendee.externalReference }}
    </dd>
  </div>
  <div class="sm:col-span-1">
    <dt class="text-sm font-medium text-gray-500">Phone</dt>
    <dd class="mt-1 text-sm text-gray-900">{{ attendee.mobilePhone }}</dd>
  </div>
  <div class="sm:col-span-1">
    <dt class="text-sm font-medium text-gray-500">Email</dt>
    <dd class="mt-1 text-sm text-gray-900">{{ attendee.email }}</dd>
  </div>
  <div class="sm:col-span-1">
    <dt class="text-sm font-medium text-gray-500">Occupation</dt>
    <dd class="mt-1 text-sm text-gray-900">{{ attendee.occupation }}</dd>
  </div>
  <div class="sm:col-span-1">
    <dt class="text-sm font-medium text-gray-500">Organisation/Company</dt>
    <dd class="mt-1 text-sm text-gray-900">{{ attendee.company }}</dd>
  </div>
  <div class="sm:col-span-1">
    <dt class="text-sm font-medium text-gray-500">Country</dt>
    <dd class="mt-1 text-sm text-gray-900">
      {{ attendee.country?.name }}
    </dd>
  </div>
  <div class="sm:col-span-1">
    <dt class="text-sm font-medium text-gray-500">Address</dt>
    <dd class="mt-1 text-sm text-gray-900">
      {{ attendee.businessCity }}, {{ attendee.address }}
    </dd>
  </div>
  <div class="sm:col-span-1">
    <dt class="text-sm font-medium text-gray-500">Expertise</dt>
    <dd class="mt-1 text-sm text-gray-900">
      <span class="inline-flex items-center rounded-full bg-indigo-100 px-2 py-1 text-xs font-medium text-indigo-700">{{
        attendee.myIndustry?.name }}</span>
    </dd>
  </div>
  <div class="sm:col-span-1">
    <dt class="text-sm font-medium text-gray-500">Offers</dt>
    <dd *ngIf="attendee.myOffers?.length > 0" class="mt-1 text-sm text-gray-900">
      @for (offer of attendee.myOffers; track $index) {
      <span
        class="inline-flex items-center rounded-full bg-indigo-100 px-2 py-1 text-xs font-medium text-indigo-700 mr-1.5 mb-1.5">{{
        offer?.name }}</span>
      }
    </dd>
  </div>
  <div class="sm:col-span-1">
    <dt class="text-sm font-medium text-gray-500">Interests</dt>
    <dd class="mt-1 text-sm text-gray-900">
      @for (interest of attendee.myInterests; track $index) {
      <span
        class="inline-flex items-center rounded-full bg-indigo-100 px-2 py-1 text-xs font-medium text-indigo-700 mr-1.5 mb-1.5">{{
        interest.name }}</span>
      }
    </dd>
  </div>
  <div class="sm:col-span-1">
    <dt class="text-sm font-medium text-gray-500">Looking For</dt>
    <dd class="mt-2 text-sm text-gray-900">
      @for (lookingFor of attendee.lookingFor; track $index) {
      <span
        class="inline-flex items-center rounded-full bg-indigo-100 px-2 py-1 text-xs font-medium text-indigo-700 mr-1.5 mb-1.5">{{
        lookingFor.label }}</span>
      }
    </dd>
  </div>
  <div class="sm:col-span-1">
    <dt class="text-sm font-medium text-gray-500">Groups</dt>
    <dd class="mt-2 text-sm text-gray-900">
      @for (group of attendee.groups; track $index) {
      <span
        class="inline-flex items-center rounded-full bg-indigo-100 px-2 py-1 text-xs font-medium text-indigo-700 mr-1.5 mb-1.5">{{
          group }}</span>
      }
    </dd>
  </div>
  <div class="sm:col-span-1">
    <dt class="text-sm font-medium text-gray-500">Tags</dt>
    <dd class="mt-2 text-sm text-gray-900">
      @for (tag of attendee.tags; track $index) {
      <span
        class="inline-flex items-center rounded-full bg-indigo-100 px-2 py-1 text-xs font-medium text-indigo-700 mr-1.5 mb-1.5">{{
        tag }}</span>
      }
    </dd>
  </div>
  <div *ngFor="let key of metaFieldKeys" class="ml-2">
    <dt class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">{{ key }}</dt>
    <dd class="mt-1 text-sm text-gray-900 sm:col-span-2">
      <p>{{ attendee.metaField[key] }}</p>
    </dd>
  </div>
  <div class="sm:col-span-2">
    <dt class="text-sm font-medium text-gray-500">About</dt>
    <dd class="mt-1 max-w-prose space-y-5 text-sm text-gray-900">
      <p>{{ user?.about }}</p>
    </dd>
  </div>
  <div class="sm:col-span-1">
    <dt class="text-sm font-medium text-gray-500">QRCode</dt>
    <dd class="mt-1 max-w-prose space-y-5 text-sm text-gray-900">
      <img [src]="qrCodeUrl" height="300" width="300" />
    </dd>
  </div>
</dl>
