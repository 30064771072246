<form [formGroup]="detailsForm" (submit)="onSubmit()">
    <div class="space-y-12">
        <div class="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-1">
            <div>
                <h2 class="text-base font-semibold leading-7 text-gray-900">Form's Details</h2>
                <p class="mt-1 text-sm leading-6 text-gray-600">This information will be displayed publicly so be
                    careful
                    what you share.</p>
            </div>

            <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                <div class="col-span-full">
                    <label for="name" class="block text-sm font-medium leading-6 text-gray-900">Name<span class="text-slate-500 required">*</span></label>
                    <div class="mt-2">
                        <input id="name" name="name" formControlName="name" type="text"
                            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-main sm:text-sm sm:leading-6" />
                    </div>
                </div>

                <div class="col-span-full">
                    <label for="purpose" class="block text-sm font-medium leading-6 text-gray-900">Purpose<span class="text-slate-500 required">*</span></label>
                    <div class="mt-2">
                        <textarea id="purpose" name="purpose" formControlName="purpose" rows="3"
                            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-main sm:text-sm sm:leading-6"></textarea>
                    </div>
                </div>

                <div class="col-span-full">
                    <label for="startAt" class="block text-sm font-medium leading-6 text-gray-900">
                        Start At<span class="text-slate-500 required">*</span>
                    </label>
                    <div class="mt-2">
                        <div
                            class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-main">
                            <nz-date-picker id="startAt" nzPlaceHolder formControlName="startAt" nzBorderless
                                [nzDefaultPickerValue]="startAt" [nzFormat]="'yyyy-MM-dd'"
                                class="block flex-1 border-0 bg-transparent py-1.5 pl-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"></nz-date-picker>
                        </div>
                    </div>
                    <p class="mt-3 text-sm leading-6 text-gray-600">Set when the registration should available for
                        participants.</p>
                </div>

                <div class="col-span-full">
                    <label for="endAt" class="block text-sm font-medium leading-6 text-gray-900">
                        End At<span class="text-slate-500 required">*</span>
                    </label>
                    <div class="mt-2">
                        <div
                            class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-main">
                            <nz-date-picker id="endAt" nzPlaceHolder formControlName="endAt" nzBorderless
                                [nzDefaultPickerValue]="startAt" [nzFormat]="'yyyy-MM-dd'"
                                class="block flex-1 border-0 bg-transparent py-1.5 pl-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"></nz-date-picker>
                        </div>
                    </div>
                    <p class="mt-3 text-sm leading-6 text-gray-600">Set when the registration
                        should end.</p>
                </div>


                <div class="col-span-full">
                    <label for="url" class="block text-sm font-medium leading-6 text-gray-900">Registration Link</label>
                    <div class="mt-2">
                        <div
                            class="hover:cursor-pointer relative flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-main">
                            <span class="flex select-none items-center pl-3 text-gray-500 sm:text-sm"></span>
                            <input (click)="copyLink()" type="text" name="url" id="url" autocomplete="url" formControlName="url" [readOnly]="true"
                                class="hover:cursor-pointer pointer-events-auto block flex-1 border-0 bg-transparent py-1.5 pr-10 pl-1 text-gray-500 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                placeholder="janesmith">
                            <div class="cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3">
                                <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z" />
                                </svg>
                            </div>
                        </div>
                        <p class="text-xs text-slate-500 mt-1.5">
                            Click to copy
                        </p>
                    </div>
                </div>

                <div class="col-span-full">
                    <button type="submit"
                        class="inline-flex justify-center items-center gap-x-1 w-full rounded-md bg-main px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-main/70 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main">
                        <span *ngIf="saving">
                            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
                                fill="none" viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                    stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                                </path>
                            </svg>
                        </span>
                        Save
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>