import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RadioOption } from '../custom/models/radio-option.type';
import { InputForm } from './schema-builder-helper.service';

@Injectable({
  providedIn: 'root',
})
export class CustomFieldHelperService {
  inputsForms: InputForm[] = [
    { name: 'Simple Input', desc: 'Ideal for getting the user input as text or number. Keyboard and mouse can be used for providing or changing data.', input: 'custom-input', form: 'assets/forms/custom_input_form.json' },
    { name: 'Text area Input', desc: 'Ideal for getting the user input as log text.', input: 'formly-custom-textarea', form: 'assets/forms/custom_textarea.json' },
    { name: 'Radio Input', desc: 'Used to select a single value in multiple options.', input: 'custom-radio-type', form: 'assets/forms/custom_radio_form.json' },
    { name: 'Checkbox Input', desc: 'Used for selecting multiple values from several options.', input: 'custom-checkbox-type', form: 'assets/forms/custom_checkbox_form.json' },
    { name: 'Drop Down Input', desc: 'If there are too many options to display, you can wrap them in a Dropdown.', input: 'custom-unique-select', form: 'assets/forms/custom_dropdown_form.json' }
  ];
  predefinedInputsForms: InputForm[] = [
    { name: 'Age', desc: 'Ideal for getting the user\'s age.', input: 'custom-input', form: 'assets/forms/predefined_age_input.json' },
    { name: 'Gender', desc: 'Ideal for getting the user\'s gender.', input: 'custom-radio-type', form: 'assets/forms/predefined_gender.json' },
    { name: 'Salutation', desc: 'Ideal for getting the user\'s salutation.', input: 'custom-radio-type', form: 'assets/forms/predefined_salutation.json' },
    { name: 'Position/Level', desc: 'Ideal for getting the user\'s poisition.', input: 'custom-unique-select', form: 'assets/forms/predefined_level.json' },
    { name: 'Biographie', desc: 'Ideal for getting the user\'s information.', input: 'formly-custom-textarea', form: 'assets/forms/predefined_biographie.json' },
    { name: 'Website', desc: 'Ideal for getting a link to website.', input: 'custom-input', form: 'assets/forms/predefined_website.json' }
  ];


  constructor(
    private httpClient: HttpClient,
  ) { }

  getFieldSchemaByInput(input: string) {
    const inputs = this.inputsForms.concat(this.predefinedInputsForms);
    const index = inputs.findIndex(item => item.name.toLowerCase() == input.toLowerCase());
    const path: string = inputs[index].form;
    return this.loadInputTemplate(path);
  }

  convertModelToField(model, type) {
    switch (type) {
      case 'custom-input':
        return {
          key: model.label.toLowerCase(),
          label: model.label,
          type: type,
          props: {
            type: model.type,
            required: model.isRequired
          }
        };
      case 'formly-custom-textarea':
        return {
          key: model.label.toLowerCase(),
          label: model.label,
          type: type,
          props: {
            type: model.type,
            required: model.isRequired,
            maxLength: model.maxLength,
            minLength: model.minLength
          }
        };
      case 'custom-radio-type':
        let label = model.items[0];
        let isRequired = model.items[1];
        let options: RadioOption[] = [];
        model.items.slice(2).map(item => {
          options.push({ label: item, value: item });
        });
        return {
          key: label?.toLowerCase() || '',
          label: label,
          type: type,
          props: {
            required: isRequired,
            options: options,
          }
        };
      case 'custom-checkbox-type':
        let labelRadio = model.items[0];
        let isRequiredRadio = model.items[1];
        let optionsRadio: RadioOption[] = [];
        model.items.slice(2).map(item => {
          optionsRadio.push({ label: item, value: item });
        });
        return {
          key: labelRadio.toLowerCase(),
          label: labelRadio,
          type: type,
          props: {
            required: isRequiredRadio,
            options: optionsRadio,
          }
        };
      case 'custom-unique-select':
        let labelDropdown = model.items[0];
        let isDropDownRequired = model.items[1];
        let dropdownOpts: RadioOption[] = [];
        model.items.slice(2).map(item => {
          dropdownOpts.push({ label: item, value: item });
        });
        return {
          key: labelDropdown.toLowerCase(),
          label: labelDropdown,
          type: type,
          props: {
            required: isDropDownRequired,
            options: dropdownOpts,
          }
        };
      case 'formly-country-input':
        return {};
      case 'formly-industry-input':
        return {};
      case 'formly-offer-input':
        return {};
      case 'formly-interests-input':
        return {};
      case 'formly-lookingfor-input':
        return {};
      default:
        return {};
    }
  }

  loadInputTemplate(template: string) {
    return this.httpClient.get<{ schema, model }>(`${template}`);
  }
}
