import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AnalyticsComponent } from '../analytics/analytics.component';
import { AttendeesComponent } from '../attendees/attendees.component';
import { ListOfAttendeesComponent } from '../attendees/list-of-attendees/list-of-attendees.component';
import { MeetingConfigurationComponent } from '../meeting/configuration/meeting-configuration.component';
import { MeetingComponent } from '../meeting/meeting.component';
import { ConfirmationOfRegistrationComponent } from '../notifications/emails/confirmation-of-registration/confirmation-of-registration.component';
import { CustomEmailComponent } from '../notifications/emails/custom-email/custom-email.component';
import { EmailNotificationsComponent } from '../notifications/emails/email-notifications/email-notifications.component';
import { NotificationMenuComponent } from '../notifications/notification-menu/notification-menu.component';
import { NotificationsComponent } from '../notifications/notifications.component';
import { BadgesComponent } from '../registration/badges/badges.component';
import { EditFormComponent } from '../registration/edit-form/edit-form.component';
import { FormsComponent } from '../registration/forms/forms.component';
import { NewFormComponent } from '../registration/forms/new-form/new-form.component';
import { MenuComponent } from '../registration/menu/menu.component';
import { RegistrationComponent } from '../registration/registration.component';
import { TicketsComponent } from '../registration/tickets/tickets.component';
import { EventEditorGuard } from '../shared/guards/event-editor.guard';
import { ProfileResolver } from '../shared/resolvers/profile.resolver';
import { EventConfigurationComponent } from './event-configuration/event-configuration.component';
import { EventEditorComponent } from './event-editor.component';
import { BasicInformationComponent } from './event-information/basic-information/basic-information.component';
import { EventBrochuresComponent } from './event-information/event-brochures/event-brochures.component';
import { EventCoverComponent } from './event-information/event-cover/event-cover.component';
import { EventIndustriesComponent } from './event-information/event-industries/event-industries.component';
import { EventInformationComponent } from './event-information/event-information.component';
import { EventLocationComponent } from './event-information/event-location/event-location.component';
import { EventNatureComponent } from './event-information/event-nature/event-nature.component';
import { EventScheduleComponent } from './event-information/event-schedule/event-schedule.component';
import { EventSponsorsComponent } from './event-information/event-sponsors/event-sponsors.component';
import { EventTypeComponent } from './event-information/event-type/event-type.component';
import { OverviewComponent } from './event-information/overview/overview.component';
import { EventSettingComponent } from './event-setting/event-setting.component';
import { UpdateEventDetailsComponent } from './update-event-details/update-event-details.component';

const routes: Routes = [
  {
    path: 'event-editor',
    component: EventEditorComponent,
    canActivate: [EventEditorGuard],
    resolve: [ProfileResolver],
    children: [
      { path: '', component: EventInformationComponent },
      { path: 'menu', component: EventInformationComponent },
      { path: 'overview', component: OverviewComponent, title: 'Overview' },
      { path: 'details', component: UpdateEventDetailsComponent, title: 'Event Details' },
      { path: 'basic-information', component: BasicInformationComponent, title: 'Basic Information' },
      { path: 'event-type', component: EventTypeComponent, title: 'Event Type' },
      { path: 'event-nature', component: EventNatureComponent, title: 'Event Nature' },
      { path: 'event-cover', component: EventCoverComponent, title: 'Event Cover' },
      { path: 'event-location', component: EventLocationComponent, title: 'Event Location' },
      { path: 'event-schedule', component: EventScheduleComponent, title: 'Event Schedule' },
      { path: 'event-industries', component: EventIndustriesComponent, title: 'Event Industries' },
      { path: 'event-sponsors', component: EventSponsorsComponent, title: 'Event Sponsors' },
      { path: 'event-brochures', component: EventBrochuresComponent, title: 'Event Brochures' },
      { path: 'configuration', component: EventConfigurationComponent, title: 'Event Configuration' },
      { path: 'settings', component: EventSettingComponent, title: 'Event Settings' },
      {
        path: 'registration',
        component: RegistrationComponent,
        children: [
          { path: '', redirectTo: 'menu', pathMatch: 'full' },
          { path: 'menu', component: MenuComponent },
          { path: 'forms', component: FormsComponent, title: 'Registration Forms' },
          { path: 'new-form', component: NewFormComponent, title: 'New Registration Form' },
          { path: 'edit-form', component: EditFormComponent, title: 'Edit Registration Form' },
          { path: 'tickets', component: TicketsComponent, title: 'Tickets' },
          { path: 'badges', component: BadgesComponent, title: 'Print Badge' },
          { path: 'badges/:profileId', component: BadgesComponent, title: 'Print Badge' }
        ]
      },
      {
        path: 'meeting',
        component: MeetingComponent,
        children: [
          { path: '', redirectTo: 'configuration', pathMatch: 'full' },
          { path: 'configuration', component: MeetingConfigurationComponent }
        ]
      },
      {
        path: 'attendees',
        component: AttendeesComponent,
        children: [
          { path: '', component: ListOfAttendeesComponent }
        ],
      },
      {
        path: 'notifications',
        component: NotificationsComponent,
        children: [
          { path: '', redirectTo: 'menu', pathMatch: 'full' },
          { path: 'menu', component: NotificationMenuComponent },
          { path: 'email-notifications', component: EmailNotificationsComponent },
          { path: 'custom-email', component: CustomEmailComponent },
          { path: 'confirmation-of-registration', component: ConfirmationOfRegistrationComponent }
        ]
      },
      {
        path: 'analytics',
        component: AnalyticsComponent
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EventEditorRoutingModule { }
