<label for="logo-upload" class="hover:cursor-pointer relative">
  <span *ngIf="!uploading"
    class="inline-flex items-center justify-center w-full gap-x-1.5 rounded-md bg-slate-200 px-3 py-1.5 text-sm font-semibold text-slate-700 shadow-sm hover:bg-slate-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-100">Upload
  </span>
  <span *ngIf="uploading">
    <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-slate-700" xmlns="http://www.w3.org/2000/svg" fill="none"
      viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
      </path>
    </svg>
  </span>
  <input id="logo-upload" name="logo-upload" type="file" class="sr-only" (change)="onSelectFile($event)">
</label>


<div *ngIf='isModal' class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div @opacity *ngIf='isModal' class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true">
  </div>

  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div *ngIf='isModal' (click)="toggleModal()" class="fixed inset-0"></div>
    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div @opacityTranslateY *ngIf='isModal'
        class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all w-3/4 sm:my-8 sm:max-w-4xl sm:p-6">
        <div *ngIf="!uploading" class="absolute top-2 right-3 hover:cursor-pointer">
          <div (click)="toggleModal()"
            class="size-7 flex justify-center items-center rounded-full bg-slate-100 hover:bg-slate-200">
            <svg class="size-6 text-slate-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              stroke-width="1.5" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
            </svg>
          </div>
        </div>
        <div class="mt-5 pb-7">
          @if (uploading) {
          <div class="mx-auto flex flex-col items-center justify-center gap-y-2.5">
            <svg class="animate-spin h-6 w-6 text-slate-600" xmlns="http://www.w3.org/2000/svg" fill="none"
              viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
              </path>
            </svg>
            <span class="text-slate-600">Processing...</span>
          </div>
          }

          @if(!uploading){

          @if(result.length == 0){
          <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
            <svg class="h-6 w-6 text-green-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
              aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
            </svg>
          </div>
          }@else {
          <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
            <svg class="h-6 w-6 text-yellow-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              stroke-width="1.5" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
            </svg>
          </div>
          }

          <div class="mt-3 text-center sm:mt-5">
            @if(result.length == 0){
            <h3 class="text-base font-semibold leading-6 text-slate-700" id="modal-title">Upload successful</h3>
            }@else{
            <h3 class="text-base font-semibold leading-6 text-slate-700" id="modal-title">Successful with some issues</h3>
            }

            @if (result.length > 0) {
            <div class="mt-2">
              <p class="text-sm text-gray-500">
                Some lines were not uploaded. Please check the logs for details.
              </p>
            </div>

            <div class="px-4 sm:px-6 lg:px-8">
              <div class="-mx-4 mt-4 sm:-mx-0 max-h-44 overflow-y-scroll">
                <table class="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                        First Name</th>
                      <th scope="col"
                        class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">Last Name
                      </th>
                      <th scope="col"
                        class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">Email
                      </th>
                      <th scope="col"
                        class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">Logs
                      </th>
                      <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Line</th>

                    </tr>
                  </thead>
                  <tbody class="divide-y divide-gray-200 bg-white">
                    @for ( row of result ; track $index) {
                    <tr>
                      <td
                        class="w-full max-w-0 py-4 pl-4 pr-3 text-sm text-left font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0">
                        {{ row.firstName }}
                        <dl class="text-left font-normal lg:hidden">
                          <dt class="sr-only">Last Name</dt>
                          <dd class="mt-1 truncate text-gray-700">{{ row.lastName}}</dd>
                          <dt class="sr-only sm:hidden">Email</dt>
                          <dd class="mt-1 truncate text-gray-500 sm:hidden">{{ row.email }}</dd>
                          <dt class="sr-only sm:hidden">Logs</dt>
                          <dd class="mt-1 truncate text-gray-500 sm:hidden">{{ row.logMessage }}</dd>
                        </dl>
                      </td>
                      <td class="text-left hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">{{ row.lastName }}</td>
                      <td class="text-left hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{{ row.email }}</td>
                      <td class="text-left hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{{ row.logMessage }}
                      </td>
                      <td class="text-left px-3 py-4 text-sm text-gray-500">{{ row.line }}</td>
                    </tr>
                    }
                  </tbody>
                </table>
              </div>
              <div class="mt-7 sm:flex sm:justify-end sm:items-center">
                <div class="sm:mt-0 sm:flex-none">
                  <button type="button" (click)="convertResultToCSV(result)"
                    class="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Download
                    Logs</button>
                </div>
              </div>
            </div>
            }
          </div>
          }
        </div>
      </div>
    </div>
  </div>
</div>
