<div class="px-2 md:px-7 py-7">
  <div class="mx-auto max-w-5xl">
    <nav class="flex mb-4" aria-label="Breadcrumb">
      <ol role="list" class="flex items-center space-x-4">
        <li>
          <div class="flex items-center justify-start">
            <a routerLink="/event-editor/menu" class="text-sm font-medium text-gray-500 hover:text-gray-700">Event
              Editor</a>
          </div>
        </li>
        <li>
          <div class="flex items-center">
            <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                clip-rule="evenodd" />
            </svg>
            <a [routerLink]="'/event-editor/details'" class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
              aria-current="page">Streamline Event Details</a>
          </div>
        </li>
        <li>
          <div class="flex items-center">
            <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                clip-rule="evenodd" />
            </svg>
            <a class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700" aria-current="page">Event Cover</a>
          </div>
        </li>
      </ol>
    </nav>
  </div>
  <div class="space-y-12 mt-7">
    <div class="mx-auto max-w-4xl lg:text-center">
      <h2 class="text-base font-semibold leading-7 text-main">Cover</h2>
      <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl line-clamp-2 text-ellipsis">{{
        event.name }}
      </p>
      <p class="mt-6 text-lg leading-8 text-gray-600">Upload your event's logo, banner, and background image easily with
        Cover. Enhance your event's visual identity and branding effortlessly.</p>
    </div>
  </div>
  <div class="mx-auto max-w-5xl mt-24 lg:max-w-4xl">
    <div class="border-b border-gray-900/10 pb-12">
      <div class="grid max-w-full grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 col-span-2">
        <div class="col-span-full">
          <label for="logo" class="block text-sm font-medium leading-6 text-gray-900">
            Logo <span class="text-slate-600 text-xs">32x32</span>
          </label>
          <div class="mt-2 flex items-center gap-x-3">
            <svg *ngIf="!isLogoExists()" class="h-12 w-12 text-gray-300" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24" fill="currentColor">
              <path fill-rule="evenodd"
                d="M1.5 6a2.25 2.25 0 0 1 2.25-2.25h16.5A2.25 2.25 0 0 1 22.5 6v12a2.25 2.25 0 0 1-2.25 2.25H3.75A2.25 2.25 0 0 1 1.5 18V6ZM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0 0 21 18v-1.94l-2.69-2.689a1.5 1.5 0 0 0-2.12 0l-.88.879.97.97a.75.75 0 1 1-1.06 1.06l-5.16-5.159a1.5 1.5 0 0 0-2.12 0L3 16.061Zm10.125-7.81a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0Z"
                clip-rule="evenodd" />
            </svg>
            <img *ngIf="isLogoExists()" class="inline-block h-14 w-14 rounded-md"
              [src]="event.theme.logo.name | image | async" alt>
            <label for="logo-upload" class="relative">
              <span *ngIf="!saving"
                class="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Upload
                a Logo
              </span>
              <span *ngIf="saving">
                <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-slate-700" xmlns="http://www.w3.org/2000/svg"
                  fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                  </path>
                </svg>
              </span>
              <input id="logo-upload" name="logo-upload" type="file" class="sr-only" (change)="onSelectLogo($event)">
            </label>
          </div>
        </div>

        <div class="col-span-full">
          <label for="cover-banner" class="block text-sm font-medium leading-6 text-gray-900">
            Cover Banner <span class="text-slate-600 text-xs">1095x620</span>
          </label>
          <div
            class="relative mt-2 box-border h-36 w-full flex justify-center items-center rounded-lg border border-dashed border-gray-900/25 px-6 py-6">
            <img *ngIf="isBannerExists()" class="absolute z-10 top-0 inline-block h-36 w-full rounded-md object-cover"
              [src]="event.theme.banner.name | image | async" alt>
            <div class="text-center bg-white/90 z-30 py-2 px-6 rounded-sm shadow-sm">
              <svg *ngIf="!isBannerExists() && !saving" class="mx-auto h-12 w-12 text-gray-300" viewBox="0 0 24 24"
                fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                  clip-rule="evenodd" />
              </svg>
              <svg *ngIf="saving" class="animate-spin mx-auto h-12 w-12 text-slate-700"
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                </path>
              </svg>
              <div *ngIf="!saving" class="flex text-sm leading-6 text-gray-600">
                <label for="cover-banner"
                  class="relative cursor-pointer rounded-md font-semibold text-main focus-within:outline-none focus-within:ring-2 focus-within:ring-main focus-within:ring-offset-2 hover:text-secondary">
                  <span>Upload a file</span>
                  <input id="cover-banner" name="cover-banner" type="file" class="sr-only"
                    (change)="onSelectBanner($event)">
                </label>
                <p class="pl-1">or drag and drop</p>
              </div>
              <p *ngIf="!saving" class="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 1MB</p>
            </div>
          </div>
        </div>

        <div class="col-span-full">
          <label for="cover-background" class="block text-sm font-medium leading-6 text-gray-900">
            Cover Background <span class="text-slate-600 text-xs"></span>
          </label>
          <div
            class="relative mt-2 box-border h-64 w-full flex justify-center items-center rounded-lg border border-dashed border-gray-900/25 px-6 py-6">
            <img *ngIf="isBackgroundExists()"
              class="absolute z-10 top-0 inline-block h-64 w-full rounded-md object-cover"
              [src]="event.theme.background.name | image | async" alt>
            <div class="text-center bg-white/90 z-30 py-2 px-6 rounded-sm shadow-sm">
              <svg *ngIf="!isBackgroundExists() && !saving" class="mx-auto h-12 w-12 text-gray-300" viewBox="0 0 24 24"
                fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                  clip-rule="evenodd" />
              </svg>
              <svg *ngIf="saving" class="animate-spin mx-auto h-12 w-12 text-slate-700"
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                </path>
              </svg>
              <div *ngIf="!saving" class="flex text-sm leading-6 text-gray-600">
                <label for="cover-background"
                  class="relative cursor-pointer rounded-md font-semibold text-main focus-within:outline-none focus-within:ring-2 focus-within:ring-main focus-within:ring-offset-2 hover:text-secondary">
                  <span>Upload a file</span>
                  <input id="cover-background" name="cover-background" type="file" class="sr-only"
                    (change)="onSelectBackground($event)">
                </label>
                <p class="pl-1">or drag and drop</p>
              </div>
              <p *ngIf="!saving" class="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 1MB</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-12">
      <div class="my-7">
        <p class="leading-7 text-slate-700 text-base">
          Easily proceed to <span class="font-bold">Event Location</span> by clicking "Manage Location." This button
          will guide you through updating location of your event.
        </p>
      </div>
      <div class="flex">
        <div (click)="trackUpdate('Location')" [routerLink]="'/event-editor/event-location'"
          class="flex flex-col borded md:w-1/2">
          <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
            <svg class="h-5 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                d="M5.5 17a4.5 4.5 0 01-1.44-8.765 4.5 4.5 0 018.302-3.046 3.5 3.5 0 014.504 4.272A4 4 0 0115 17H5.5zm3.75-2.75a.75.75 0 001.5 0V9.66l1.95 2.1a.75.75 0 101.1-1.02l-3.25-3.5a.75.75 0 00-1.1 0l-3.25 3.5a.75.75 0 101.1 1.02l1.95-2.1v4.59z"
                clip-rule="evenodd" />
            </svg>
            Event Location
          </dt>
          <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
            <p class="flex-auto">Enter the event's precise address, location, and country details seamlessly with
              Location. Provide accurate geographical information for attendees.</p>
            <p class="mt-6 md:text-end">
              <button type="button"
                class="rounded-md inline-flex items-center gap-x-1.5 bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                Manage Location
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="-mr-0.5 h-5 w-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                </svg>
              </button>
            </p>
          </dd>
        </div>
      </div>

    </div>
  </div>
</div>
