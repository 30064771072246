<a (click)="edit(form)" class="group">
    <div class="flex flex-col gap-y-2 justify-start items-center bg-">
        <div>
            <div [class]="bgColor"
                class="w-24 h-24 shadow-md rounded-md flex flex-col justify-center items-center gap-y-1.5 hover:cursor-pointer">
                <p class="text-white text-lg font-medium">{{ getInitials() }}</p>
            </div>
        </div>
        <div class="text-center">
            <p class="text-sm font-normal text-gray-700 group-hover:text-gray-900">{{ form.name }}</p>
            <p class="text-xs font-normal text-gray-500">{{ form.startDate | date:'MMM d, y' }} - {{ form.endDate | date:'MMM d, y' }}</p>
            <p class="text-xs mt-1.5 font-medium text-gray-500 group-hover:text-gray-700">
                <span class="inline-flex items-center rounded-full px-2 py-1 text-xs font-medium"
                    [ngClass]="{'bg-red-100 text-red-700': form.formConfiguration.formStatus == 'Disabled', 'bg-green-100 text-green-700': form.formConfiguration.formStatus == 'Enabled' }">
                    {{ form.formConfiguration.formStatus }}
                </span>
            </p>
        </div>
    </div>
</a>