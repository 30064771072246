import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NotificationService } from 'src/app/shared/components/notifications/notification.service';
import { defaultFilesTypeSupported } from 'src/app/shared/model/accepted-upload-formats';
import { NotificationType } from 'src/app/shared/model/enum/notification-type.enum';
import { Event } from 'src/app/shared/model/event/event.model';
import { AppSettingService } from 'src/app/shared/service/app-setting.service';
import { EventService } from 'src/app/shared/service/event.service';
import { FileUploaderService } from 'src/app/shared/service/file-uploader.service';
import { IndustryService } from 'src/app/shared/service/industry.service';
import { LastUpdatesTrackerService } from 'src/app/shared/service/last-updates-tracker.service';

@Component({
  selector: 'event-industries',
  templateUrl: './event-industries.component.html',
  styleUrls: ['./event-industries.component.scss', '../event-information.component.scss']
})
export class EventIndustriesComponent implements OnInit {
  event: Event;
  eventSub: Subscription;
  saving: boolean = false;

  constructor(
    private fileUploader: FileUploaderService,
    private notificationService: NotificationService,
    private appSettings: AppSettingService,
    private eventService: EventService,
    private updateTracker: LastUpdatesTrackerService,
    private industryService: IndustryService
  ) { }

  ngOnInit(): void {
    this.eventSub = this.eventService.selectedEvent.subscribe({
      next: event => {
        this.event = event;
      },
    });
  }

  ngOnDestroy(): void {
    if (this.eventSub) this.eventSub.unsubscribe();
  }

  onSelectFile(selected: any) {
    this.saving = true;
    const endpoint: string = `industries/upload/${this.event.id}`;
    this.uploadIndustries(selected, endpoint);
  }

  uploadIndustries(selected, endpoint) {
    this.fileUploader.onFileSelect(selected, endpoint, this.appSettings.settings.maxFileSizeMo, defaultFilesTypeSupported).subscribe({
      next: _ => {
        this.saving = false;
        this.notificationService.notify('Event Industries', 'New list of industries has been deployed', NotificationType.Success);
      },
      error: error => {
        console.log(error);
        this.saving = false;
        this.notificationService.notify('Event Industries', 'An error happend while uploading the list.', NotificationType.Error);
      }
    });
  }

  trackUpdate(menu: string) {
    this.updateTracker.newUpdate(menu);
  }

  getTemplate(template: string) {
    this.industryService.downloadTemplate(template);
  }
}
