<div [ngClass]="{'bg-gray-100 rounded-md': attendee.id == selected?.id}"
  class="relative flex items-start justify-start space-x-3 pl-3 py-5 focus-within:ring-2 focus-within:ring-inset focus-within:ring-main hover:bg-gray-100 rounded-md my-1.5">
  <div class="flex-shrink-0">
    <img *ngIf="profilePicture" class="h-10 w-10 rounded-full" [src]="profilePicture | image | async" alt="">
    <svg *ngIf="!profilePicture" class="h-10 w-10 rounded-full text-slate-500" data-slot="icon" fill="none"
      stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true">
      <path stroke-linecap="round" stroke-linejoin="round"
        d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z">
      </path>
    </svg>
  </div>
  <div class="min-w-0 flex-1">
    <a (click)="toggleSlideOver()" class="focus:outline-none">
      <span class="absolute inset-0" aria-hidden="true"></span>
      <p class="text-sm font-medium text-gray-900">{{ attendee.salutation }} {{ attendee.firstName }} {{
        attendee.lastName }}</p>
      <p class="truncate text-sm text-gray-500">{{ attendee.occupation }} <span *ngIf="attendee.company">at {{ attendee.company }}</span></p>
      <p class="truncate text-sm text-gray-500">{{ attendee.email }}</p>
      <p class="truncate text-sm text-gray-500">{{ attendee.id }}</p>
    </a>
  </div>
</div>

<div class="relative z-[90]" role="dialog" aria-modal="true">

  <div @translateX *ngIf='isSlideOver' class="fixed inset-0 overflow-hidden">
    <div class="absolute inset-0 overflow-hidden">
      <div @translateX *ngIf='isSlideOver' (click)="toggleSlideOver()" class="fixed inset-0"></div>
      <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
        <div @translateX *ngIf='isSlideOver' class="pointer-events-auto w-screen max-w-md">
          <div class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
            <div class="px-4 py-6 sm:px-6">
              <div class="flex items-start justify-between">
                <h2 id="slide-over-heading" class="text-base font-semibold leading-6 text-gray-900">Profile</h2>
                <div class="ml-3 flex h-7 items-center">
                  <button type="button" (click)="toggleSlideOver()"
                    class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-main">
                    <span class="sr-only">Close panel</span>
                    <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                      aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div *ngIf="!loading">
              <div class="pb-1 sm:pb-6">
                <div>
                  <div class="relative h-40 sm:h-56">
                    <img *ngIf="profilePicture" class="absolute h-full w-full object-cover"
                      [src]="profilePicture | image | async" alt="">
                    <svg *ngIf="!profilePicture" class="mx-auto absolute h-full w-full object-cover text-gray-300"
                      viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                        clip-rule="evenodd" />
                    </svg>
                  </div>
                  <div class="mt-6 px-4 sm:mt-8 sm:flex sm:items-end sm:px-6">
                    <div class="sm:flex-1">
                      <div>
                        <div class="flex items-center">
                          <h3 class="text-xl font-bold text-gray-900 sm:text-2xl">{{ attendee.salutation }} {{
                            attendee.name }}</h3>
                          <span *ngIf="attendee.user.emailVerified"
                            class="ml-2.5 inline-block h-2 w-2 flex-shrink-0 rounded-full bg-green-400">
                            <span class="sr-only">Verified</span>
                          </span>
                          <span *ngIf="!attendee.user.emailVerified"
                            class="ml-2.5 inline-block h-2 w-2 flex-shrink-0 rounded-full bg-red-400">
                            <span class="sr-only">Not-Verified</span>
                          </span>
                        </div>
                        <p class="text-sm text-gray-500">{{ attendee.id }}</p>
                      </div>
                      <div class="mt-5 flex flex-wrap space-y-3 sm:space-y-0 sm:space-x-3">
                        <button *ngIf="!attendee.user.emailVerified" type="button" (click)="sendUpdatePasswordEmail()"
                          [disabled]="isSendingEmail"
                          class="gap-x-1 inline-flex w-full flex-shrink-0 items-center justify-center rounded-md bg-main px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-main/60 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main sm:flex-1">
                          <span *ngIf="isSendingEmail">
                            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
                              fill="none" viewBox="0 0 24 24">
                              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                              </circle>
                              <path class="opacity-75" fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                              </path>
                            </svg>
                          </span>
                          Verification Email
                        </button>
                        <button type="button" (click)="sendConfirmationEmail()" [disabled]="isSendingEmail"
                          class="gap-x-1 inline-flex w-full flex-shrink-0 items-center justify-center rounded-md bg-main px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-main/60 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main sm:flex-1">
                          <span *ngIf="isSendingEmail">
                            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
                              fill="none" viewBox="0 0 24 24">
                              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                              </circle>
                              <path class="opacity-75" fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                              </path>
                            </svg>
                          </span>
                          Confirmation Email
                        </button>
                        <button type="button" (click)="toggleDialog()"
                          class="inline-flex w-full flex-1 items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                          Remove
                        </button>

                        <!--  <div class="ml-3 inline-flex sm:ml-0">
                          <div class="relative inline-block text-left">
                            <button type="button" (click)="toggleDropdown()"
                              class="inline-flex items-center rounded-md border border-gray-300 bg-white p-2 text-sm font-medium text-gray-400 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-main focus:ring-offset-2"
                              id="options-menu-button" aria-expanded="false" aria-haspopup="true">
                              <span class="sr-only">Open options menu</span>
                              <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path
                                  d="M10 3a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM10 8.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM11.5 15.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z" />
                              </svg>
                            </button>
                            <div @opacityScale *ngIf='isDropdown'
                              class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                              role="menu" aria-orientation="vertical" aria-labelledby="options-menu-button"
                              tabindex="-1">
                              <div class="py-1" role="none">
                                <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1"
                                  id="options-menu-item-0">View profile</a>
                                <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1"
                                  id="options-menu-item-1">Copy profile link</a>
                              </div>
                            </div>
                          </div>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="px-4 pt-5 pb-5 sm:px-0 sm:pt-0">
                <dl class="space-y-8 px-4 sm:space-y-6 sm:px-6">
                  <div>
                    <dt class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">Organisation/Company</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:col-span-2">{{ attendee.company }}</dd>
                  </div>
                  <div>
                    <dt class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">Occupation</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:col-span-2">{{ attendee.occupation }}</dd>
                  </div>
                  <div>
                    <dt class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">About</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:col-span-2">
                      <p>{{ attendee.user.about }}</p>
                    </dd>
                  </div>
                  <div>
                    <dt class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">Email Address</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:col-span-2">{{ attendee.email }}</dd>
                  </div>
                  <div>
                    <dt class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">Mobile Phone</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:col-span-2">{{ attendee.mobilePhone }}</dd>
                  </div>
                  <div>
                    <dt class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">Location</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:col-span-2">{{ attendee.country?.name }}, {{
                      attendee.businessCity }}, {{ attendee.address }}</dd>
                  </div>
                  <div>
                    <dt class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">Expertise</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:col-span-2">
                      <span
                        class="inline-flex items-center rounded-full bg-indigo-100 px-2 py-1 text-xs font-medium text-indigo-700">{{
                        attendee.myIndustry?.name }}</span>
                    </dd>
                  </div>
                  <div>
                    <dt class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">Offers</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:col-span-2">
                      <span *ngFor="let offer of attendee.myOffers"
                        class="inline-flex items-center rounded-full bg-indigo-100 px-2 py-1 text-xs font-medium text-indigo-700 mr-1.5 mb-1.5">{{
                        offer.name }}</span>
                    </dd>
                  </div>
                  <div>
                    <dt class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">Interests</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:col-span-2">
                      <span *ngFor="let interest of attendee.myInterests"
                        class="inline-flex items-center rounded-full bg-indigo-100 px-2 py-1 text-xs font-medium text-indigo-700 mr-1.5 mb-1.5">{{
                        interest.name }}</span>
                    </dd>
                  </div>
                  <div>
                    <dt class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">Looking For</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:col-span-2">
                      <span *ngFor="let lookingFor of attendee.lookingFor"
                        class="inline-flex items-center rounded-full bg-indigo-100 px-2 py-1 text-xs font-medium text-indigo-700 mr-1.5 mb-1.5">{{
                        lookingFor.label }}</span>
                    </dd>
                  </div>
                  <div *ngFor="let key of metaFieldKeys" class="ml-2">
                    <dt class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">{{ key }}</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:col-span-2">
                      <p>{{ profile.metaField[key] }}</p>
                    </dd>
                  </div>
                  <div>
                    <dt class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">Created At</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:col-span-2">
                      <time>{{ attendee.createdAt | date: 'MMMM d, y, h:mm:ss a zzzz'}}</time>
                    </dd>
                  </div>
                  <div *ngIf="qrCodeUrl">
                    <dt class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">QRCode</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:col-span-2">
                      <img [src]="qrCodeUrl" height="300" width="300" />
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div @opacity *ngIf='isDialog' class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

  <div class="fixed inset-0 z-10 overflow-y-auto">
    <div class="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0">
      <div @opacityTranslateY *ngIf='isDialog'
        class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
        <div class="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
          <button type="button" (click)="toggleDialog()"
            class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            <span class="sr-only">Close</span>
            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
              aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div class="sm:flex sm:items-start">
          <div
            class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <svg class="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
              aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Remove attendee</h3>
            <div class="mt-2">
              <p class="text-sm text-gray-500">Are you sure you want to remove this attendee from your event? This
                action cannot be undone.</p>
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button type="button" (click)="remove()"
            class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto">Remove</button>
          <button type="button" (click)="toggleDialog()"
            class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>
