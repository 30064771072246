import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MeetingConfigurationV1 } from 'src/app/shared/model/meeting/meeting.model';
import { NewMeetingConfRequest } from 'src/app/shared/model/meeting/new-meeting-conf.request';
import { UpdateMeetingConfRequest } from 'src/app/shared/model/meeting/update-meeting-conf.request';
import { MeetingConfigurationV1Service } from 'src/app/shared/service/meeting-configuration-v1.service';

@Component({
  selector: 'meeting-configuration',
  templateUrl: './meeting-configuration.component.html',
  styleUrl: './meeting-configuration.component.scss',
  animations: [
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 }))
      ])
    ]),
    trigger('opacityTranslateY', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(1rem)' }))
      ])
    ])
  ]
})
export class MeetingConfigurationComponent implements OnInit {
  loading: boolean = false;
  isModal = false;
  configuration: MeetingConfigurationV1 = new MeetingConfigurationV1();
  form: FormGroup;
  mode: string = '';

  constructor(
    private meetingConfService: MeetingConfigurationV1Service,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.getConfiguration();
  }

  initForm() {
    this.form = this.formBuilder.group({
      bizEventId: [this.configuration.bizEventId, [Validators.required]],
      bizEventUrl: [this.configuration.bizEventUrl, [Validators.required]],
      apiKey: [this.configuration.apiKey, [Validators.required]],
      jwtKey: [this.configuration.jwtKey, [Validators.required]]
    });
  }

  getConfiguration() {
    this.loading = true;
    this.meetingConfService.getConfiguration().subscribe({
      next: configuration => {
        if (configuration) {
          this.configuration = configuration;
          this.mode = 'update';
        } else {
          this.mode = 'create';
        }
        this.loading = false;
        this.initForm();
      },
      error: _ => this.loading = false,
    })
  }

  openModal() {
    this.isModal = true;
    this.initForm();
  }

  closeModal() {
    this.form.reset();
    this.isModal = false;
  }

  edit() {
    this.isModal = true;
    this.initForm();
  }

  onSubmit() {
    if (!this.form.valid) return;

    this.loading = true;
    const values = this.form.getRawValue();

    if (this.mode == 'create') this.create(values);
    if (this.mode == 'update') this.update(values);

  }

  create(values) {
    const request: NewMeetingConfRequest = new NewMeetingConfRequest('', values.bizEventId, values.bizEventUrl, values.apiKey, values.jwtKey);

    this.meetingConfService
      .newConfiguration(request)
      .subscribe({
        next: (_) => {
          this.loading = false;
          this.closeModal();
          this.getConfiguration();
        },
        error: (_) => {
          this.loading = false;
          this.closeModal();
        }
      });
  }

  update(values) {
    const request: UpdateMeetingConfRequest = new UpdateMeetingConfRequest(this.configuration.id, values.bizEventId, values.bizEventUrl, values.apiKey, values.jwtKey);
    this.meetingConfService
      .updateConfiguration(request)
      .subscribe({
        next: (_) => {
          this.loading = false;
          this.closeModal();
          this.getConfiguration();
        },
        error: (_) => {
          this.loading = false;
          this.closeModal();
        }
      })

  }

}
