<div
  @opacity
  *ngIf="isModal"
  class="relative z-10"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div
        @opacityTranslateY
        *ngIf="isModal"
        class="relative transform overflow-hidden rounded-lg bg-white px-7 pb-5 pt-6 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
        <div>
          <form
            [formGroup]="communityForm"
            (ngSubmit)="onSubmit()">
            <div class="space-y-12">
              <div class>
                <h2 class="text-center text-base font-semibold leading-7 text-gray-900">Create your
                                    community</h2>
                <p class="text-center mt-1 text-sm leading-6 text-gray-600">Your community serves as the
                                    hub for all your
                                    events, ensuring continuous engagement with your audience throughout the year.</p>
                <div class="mt-7 grid grid-cols-1 gap-x-6 gap-y-7">
                  <div class="col-span-full">
                    <label
                      for="name"
                      class="block text-sm font-medium leading-6 text-gray-900">
                      Community name
                    </label>
                    <div class="mt-2">
                      <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-main">
                        <input
                          type="text"
                          name="name"
                          formControlName="name"
                          id="name"
                          autocomplete="name"
                          class="block flex-1 border-0 bg-transparent text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                          placeholder="Enter your community name">
                      </div>
                    </div>
                  </div>
                  <div class="col-span-full">
                    <label
                      for="description"
                      class="block text-sm font-medium leading-6 text-gray-900">
                      Description
                    </label>
                    <div class="mt-2">
                      <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-main">
                        <textarea
                          type="text"
                          name="description"
                          formControlName="description"
                          id="description"
                          autocomplete="description"
                          class="block flex-1 border-0 bg-transparent text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                          placeholder>
                        </textarea>
                      </div>
                    </div>
                  </div>
                  <div class="col-span-full">
                    <label
                      for="tags"
                      class="block text-sm font-medium leading-6 text-gray-900">
                      Tags
                    </label>
                    <div class="mt-2">
                      <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-main">
                        <span
                          *ngFor="let tag of tags"
                          class="select-non m-1 inline-flex items-center gap-x-0.5 rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">
                            {{ tag }}
                          
                          <button
                            type="button"
                            (click)="removeTag(tag)"
                            class="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-gray-500/20">
                            <span class="sr-only">Remove</span>
                            <svg viewBox="0 0 14 14" class="h-3.5 w-3.5 stroke-gray-700/50 group-hover:stroke-gray-700/75">
                              <path d="M4 4l6 6m0-6l-6 6" />
                            </svg>
                            <span class="absolute -inset-1"></span>
                          </button>
                          </span>
                        <input
                          (keyup)="onKeyUp($event)"
                          formControlName="tags"
                          type="text"
                          name="tags"
                          id="tags"
                          autocomplete="tags"
                          class="block flex-1 border-0 bg-transparent py-1.5 pl-3 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                          placeholder="Halal">
                      </div>
                    </div>
                    <p
                      class="mt-2 text-sm text-gray-500"
                      id="email-description">Separate tags with comma `,`</p>
                    <div
                      *ngIf="missingTag"
                      class="rounded-md bg-yellow-50 my-4 p-4">
                      <div class="flex">
                        <div class="flex-shrink-0">
                          <svg class="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                          </svg>
                        </div>
                        <div class="ml-3">
                          <h3 class="text-sm font-medium text-yellow-800">Missing Tags</h3>
                          <div class="mt-2 text-sm text-yellow-700">
                            <p>Tags are required to better match your community.</p>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="col-span-full">
                  <div class="sm:flex sm:flex-row-reverse">
                    <button
                      type="submit"
                      [disabled]="!communityForm.valid || saving"
                      class="disabled:bg-opacity-25 inline-flex items-center w-full justify-center rounded-md bg-main px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary-500 sm:ml-3 sm:w-auto">
                      <svg *ngIf="saving" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Save
                    </button>
                    <button
                      (click)="toggleModal()"
                      type="button"
                      class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
