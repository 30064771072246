import { Injectable } from '@angular/core';
import { AppSettingService } from './app-setting.service';
import { HttpClient } from '@angular/common/http';
import { of, tap } from 'rxjs';
import { TimezoneOffset } from '../model/timezone.model';

@Injectable({
  providedIn: 'root'
})
export class TimezoneService {
  timezones: TimezoneOffset[] = [];

  constructor(
    private appSettings: AppSettingService,
    private httpClient: HttpClient
  ) { }

  getTimezones() {
    if (this.timezones.length > 0) {
      return of(this.timezones);
    }

    const url: string = `${this.appSettings.settings.url}/timezones`;
    const api$ = this.httpClient.get<TimezoneOffset[]>(url);

    return api$.pipe(
      tap(timezones => this.timezones = timezones),
    );
  }

  filterZones(keyword: string){
    return this.timezones.filter(zone => zone.label.toLowerCase().includes(keyword.toLowerCase()));
  }


}
