
export class EventConfiguration{
    public constructor(
        public published: boolean = false,
        public sendWelcomeEmailOnImport: boolean = false,
        public allowExhibitorInConference: boolean = false,
        public allowVisitorsInExhibition: boolean  = false,
        public allowDashboardForExhibitor: boolean = false,
        public halalEvent: boolean = false,
        public automaticallySendFeedbackRequest: boolean = false,
    ){}
}