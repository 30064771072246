import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/shared/components/notifications/notification.service';
import { NotificationType } from 'src/app/shared/model/enum/notification-type.enum';
import { AttendeesService } from 'src/app/shared/service/attendees.service';
import { EmailService } from 'src/app/shared/service/email.service';
import { RegisterService } from 'src/app/shared/service/register.service';

@Component({
  selector: 'new-attendee',
  templateUrl: './new-attendee.component.html',
  styleUrl: './new-attendee.component.scss',
  animations: [
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 }))
      ])
    ]),
    trigger('opacityTranslateY', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(1rem)' }))
      ])
    ]),
  ]
})
export class NewAttendeeComponent implements OnInit {
  formGroup: FormGroup;
  isModal = false;
  isOptionsModal: boolean = false;
  saving: boolean = false;
  newProfile;
  sendingEmail: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private registerService: RegisterService,
    private attendeesService: AttendeesService,
    private emailService: EmailService,
    private notifyService: NotificationService
  ) { }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      salutation: [],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      company: [],
      occupation: []
    });
  }

  toggleModal() {
    this.isModal = !this.isModal;
  }

  toggleModalOption() {
    this.isOptionsModal = !this.isOptionsModal;
  }

  onSubmit() {
    if (this.saving) return;

    this.saving = true;
    const values = this.formGroup.getRawValue();

    this.registerService.registerToEvent(values).subscribe({
      next: (result) => {
        this.newProfile = result;
        this.saving = false;
        this.attendeesService.fetchAttendees();
        this.toggleModal();
        this.toggleModalOption();
      },
      error: () => {
        this.saving = false;
      }
    });
  }

  sendConfrimRegistration() {
    if (this.sendingEmail) return;
    this.sendingEmail = true;
    this.emailService
      .sendConfirmationEmail(this.newProfile.id)
      .subscribe({
        next: _ => {
          this.notifyService.notify('Confirmation Email', 'An email has been sent to your attendee.', NotificationType.Success);
          this.sendingEmail = false;
        },
        error: _ => {
          this.sendingEmail = false;
        }
      })
  }
}
