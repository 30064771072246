import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { PortalFlowService } from './portal-flow.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'portal-flow',
  templateUrl: './portal-flow.component.html',
  styleUrls: ['./portal-flow.component.scss'],
  animations: [
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 }))
      ])
    ]),
    trigger('opacityTranslateY', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(1rem)' }))
      ])
    ])
  ]
})
export class PortalFlowComponent implements OnInit, OnDestroy {
  isModal: boolean = false;
  isModalSub: Subscription | undefined;
  currentStep: number = 0;
  currentStepSub: Subscription | undefined;

  constructor(
    private portalFlowService: PortalFlowService
  ) { }

  ngOnInit(): void {
    this.portalFlowService.hasFlowStarted.subscribe({
      next: status => this.isModal = status
    });

    this.portalFlowService.activeStep.subscribe({
      next: step => this.currentStep = step,
    });
  }

  ngOnDestroy(): void {
    if (this.isModalSub) this.isModalSub.unsubscribe();
    if (this.currentStepSub) this.currentStepSub.unsubscribe();
  }

  toggleModal() {
    this.portalFlowService.toggle();
  }
}
