<div class="mx-auto">
  <div class="max-w-5xl mx-auto">
    <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Print Badges</h2>
    <p class="mt-6 text-lg leading-8 text-gray-600">Streamline the printing of attendee badges, ensuring
      high-quality, accurate, and ready-to-use badges for seamless event check-ins.</p>
    <div
      class="mt-10 rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-main">
      <label for="id" class="block text-xs font-medium text-slate-600">ID, email, First Name, Last Name, Group, External
        Reference</label>
      <input #searchInput type="text" name="id" id="id" (keyup)="search(getValue($event))" autofocus
        class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
        placeholder="Search By ID, email, First Name, Last Name, Group, External Reference">
    </div>
    <p class="text-xs text-slate-500 pl-1.5 pt-1.5">
      You need to write at lest 3 characters.
    </p>
  </div>


  <!-- <div class="mt-10 max-w-5xl mx-auto" *ngIf="(searchText$ | async)?.length > 2 && (result$ | async)?.length == 0">
    <div class="rounded-md bg-yellow-50 p-4">
      <div class="flex">
        <div class="flex-shrink-0">
          <svg class="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
          </svg>
        </div>
        <div class="ml-3">
          <h3 class="text-sm font-medium text-yellow-800">No results found!</h3>
          <div class="mt-2 text-sm text-yellow-700">
            <p>We did not find any results, please update your ID</p>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <div class="max-w-5xl mx-auto">
    <ul role="list" class="mx-auto mt-20 grid grid-cols-2 gap-x-10 gap-y-16 text-center sm:grid-cols-3 md:grid-cols-4">
      @for ( attendee of result$ | async; track $index) {
      <li (click)="onSelect(attendee)" class="hover:cursor-pointer">
        @if (attendee.user?.profilePhoto?.name) {
        <img class="mx-auto h-20 w-20 rounded-full" [src]="attendee.user.profilePhoto?.name | image | async" alt="">
        }@else {
        <svg class="mx-auto h-20 w-20 rounded-full text-slate-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
          stroke-width="1.5" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round"
            d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
        </svg>
        }
        <h3 class="mt-6 text-base font-semibold leading-7 tracking-tight text-slate-700">{{ attendee.salutation }} {{
          attendee.firstName }} {{ attendee.lastName }}</h3>
        <p class="mt-1 text-sm leading-6 text-slate-600">
          <span
            class="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">{{
            attendee.id }}</span>
        </p>
        @if (attendee.externalReference) {
        <p class="text-sm leading-6 text-slate-600 mt-1">
          <span
            class="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
            {{ attendee.externalReference }}
          </span>
        </p>
        }

        <p class="text-sm leading-6 text-slate-600">{{ attendee.email }}</p>
        <p class="text-sm leading-6 text-slate-600">{{ attendee.occupation }}
          <span *ngIf="attendee.company">at {{ attendee.company }}</span>
        </p>

        @if (attendee.tags && attendee.tags.length > 0) {
        <p class="text-sm leading-6 mt-1">
          @for ( tag of attendee.tags ; track $index ) {
          <span
            class="inline-flex items-center rounded-full bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-700 mr-1.5">{{
            tag }}</span>
          }
        </p>
        }
        @if (attendee.groups && attendee.groups.length > 0) {
        <p class="text-sm leading-6 mt-1">
          @for ( group of attendee.groups ; track $index ) {
          <span
            class="inline-flex items-center rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-700 mr-1.5">{{
            group }}</span>
          }
        </p>
        }

        <div *ngIf="selectedProfile && selectedProfile.id == attendee.id"
          class="flex justify-center items-center gap-x-3.5 mt-5">
          <button (click)="toggleModal()" type="button"
            class="w-36 inline-flex justify-center items-center gap-x-2 rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            Edit
            <svg class="-mr-0.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              stroke-width="1.5" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
            </svg>
          </button>

          <button (click)="print(attendee)" type="button"
            class="w-36 inline-flex justify-center items-center gap-x-2 rounded-md bg-main px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-main/75 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main">
            Print
            <svg class="-mr-0.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              stroke-width="1.5" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0 1 10.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0 .229 2.523a1.125 1.125 0 0 1-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0 0 21 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 0 0-1.913-.247M6.34 18H5.25A2.25 2.25 0 0 1 3 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 0 1 1.913-.247m10.5 0a48.536 48.536 0 0 0-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5Zm-3 0h.008v.008H15V10.5Z" />
            </svg>
          </button>
        </div>
      </li>
      }
    </ul>
  </div>

  <div *ngIf="selectedProfile">
    <div class="rounded-md flex justify-center">
      <div #badge class="block flex-col justify-center items-center bg-transparent w-[10.4cm] h-[14.9]">
        <div class="w-[10.4cm] h-[4cm]">

        </div>

        <div class="w-[10.4cm] h-[9.9cm] flex flex-col justify-evenly items-center">
          <div class="text-center">
            <h3 class="text-2xl font-title font-bold text-black">{{ salutation }} {{ firstName }} {{ lastName }}
            </h3>
            <p class="text-base font-semibold text-black font-text">{{ occupation }}</p>
            <p class="text-base font-text font-bold text-black uppercase">{{ company }}</p>
            <p class="text-base font-text font-bold text-black mt-1.5 uppercase">{{ country }}</p>
          </div>
          <div>
            <div class="flex justify-center mt-3">
              <img [src]="qrCodeUrl" width="90" height="90" alt="qrcode" alt="">
            </div>
            <div class="text-center">
              <span class="text-[7px] text-black font-text">{{ selectedProfile.id }}</span>
            </div>
          </div>
        </div>


        <div class="w-[10.4cm] h-[1cm]">

        </div>
      </div>
    </div>
  </div>


  <div *ngIf="isEditModal" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div @opacity *ngIf='isEditModal' class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      aria-hidden="true"></div>

    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div *ngIf='isEditModal' (click)="toggleModal()" class="fixed inset-0"></div>
      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div @opacityTranslateY *ngIf='isEditModal'
          class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-[777px] sm:p-6">
          <div>
            <form>
              <div>
                <h2 class="text-base font-semibold leading-7 text-gray-900">Badge Information</h2>
                <p class="mt-1 text-sm leading-6 text-gray-600">Updating badge information won't update this attendees
                  details!</p>

                <div class="mt-10 grid grid-cols-2 gap-x-6 gap-y-8">
                  <div class="sm:col-span-2">
                    <label for="salutation" class="block text-sm font-medium leading-6 text-gray-900">Salutation</label>
                    <div class="mt-2">
                      <input [(ngModel)]="salutation" type="text" name="salutation" id="salutation"
                        autocomplete="given-name"
                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    </div>
                  </div>

                  <div class="md:col-span-1 col-span-2">
                    <label for="firstName" class="block text-sm font-medium leading-6 text-gray-900">First Name</label>
                    <div class="mt-2">
                      <input [(ngModel)]="firstName" type="text" name="firstName" id="firstName"
                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    </div>
                  </div>

                  <div class="md:col-span-1 col-span-2">
                    <label for="lastName" class="block text-sm font-medium leading-6 text-gray-900">Last Name</label>
                    <div class="mt-2">
                      <input [(ngModel)]="lastName" type="text" name="lastName" id="lastName"
                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    </div>
                  </div>

                  <div class="md:col-span-1 col-span-2">
                    <label for="occupation" class="block text-sm font-medium leading-6 text-gray-900">Occupation</label>
                    <div class="mt-2">
                      <input [(ngModel)]="occupation" type="text" name="occupation" id="occupation"
                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    </div>
                  </div>

                  <div class="md:col-span-1 col-span-2">
                    <label for="company" class="block text-sm font-medium leading-6 text-gray-900">Company</label>
                    <div class="mt-2">
                      <input [(ngModel)]="company" type="text" name="company" id="company"
                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    </div>
                  </div>

                  <div class="col-span-2">
                    <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Coutry</label>
                    <div class="mt-2">
                      <input [(ngModel)]="country" type="text" name="country" id="country"
                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-6 flex items-center justify-end gap-x-6">
                <button (click)="cancel()" type="button"
                  class="text-sm font-semibold leading-6 text-gray-900">Cancel</button>
                <button (click)="save()" type="button"
                  class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--
  <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div @opacity *ngIf='isEditModal' class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      aria-hidden="true">
    </div>

    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div *ngIf='isEditModal' (click)="toggleModal()" class="fixed inset-0"></div>
      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div @opacityTranslateY *ngIf='isEditModal'
          class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all w-3/4 sm:my-8 sm:max-w-4xl sm:p-6">
          <div class="absolute top-2 right-3 hover:cursor-pointer">
            <div (click)="toggleModal()"
              class="size-7 flex justify-center items-center rounded-full bg-slate-100 hover:bg-slate-200">
              <svg class="size-6 text-slate-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
              </svg>
            </div>
          </div>
          <div class="mt-5 pb-7">
            <form>
              <div>
                <h2 class="text-base font-semibold leading-7 text-gray-900">Badge Information</h2>
                <p class="mt-1 text-sm leading-6 text-gray-600">Updating badge information won't update this attendees
                  details!</p>

                <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div class="sm:col-span-FULL">
                    <label for="salutation" class="block text-sm font-medium leading-6 text-gray-900">Salutation</label>
                    <div class="mt-2">
                      <input [(ngModel)]="salutation" type="text" name="salutation" id="salutation"
                        autocomplete="given-name"
                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    </div>
                  </div>

                  <div class="sm:col-span-1">
                    <label for="firstName" class="block text-sm font-medium leading-6 text-gray-900">First Name</label>
                    <div class="mt-2">
                      <input [(ngModel)]="firstName" type="text" name="firstName" id="firstName"
                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    </div>
                  </div>

                  <div class="sm:col-span-1">
                    <label for="lastName" class="block text-sm font-medium leading-6 text-gray-900">Last Name</label>
                    <div class="mt-2">
                      <input [(ngModel)]="lastName" type="text" name="lastName" id="lastName"
                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    </div>
                  </div>

                  <div class="sm:col-span-1">
                    <label for="occupation" class="block text-sm font-medium leading-6 text-gray-900">Occupation</label>
                    <div class="mt-2">
                      <input [(ngModel)]="occupation" type="text" name="occupation" id="occupation"
                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    </div>
                  </div>

                  <div class="sm:col-span-1">
                    <label for="company" class="block text-sm font-medium leading-6 text-gray-900">Company</label>
                    <div class="mt-2">
                      <input [(ngModel)]="company" type="text" name="company" id="company"
                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    </div>
                  </div>

                  <div class="sm:col-span-1">
                    <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Coutry</label>
                    <div class="mt-2">
                      <input [(ngModel)]="country" type="text" name="country" id="country"
                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-6 flex items-center justify-end gap-x-6">
                <button (click)="cancel()" type="button"
                  class="text-sm font-semibold leading-6 text-gray-900">Cancel</button>
                <button (click)="save()" type="button"
                  class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div> -->

</div>
