import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AttendeesComponent } from './attendees.component';
import { AttendeeComponent } from './list-of-attendees/attendee/attendee.component';
import { ListOfAttendeesComponent } from './list-of-attendees/list-of-attendees.component';
import { SharedModule } from '../shared/shared.module';
import { SearchAttendeeComponent } from './list-of-attendees/search-attendee/search-attendee.component';
import { AttendeeDetailsComponent } from './list-of-attendees/attendee-details/attendee-details.component';
import { AttendeeActionsComponent } from './list-of-attendees/attendee-details/attendee-actions/attendee-actions.component';
import { ProfileDetailsComponent } from './list-of-attendees/attendee-details/profile-details/profile-details.component';
import { AttendeeWorkshopComponent } from './list-of-attendees/attendee-details/attendee-workshop/attendee-workshop.component';
import { UploadAttendeesComponent } from './list-of-attendees/upload-attendees/upload-attendees.component';
import { NewAttendeeComponent } from './list-of-attendees/new-attendee/new-attendee.component';


@NgModule({
  declarations: [
    ListOfAttendeesComponent,
    AttendeesComponent,
    AttendeeComponent,
    AttendeeDetailsComponent,
    AttendeeActionsComponent,
    SearchAttendeeComponent,
    ProfileDetailsComponent,
    AttendeeWorkshopComponent,
    UploadAttendeesComponent,
    NewAttendeeComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
  ]
})
export class AttendeesModule { }
