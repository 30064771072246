import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { SchemaBuilderHelper } from './schema-builder-helper.service';
import { SchemaBuilderService } from './schema-builder.service';

@Injectable({
  providedIn: 'root'
})
export class DefaultFormTemplateService {

  constructor(
    private schemaBuilder: SchemaBuilderService,
    private formBuilderHelper: SchemaBuilderHelper
  ) {}

  loadDefaultTemplate() {
    return this.schemaBuilder.loadSchema();
  }

  updateFormFields(formlyFieldConfig: FormlyFieldConfig[]) {
    this.schemaBuilder.updateFormFields(formlyFieldConfig);
  }

  isDefaultField(key: string) {
    return this.formBuilderHelper.isDefaultField(key);
  }

  orderUp(key: string) {
    this.schemaBuilder.orderUp(key);
  }

  orderBottom(key: string) {
    this.schemaBuilder.orderDown(key);
  }

  removeField(key: string) {
    this.schemaBuilder.removeField(key);
  }

  canRemove(key: string) {
    return !this.isDefaultField(key);
  }

}
