import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { NotificationService } from '../components/notifications/notification.service';
import { NotificationType } from '../model/enum/notification-type.enum';

export interface Notify {
  title: string,
  message: string,
}

@Injectable({
  providedIn: 'root'
})
export class ErrorService {


  constructor(
    private notificationService: NotificationService
  ) { }


  handleError(error: HttpErrorResponse, notify: Notify | null, logError: boolean) {
    console.log(error);
    if (error?.status === 0) {
      if (logError)
        console.error('An error occurred:', error?.error);
    } else {
      if (logError)
        console.error(`Server returned code error ${error?.status}, with message: `, error?.error);
    }

    if (notify)
      this.notificationService.notify(notify.title, notify.message, NotificationType.Error);

    return throwError(() => new Error('Something bad happened; please try again later.'));
  }
}
