import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs';
import { NotificationService } from '../components/notifications/notification.service';
import { NotificationType } from '../model/enum/notification-type.enum';
import { AppSettingService } from './app-setting.service';
import { CustomEmailModel } from './email.service';
import { ErrorService } from './error.service';
import { EventService } from './event.service';

@Injectable({
  providedIn: 'root'
})
export class EmailTemplatesService {

  constructor(
    private eventService: EventService,
    private httpClient: HttpClient,
    private appSetting: AppSettingService,
    private notifyService: NotificationService,
    private errorService: ErrorService
  ) { }

  public updateTemplate(emailModel: CustomEmailModel) {
    const eventId: string = this.eventService.selectedEvent.value.id;
    const url: string = `${this.appSetting.settings.url}/email-templates/update-confirm-register-template/event/${eventId}`;

    const api$ = this.httpClient.patch(url, emailModel);

    return api$.pipe(
      tap(_ => this.notifyService.notify('Update Email Template', 'The template has been saved saved.', NotificationType.Success)),
      catchError(error => {
        const notify = { title: 'Update Email Template', message: 'An error happend while trying to update this template.' }
        return this.errorService.handleError(error, notify, true);
      }),
    );
  }

  public getConfirmRegistrationTemplate() {
    const eventId: string = this.eventService.selectedEvent.value.id;
    const url: string = `${this.appSetting.settings.url}/email-templates/get-confirm-register-template/event/${eventId}`;

    const api$ = this.httpClient.get(url);

    return api$.pipe(
      catchError(error => {
        const notify = { title: 'Email Template', message: 'We could n\'t load this template.' }
        return this.errorService.handleError(error, notify, true);
      }),
    );
  }
}
