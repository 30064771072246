import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import en from '@angular/common/locales/en';
import { FormsModule } from '@angular/forms';
import { AuthConfig, OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EventEditor } from './event-editor/event-editor.module';
import { PortalModule } from './portal/portal.module';
import { ApplicationInterceptor } from './shared/interceptors/application.interceptor';
import { StatehandlerProcessorService, StatehandlerProcessorServiceImpl } from './shared/service/statehandler-processor.service';
import { StatehandlerService, StatehandlerServiceImpl } from './shared/service/statehandler.service';
import { StorageService } from './shared/service/storage.service';
import { SharedModule } from './shared/shared.module';

registerLocaleData(en);


export const authConfig: AuthConfig = {
  scope: 'openid profile email offline_access',
  responseType: 'code',
  oidc: true,
  clientId: 'webapp',
  issuer: environment.authConfig.issuer,
  redirectUri: window.location.origin,
  requireHttps: false,
};

export const stateHandlerFn = (stateHandler: StatehandlerService) => {
  return () => {
    return stateHandler.initStateHandler();
  };
};


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    EventEditor,
    PortalModule,
    FormsModule,
    SharedModule,
    HttpClientModule,
    OAuthModule.forRoot(),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: stateHandlerFn,
      multi: true,
      deps: [StatehandlerService],
    },
    {
      provide: AuthConfig,
      useValue: authConfig,
    },
    {
      provide: StatehandlerProcessorService,
      useClass: StatehandlerProcessorServiceImpl,
    },
    {
      provide: StatehandlerService,
      useClass: StatehandlerServiceImpl,
    },
    {
      provide: OAuthStorage,
      useClass: StorageService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApplicationInterceptor,
      multi: true
    },
    { provide: NZ_I18N, useValue: en_US },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
