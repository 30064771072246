import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { format } from 'date-fns';
import { Subscription } from 'rxjs';
import { SchemaBuilderService } from 'src/app/dynamic-form/services/schema-builder.service';
import { CreateFormRequest } from 'src/app/shared/model/form/create-form-request';
import { FormDefinitionRequest } from 'src/app/shared/model/form/form-definition-request';
import { AppSettingService } from 'src/app/shared/service/app-setting.service';
import { RegistrationFormService } from 'src/app/shared/service/registration-form.service';

@Component({
  selector: 'event-new-form',
  templateUrl: './new-form.component.html',
  styleUrl: './new-form.component.scss',
  animations: [
    trigger('translateX', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('500ms ease-in-out', style({ transform: 'translateX(0)' }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)' }),
        animate('500ms ease-in-out', style({ transform: 'translateX(100%)' }))
      ])
    ])
  ]
})
export class NewFormComponent implements OnInit, OnDestroy {
  form: FormGroup = new FormGroup({});
  ready: boolean = false;
  model = {};
  options: FormlyFormOptions;
  fields: FormlyFieldConfig[];
  formSub: Subscription;

  loading: boolean = false;
  saving: boolean = false;

  selectedTab: string = 'custom';
  isSlideOver = false;

  constructor(
    private registrationFormService: RegistrationFormService,
    private appSettings: AppSettingService,
    private router: Router,
    private schemaBuilder: SchemaBuilderService,
  ) { }

  ngOnInit(): void {
    this.schemaBuilder.loadSchema();
    this.schemaBuilder.schema.subscribe({
      next: (_) => {
        this.form = new FormGroup({});
        this.fields = this.schemaBuilder.getFormFields();
        this.model = this.schemaBuilder.getModel();
        this.ready = true;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.formSub) this.formSub.unsubscribe();
    this.resetForm();
    this.schemaBuilder.reset();
  }

  redirectToForms() {
    this.router.navigate(['/event-editor/registration/forms']);
  }

  formatDate(date: Date): string {
    return format(date, this.appSettings.settings.dateAPIFormat);
  }

  submit() {
    this.saving = true;
    const schema = this.schemaBuilder.getSchema();

    const request: CreateFormRequest = new CreateFormRequest('', new FormDefinitionRequest(schema));

    this.registrationFormService
      .saveNewForm(request)
      .subscribe({
        next: (form) => {
          this.saving = false;
          this.resetForm();
          this.redirectToForms();

        },
        error: (error) => {
          this.saving = false;
        }
      });
  }

  resetForm() {
    this.form = new FormGroup({});
    this.ready = false;
    this.fields = [];
    this.model = {};
    if (this.options) {
      this.options.resetModel();
      this.options.updateInitialValue();
    }
  }

  onSelectTab(tab: string) {
    this.selectedTab = tab;
  }

  toggleSlideOver() {
    this.isSlideOver = !this.isSlideOver;
  }
}
