import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DefaultLaguages } from 'src/app/shared/model/default-languages';
import { Event } from 'src/app/shared/model/event/event.model';
import { Language } from 'src/app/shared/model/language.model';
import { UpdateBasicDetailsEvent } from 'src/app/shared/model/request/UpdateEventBasicDetails.request';
import { Tag } from 'src/app/shared/model/tag.model';
import { EventService } from 'src/app/shared/service/event.service';
import { LastUpdatesTrackerService } from 'src/app/shared/service/last-updates-tracker.service';

@Component({
  selector: 'event-basic-information',
  templateUrl: './basic-information.component.html',
  styleUrls: ['./basic-information.component.scss', '../event-information.component.scss']
})
export class BasicInformationComponent implements OnInit, OnDestroy {
  tags: Tag[] = [];
  languages: Language[] = DefaultLaguages;
  selectedLanguage: Language = new Language();
  showDropdownLanguage: boolean = false;
  activeLanguage: Language | null;
  form: FormGroup;
  saving: boolean = false;
  savingSub: Subscription;
  event: Event;
  eventSub: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private eventService: EventService,
    private updateTracker: LastUpdatesTrackerService
  ) { }

  ngOnInit(): void {
    this.eventSub = this.eventService.selectedEvent.subscribe({
      next: event => {
        this.event = event;
        this.initForm();
      },
    });
    this.savingSub = this.eventService.savingBasicData.subscribe({
      next: status => this.saving = status,
    });

  }

  ngOnDestroy(): void {
    if (this.eventSub) this.eventSub.unsubscribe();
    if (this.savingSub) this.savingSub.unsubscribe();
  }

  initForm() {
    this.tags = this.event.tags || [];
    this.selectedLanguage = this.event.language ? this.event.language : new Language();

    this.form = this.formBuilder.group({
      name: [this.event.name, [Validators.required]],
      tags: ['', []],
      language: [this.selectedLanguage.name, [Validators.required]],
      description: [this.event.description, [Validators.required]]
    });

  }

  onSubmit() {
    if (!this.form.valid) return;
    const values: any = this.form.getRawValue();
    const request: UpdateBasicDetailsEvent = new UpdateBasicDetailsEvent('', values.name, values.description, this.selectedLanguage, this.tags);
    this.eventService.updateBasicDetails(request);
  }

  onKeyUpTags(input: any) {
    const value: string = input.target.value;
    if (value.endsWith(',')) {
      this.addTag(value.replace(',', ''));
      this.form.controls['tags'].reset();
    }
  }

  addTag(label: string) {
    let tag = new Tag(label.trim());
    this.tags.push(tag);
  }

  removeTag(toRemove: Tag) {
    this.tags = this.tags.filter(tag => tag.label != toRemove.label);
  }

  onSelectLanguage(language: Language) {
    this.selectedLanguage = language;
    this.form.controls['language'].setValue(language.name);
    this.toggleLanguage();
  }

  toggleLanguage() {
    this.showDropdownLanguage = !this.showDropdownLanguage;
  }

  trackUpdate(menu: string) {
    this.updateTracker.newUpdate(menu);
  }
}
