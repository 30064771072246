import { Component, OnInit } from '@angular/core';
import { PortalFlowService } from '../portal-flow.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'portal-create-organisation',
  templateUrl: './create-organisation.component.html',
  styleUrls: ['./create-organisation.component.scss']
})
export class CreateOrganisationComponent implements OnInit {

  organisationForm: FormGroup = new FormGroup({});

  constructor(
    private portalFlowService: PortalFlowService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(){
    this.organisationForm = this.formBuilder.group({
      organisation: ['', [Validators.required]],
    });
  }

  onSubmit(){
    const value: string = this.organisationForm.controls['organisation'].value;
    this.portalFlowService.setOrganisation(value);
    this.next();
  }

  toggleModal(){
    this.portalFlowService.toggle();
  }

  next(){
    this.portalFlowService.nextStep();
  }

  cancel(){
    this.toggleModal();
  }

}
