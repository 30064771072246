import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormConfiguration } from 'src/app/shared/model/form/form-configuration.model';
import { Form } from 'src/app/shared/model/form/form.model';
import { RegistrationFormService } from 'src/app/shared/service/registration-form.service';

@Component({
  selector: 'app-edit-form',
  templateUrl: './edit-form.component.html',
  styleUrls: ['./edit-form.component.scss']
})
export class EditFormComponent implements OnInit {

  currentTab: string = 'FormDetails';

  form: Form;
  formSub: Subscription;
  configuration: FormConfiguration;

  constructor(
    private registrationFormService: RegistrationFormService
  ) { }

  ngOnInit(): void {
    this.formSub = this.registrationFormService.selected.subscribe({
      next: form => {
        this.form = form;
        this.configuration = this.form.formConfiguration;
      },
    });
  }

  ngOnDestroy(): void {
    if (this.formSub) this.formSub.unsubscribe();
  }


  onTabChange(selected: string){
    this.currentTab = selected;
  }

  editFormStatus(){
    this.currentTab = 'FormConfiguration';
  }

}
