<div class="w-full">
    <label id="listbox-label" class="sr-only">Select Menu</label>
    <div class="relative w-full">
      <div class="w-full inline-flex divide-x divide-indigo-700 rounded-md shadow-sm">
        <div class="w-full inline-flex items-center gap-x-1.5 rounded-l-md bg-indigo-600 py-2 px-3 text-white shadow-sm">
          <svg class="-ml-0.5 h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
          </svg>
            <p class="text-sm font-semibold">{{ current.name }}</p>
        </div>
        <button type="button" (click)="toggleSelect()" class="inline-flex items-center rounded-l-none rounded-r-md bg-indigo-600 p-2 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 focus:ring-offset-gray-50" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label">
          <span class="sr-only">Change Status</span>
          <svg class="h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
          </svg>
        </button>
      </div>
  
      <ul @opacityLeave *ngIf='isSelect' class="absolute right-0 z-10 mt-2 w-full origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-option-0">
        <li *ngFor="let item of list" (click)="select(item)" (mouseover)="setHighlight(item)"
          [ngClass]="{
          'text-white': highlight?.id === item.id,
          'bg-indigo-600': highlight?.id === item.id,
          'text-gray-900': highlight?.id !== item.id
          }"
          class="text-gray-900 cursor-default select-none p-4 text-sm" id="listbox-option-0" role="option">
          <div class="flex flex-col">
            <div class="flex justify-between">
                <p [ngClass]="{
                'font-semibold': current.id === item.id,
                'font-normal': current.id !== item.id
                }" class="font-normal">
                {{ item.name }}
              </p>
              <span *ngIf="item.id === current.id" 
                [ngClass]="{
                'text-white': highlight?.id === item.id,
                'text-indigo-600': highlight?.id !== item.id
                }" 
                class="text-indigo-600">
                <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                </svg>
              </span>
            </div>
 
            <p [ngClass]="{
              'text-indigo-200': highlight?.id === item.id,
              'text-gray-500': highlight?.id !== item.id
              }" 
              class="text-gray-500 mt-2">
              {{ item.description }}
            </p>
          </div>
        </li>
  
      </ul>
    </div>
  </div>