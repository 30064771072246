import { Community } from "../community.model";
import { EventConfiguration } from "./event-configuration.model";
import { EventSetting } from "./event-setting.model";
import { EventSchedule } from "./eventschedule.model";
import { Language } from "../language.model";
import { Location } from "../location.model";
import { Tag } from "../tag.model";
import { Theme, UploadedFile } from "../theme.model";
import { EventNature } from "../enum/event-nature.enum";
import { EventType } from "../enum/event-type.enum";
import { Sponsor } from "./sponsor.mode";

export class Event {
    public constructor(
        public id: string = '',
        public name: string = '',
        public description: string = '',
        public tags: Tag[] = [],
        public language: Language = new Language(),
        public createDate: string = '',
        public updateDate: string = '',
        public community: Community = new Community(),
        public theme: Theme = new Theme(),
        public location: Location = new Location(),
        public schedule: EventSchedule = new EventSchedule(),
        public configuration: EventConfiguration = new EventConfiguration(),
        public settings: EventSetting = new EventSetting(),
        public nature: EventNature = null,
        public type: EventType = null,
        public sponsors: Sponsor[] = [],
        public brochures: UploadedFile[] = [],
    ) { }
}
