import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Event } from 'src/app/shared/model/event/event.model';
import { EventService } from 'src/app/shared/service/event.service';
import { availableEventSettings } from './setting.model';

@Component({
  selector: 'app-event-setting',
  templateUrl: './event-setting.component.html',
  styleUrls: ['./event-setting.component.scss']
})
export class EventSettingComponent implements OnInit, OnDestroy {

  event: Event = new Event();
  eventSub: Subscription;
  settings = availableEventSettings;
  refresh: boolean = true;

  constructor(
    private eventService: EventService,
  ) { }

  ngOnInit(): void {
    this.eventSub = this.eventService.selectedEvent.subscribe({
      next: status => {
        this.refresh = false;
        this.event = status;
        this.refresh = true;
      },
    });
  }

  ngOnDestroy(): void {
      if (this.eventSub) this.eventSub.unsubscribe();
  }

}
