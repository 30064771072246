import { Injectable } from '@angular/core';
import { EventService } from './event.service';
import { AppSettingService } from './app-setting.service';
import { HttpClient } from '@angular/common/http';
import { Form } from '../model/form/form.model';
import { CreateFormRequest } from '../model/form/create-form-request';
import { UpdateRegistrationFormDetailsRequest } from '../model/form/update-form-details.request';
import { UpdateFormConfigurationRequest } from '../model/form/update-form-configuration.request';
import { NotificationService } from '../components/notifications/notification.service';
import { BehaviorSubject, catchError, tap } from 'rxjs';
import { NotificationType } from '../model/enum/notification-type.enum';
import { ErrorService } from './error.service';
import { UpdateFormSchema } from '../model/form/update-form-schema.request';

@Injectable({
  providedIn: 'root'
})
export class RegistrationFormService {
  private _formToEdit: Form;
  selected: BehaviorSubject<Form> = new BehaviorSubject(new Form());

  constructor(
    private eventService: EventService,
    private appSettings: AppSettingService,
    private httpClient: HttpClient,
    private notificationService: NotificationService,
    private errorService: ErrorService,
  ) { }


  saveNewForm(request: CreateFormRequest) {
    const eventId: string = this.eventService.selectedEvent.value.id;
    request.eventId = eventId;
    const url: string = `${this.appSettings.settings.url}/registration-form`;
    const api$ = this.httpClient.post<Form>(url, request);

    return api$.pipe(
      tap(result => this.setSelected(result)),
      tap(_ => this.notificationService.notify('Registration Form', 'New registration form created with success.', NotificationType.Success))
    );
  }

  updateExistingForm(request: UpdateRegistrationFormDetailsRequest) {
    const eventId: string = this.eventService.selectedEvent.value.id;
    request.eventId = eventId;
    const url: string = `${this.appSettings.settings.url}/registration-form/update-details`;
    const api$ = this.httpClient.patch<Form>(url, request);
    return api$.pipe(
      tap(result => this.setSelected(result)),
      tap(_ => this.notificationService.notify('Registration Form', 'Registration form has been updated.', NotificationType.Success))
    );
  }

  updateFormConfiguration(request: UpdateFormConfigurationRequest) {
    const url: string = `${this.appSettings.settings.url}/registration-form/update-configuration`;
    const api$ = this.httpClient.patch<Form>(url, request);
    return api$.pipe(
      tap(result => this.setSelected(result)),
      tap(_ => this.notificationService.notify('Registration Form', 'Registration form has been updated.', NotificationType.Success))
    );
  }

  updateFormSchema(request: UpdateFormSchema){
    const url: string = `${this.appSettings.settings.url}/registration-form/update-schema`;
    const api$ = this.httpClient.patch<Form>(url, request);
    return api$.pipe(
      tap(result => this.setSelected(result)),
      tap(_ => this.notificationService.notify('Registration Form', 'Registration form has been updated.', NotificationType.Success))
    );
  }

  loadForms() {
    const eventId: string = this.eventService.selectedEvent.value.id;
    const url: string = `${this.appSettings.settings.url}/registration-form/${eventId}`;
    const api$ = this.httpClient.get<Form[]>(url);

    return api$.pipe(
      catchError(error => {
        const notify = { title: 'Forms', message: 'We could not load the forms.' }
        return this.errorService.handleError(error, notify, true);
      })
    );
  }

  setFormToEdit(form: Form) {
    this._formToEdit = form;
  }

  setSelected(form: Form) {
    this.selected.next(form);
  }

  get editedForm() {
    return this._formToEdit;
  }
}
