import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Profile } from 'src/app/shared/model/user/profile.model';
import { AttendeesService } from 'src/app/shared/service/attendees.service';
import { DataExtractService } from 'src/app/shared/service/data-extract.service';

@Component({
  selector: 'list-of-attendees',
  templateUrl: './list-of-attendees.component.html',
  styleUrl: './list-of-attendees.component.scss'
})
export class ListOfAttendeesComponent implements OnInit, OnDestroy {
  attendees: Profile[] = [];
  attendeesSub: Subscription;
  attendeesSearchSub: Subscription;
  totalAttendees: number = 0;
  totalAttendeesSub: Subscription;
  searching: boolean = false;
  searchingSub: Subscription;
  selected: Profile;
  uploading: boolean = false;

  constructor(
    private attendeesService: AttendeesService,
    private dataExtractor: DataExtractService,
  ) { }

  ngOnInit(): void {
    this.attendeesService.fetchAttendees();

    this.totalAttendeesSub = this.attendeesService.totalAttendees.subscribe({
      next: status => this.totalAttendees = status,
    })

    this.attendeesService.searchStatus.subscribe({
      next: status => {
        this.attendeesSub = this.attendeesService.attendees.subscribe({
          next: (profiles) => {
            if (!status) {
              this.attendees = profiles;
              this.updateSelected();
            }
          },
        });

        this.attendeesSearchSub = this.attendeesService.searchResult.subscribe({
          next: (profiles) => {
            if (status) {
              this.attendees = profiles;
              this.updateSelected();
            } else if (profiles.length == 0 && !this.attendeesService.searchStatus.value) {
              this.attendees = this.attendeesService.attendees.value;
            }
          }
        });
      }
    });

    this.searchingSub = this.attendeesService.searching.subscribe({
      next: (status) => this.searching = status,
    });
  }

  ngOnDestroy(): void {
    if (this.searchingSub) this.searchingSub.unsubscribe();
    if (this.attendeesSub) this.attendeesSub.unsubscribe();
    if (this.attendeesSearchSub) this.attendeesSearchSub.unsubscribe();
    if (this.totalAttendeesSub) this.totalAttendeesSub.unsubscribe();

    this.attendeesService.resetSearch();
  }

  loadMore() {
    this.attendeesService.loadMore();
  }

  onSelectAttendee(attendee) {
    this.selected = attendee;
  }

  updateSelected() {
    if (this.selected) {
      const index = this.attendees.findIndex(attendee => attendee.id == this.selected.id);
      this.selected = this.attendees[index];
    }
  }

  extract() {
    this.dataExtractor.extractAttendees();
  }
}
