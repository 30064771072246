import { Injectable } from '@angular/core';
import { EventService } from './event.service';
import { AppSettingService } from './app-setting.service';
import { HttpClient } from '@angular/common/http';
import { Event } from '../model/event/event.model';
import { catchError, tap } from 'rxjs';
import { NotificationService } from '../components/notifications/notification.service';
import { NotificationType } from '../model/enum/notification-type.enum';

@Injectable({
  providedIn: 'root'
})
export class EventSettingService {

  constructor(
    private eventService: EventService,
    private appSettings: AppSettingService,
    private httpClient: HttpClient,
    private notificationService: NotificationService
  ) { }


  saveSetting(setting: string) {
    const currentEvent: Event = this.eventService.selectedEvent.value;
    const url: string = `${this.appSettings.settings.url}/event/${currentEvent.id}/setting/${setting}`;
    console.log(url);
    return this.httpClient
      .patch<Event>(url, {})
      .pipe(
        tap(event => {
          this.notificationService.notify('Event Saved', `${setting} has been updated`, NotificationType.Success, 'items-center');
          this.eventService.setSelectedEvent(event);
        }),
        catchError(error => {
          this.notificationService.notify('Event not Saved', `${setting} is not updated`, NotificationType.Error);
          return error;
        })
      );
  }

  resovleSelectedSetting(setting: string, event: Event){
      switch (setting) {
        case 'hasMeeting':
          return event.settings.hasMeeting;
        case 'hasRegistration':   
          return event.settings.hasRegistration;
        default:
          return false;
      }
  }
}
