<form
  [formGroup]="eventForm"
  (ngSubmit)="onSubmit()">
  <div class="space-y-12">
    <div class>
      <h2 class="text-center text-base font-semibold leading-7 text-gray-900">Create your Event</h2>
      <p class="text-center mt-1 text-sm leading-6 text-gray-600">Your event will be integrated into the community.</p>
      <div class="mt-7 grid grid-cols-1 gap-x-6 gap-y-7">
        <div class="col-span-full">
          <label
            for="event"
            class="block text-sm font-medium leading-6 text-gray-900">
            Event name
          </label>
          <div class="mt-2">
            <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-main">
              <input
                type="text"
                name="event"
                formControlName="event"
                id="event"
                autocomplete="event"
                class="placeholder:font-text block font-text flex-1 border-0 bg-transparent text-slate-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                placeholder="Enter your event name">
            </div>
          </div>
        </div>
        <div class="col-span-full">
          <div class="sm:flex sm:flex-row-reverse">
            <button
              type="submit"
              [disabled]="!eventForm.valid"
              class="disabled:bg-opacity-25 inline-flex w-full justify-center rounded-md w-btn px-4 py-2 text-sm font-semibold shadow-sm sm:ml-3 sm:w-auto">
              <span *ngIf="saving">
                <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                  viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                  </path>
                </svg>
                Processing...
              </span>
              <span *ngIf="!saving">Save</span>
            </button>
            <button
              (click)="previous()"
              type="button"
              class="mt-3 inline-flex w-full justify-center px-3 py-2 text-sm font-semibold shadow-sm w-btn-border sm:mt-0 sm:w-auto">
              Previous
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
