import { Component } from "@angular/core";
import { FieldType, FieldTypeConfig } from "@ngx-formly/core";

@Component({
  selector: 'custom-radio-type',
  template: `
    <fieldset class="flex gap-x-5">
    <legend class="text-sm font-semibold leading-3 text-slate-700">{{ props.label }}<span *ngIf="props.required" class="text-red-600 text-xs ml-0.5">*</span></legend>
    <div class="flex flex-wrap items-center justify-start gap-x-3 gap-y-4">
        <div *ngFor="let option of to.options; let i = index" class="mt-2 flex items-center gap-x-3">
                <input
                    id="radio-{{ option.value }}"
                    [name]="props.label"
                    [value]="option.value"
                    [formControl]="formControl"
                    type="radio"
                    class="h-4 w-4 border-gray-300 text-main focus:ring-main">
                <label
                    for="radio-{{ option.value }}"
                    class="block text-sm font-medium leading-6 text-gray-900">
                    {{ option.label }}
                </label>
          </div>
      </div>
    </fieldset>
    `
})
export class CustomRadioType extends FieldType<FieldTypeConfig> {


}
