import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Country } from 'src/app/shared/model/country.model';
import { Event } from 'src/app/shared/model/event/event.model';
import { Location } from 'src/app/shared/model/location.model';
import { UpdateEventLocationRequest } from 'src/app/shared/model/request/UpdateEventLocation.request';
import { WPCountry } from 'src/app/shared/model/shared/wpcountry.model';
import { WPState } from 'src/app/shared/model/shared/wpstate.mode';
import { CountryService } from 'src/app/shared/service/country.service';
import { EventService } from 'src/app/shared/service/event.service';
import { LastUpdatesTrackerService } from 'src/app/shared/service/last-updates-tracker.service';

@Component({
  selector: 'event-location',
  templateUrl: './event-location.component.html',
  styleUrls: ['./event-location.component.scss', '../event-information.component.scss']
})
export class EventLocationComponent implements OnInit, OnDestroy {
  event: Event;
  eventSub: Subscription;
  countries: WPCountry[] = [];
  states: WPState[] = [];

  selectedWpCountry: WPCountry = new WPCountry();
  selectedState: WPState;
  selectedCountry: Country = new Country();
  showDropdownCountry: boolean = false;
  showDropdownState: boolean = false;
  activeCountry: Country | null;
  activeState: string | null;
  saving: boolean = false;
  savingSub: Subscription;
  form: FormGroup;

  constructor(
    private countryService: CountryService,
    private formBuilder: FormBuilder,
    private eventService: EventService,
    private updateTracker: LastUpdatesTrackerService
  ) { }

  ngOnInit(): void {
    this.getCountries();
    this.savingSub = this.eventService.savingLocation.subscribe({
      next: status => this.saving = status,
    });
    this.eventSub = this.eventService.selectedEvent.subscribe({
      next: event => {
        this.event = event;
        this.initForm();
      },
    });
  }

  ngOnDestroy(): void {
    if (this.eventSub) this.eventSub.unsubscribe();
    if (this.savingSub) this.savingSub.unsubscribe();
  }

  initForm() {
    const country = this.event.location.country ? this.event.location.country.name : '';
    if (this.event.nature !== 'Virtual') {
      this.form = this.formBuilder.group({
        country: [country, [Validators.required]],
        state: [this.event.location.state, [Validators.required]],
        city: [this.event.location.city, [Validators.required]],
        postcode: [this.event.location.postcode, [Validators.required]],
        address: [this.event.location.address, [Validators.required]],
      });
    } else {
      this.form = this.formBuilder.group({
        country: [country],
        state: [this.event.location.state],
        city: [this.event.location.city],
        postcode: [this.event.location.postcode],
        address: [this.event.location.address],
      });
    }
    this.selectedCountry = country ? this.event.location.country : new Country();
    if (this.selectedCountry) {
      this.selectedWpCountry = this.countries.filter(wpCountry => wpCountry.name == this.selectedCountry.name)[0];
    }
  }

  getCountries() {
    this.countryService.countries().subscribe({
      next: (result) => { this.countries = result; },
    })
  }

  onSubmit() {
    if (!this.form.valid) return;
    const values = this.form.getRawValue();
    const location: Location = new Location(this.selectedCountry, values.state, values.city, values.postcode, values.address);
    const request: UpdateEventLocationRequest = new UpdateEventLocationRequest('', location);
    this.eventService.updateLocation(request);
  }

  onSelectCountry(country: WPCountry) {
    this.selectedWpCountry = country;
    this.states = country.states;
    this.selectedCountry = new Country(country.code, country.name);
    this.form.controls['country'].setValue(this.selectedCountry.name);
    this.toggleCountry();
  }

  onSelectState(state: WPState) {
    this.form.controls['state'].setValue(state.name);
    this.toggleState();
  }

  toggleCountry() {
    this.showDropdownCountry = !this.showDropdownCountry;
  }

  toggleState() {
    this.showDropdownState = !this.showDropdownState;
  }

  onSearchCountry(input: any) {
    this.showDropdownCountry = true;
    const value = input.target.value;
    this.countries = this.countryService.cacheList
    if (value && value.length > 0) {
      this.countries = this.countries.filter(country => country.name.toLowerCase().includes(value.toLowerCase()));
    } else {
      this.countries = this.countryService.cacheList;
    }
  }

  onSearchState(input: any) {
    this.showDropdownState = true;
    const value = input.target.value;
    if (value && value.length > 0) {
      this.states = this.selectedWpCountry.states.filter(state => state.name.toLowerCase().includes(value.toLowerCase()))
    } else {
      this.states = this.selectedWpCountry.states;
    }
  }

  trackUpdate(menu: string) {
    this.updateTracker.newUpdate(menu);
  }

}
