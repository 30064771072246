import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Event } from 'src/app/shared/model/event/event.model';
import { EventService } from 'src/app/shared/service/event.service';
import { availableEventConfiguration } from './configuration.model';

@Component({
  selector: 'event-configurations',
  templateUrl: './event-configuration.component.html',
  styleUrls: ['./event-configuration.component.scss']
})
export class EventConfigurationComponent implements OnInit, OnDestroy {
  
  event: Event = new Event();
  eventSub: Subscription;
  configurations = availableEventConfiguration;
  refresh: boolean = true;

  constructor(
    private eventService: EventService,
  ) { }

  ngOnInit(): void {
    this.eventSub = this.eventService.selectedEvent.subscribe({
      next: status => {
        this.refresh = false;
        this.event = status;
        this.refresh = true;
      },
    });
  }

  ngOnDestroy(): void {
      if (this.eventSub) this.eventSub.unsubscribe();
  }

}
