import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/shared/components/notifications/notification.service';
import { defaultFilesTypeSupported } from 'src/app/shared/model/accepted-upload-formats';
import { NotificationType } from 'src/app/shared/model/enum/notification-type.enum';
import { AppSettingService } from 'src/app/shared/service/app-setting.service';
import { AttendeesService } from 'src/app/shared/service/attendees.service';
import { EventService } from 'src/app/shared/service/event.service';
import { FileUploaderService } from 'src/app/shared/service/file-uploader.service';

@Component({
  selector: 'upload-attendees',
  templateUrl: './upload-attendees.component.html',
  styleUrl: './upload-attendees.component.scss',
  animations: [
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 }))
      ])
    ]),
    trigger('opacityTranslateY', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(1rem)' }))
      ])
    ]),
  ]
})
export class UploadAttendeesComponent implements OnInit {
  isModal = false;
  uploading: boolean = false;
  result: any[] = [];

  constructor(
    private appSettings: AppSettingService,
    private eventSevrice: EventService,
    private fileUploader: FileUploaderService,
    private notificationService: NotificationService,
    private attendeesService: AttendeesService
  ) { }

  ngOnInit(): void {

  }

  toggleModal() {
    this.isModal = !this.isModal;
  }

  onSelectFile(selectFile: any) {
    this.uploading = true;
    this.isModal = true;
    const eventId: string = this.eventSevrice.selectedEvent.value.id;
    const url: string = `profile/upload-profiles?eventId=${eventId}`;

    this.fileUploader
      .onFileSelect(selectFile, url, this.appSettings.settings.maxFileSizeMo, defaultFilesTypeSupported)
      .subscribe({
        next: (result: any[]) => {
          this.result = result;
          this.uploading = false;
          this.notificationService.notify('Attendees', 'List of attendees is uploaded successfully!', NotificationType.Success);
          this.attendeesService.fetchAttendees();
        },
        error: error => {
          this.uploading = false;
          this.notificationService.notify('Attendees', 'An error occured', NotificationType.Error);
          console.log(error);
          this.isModal = false;
        }
      });
  }

  private convertResultToCSV(result: any[]) {
    const csvString = [
      [
        'Line In File',
        'First Name',
        'Last Name',
        'Email',
        'Log Message'
      ],
      ...result.map(item => [
        item.line,
        item.firstName,
        item.lastName,
        item.email,
        item.logMessage
      ])
    ]
      .map(el => el.join(','))
      .join("\n")


    this.downloadFile(csvString);
  }

  private downloadFile(csvContent: string) {
    let blob = new Blob(['\ufeff' + csvContent], {
      type: 'text/csv;charset=utf-8;'
    });
    let downloadLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    downloadLink.setAttribute("href", url);
    downloadLink.setAttribute("download", 'upload_logs' + ".csv");
    downloadLink.style.visibility = "hidden";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
}
