import { Injectable } from '@angular/core';
import { BehaviorSubject, map, tap } from 'rxjs';
import { Community } from 'src/app/shared/model/community.model';
import { AppSettingService } from './app-setting.service';
import { HttpClient } from '@angular/common/http';
import { OrganisationService } from './organisation.service';
import { CreateCommunityRequest } from 'src/app/shared/model/request/CreateCommunity.request';
import { UserService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class CommunityService {
  communities: BehaviorSubject<Community[]> = new BehaviorSubject<Community[]>([]);
  pagesize: number = 27;
  page: number = 0;
  isFirstPage: boolean = true;
  isLastPage: boolean = false;
  organisationId: string;


  constructor(
    private appSettings: AppSettingService,
    private userService: UserService,
    private organisationService: OrganisationService,
    private httpClient: HttpClient
  ) { }

  fetchCommunitiesByOrganisation(organisationId: string) {
    const url: string = `${this.appSettings.settings.url}/community/of/${organisationId}?page=${this.page}&size=${this.pagesize}`;
    const api$ = this.httpClient.get(url);
    this.organisationId = organisationId;

    api$.pipe(
      map((result: any) => {
        this.isFirstPage = result.first;
        this.isLastPage = result.last;
        return <Community[]>result.content;
      }),
      tap(communities => this.communities.next(communities)),
    ).subscribe();
  }

  createCommunity(request: CreateCommunityRequest) {
    request.organisationId = this.organisationService.organisation.id;
    const url: string = `${this.appSettings.settings.url}/community`;
    const api$ = this.httpClient
      .post(url, request)
      .pipe(
        map(_ => this.userService.getCurrentSession().subscribe())
      );

    return api$;
  }

  searchByKeywordAndOrg(keyword: string, organisationId: string) {
    const url: string = `${this.appSettings.settings.url}/community/search/${keyword}/in/${organisationId}`;
    const api$ = this.httpClient.get<Community[]>(url);
    return api$;
  }

  loadMore() {
    if (this.isLastPage) return;
    this.page = this.page + 1;
    this.fetchCommunitiesByOrganisation(this.organisationId);
  }
}
