import { Injectable } from '@angular/core';
import { EventService } from './event.service';
import { AppSettingService } from './app-setting.service';
import { HttpClient } from '@angular/common/http';
import { Event } from '../model/event/event.model';
import { catchError, tap } from 'rxjs';
import { NotificationService } from '../components/notifications/notification.service';
import { NotificationType } from '../model/enum/notification-type.enum';

@Injectable({
  providedIn: 'root'
})
export class EventConfigurationService {

  constructor(
    private eventService: EventService,
    private appSettings: AppSettingService,
    private httpClient: HttpClient,
    private notificationService: NotificationService
  ) { }

  saveConfiguration(configuration: string) {
    const currentEvent: Event = this.eventService.selectedEvent.value;
    const url: string = `${this.appSettings.settings.url}/event/${currentEvent.id}/configuration/${configuration}`;
    return this.httpClient
      .patch<Event>(url, {})
      .pipe(
        tap(event => {
          this.notificationService.notify('Event Saved', `${configuration} has been updated`, NotificationType.Success, 'items-center');
          this.eventService.setSelectedEvent(event);
        }),
        catchError(error => {
          console.log(error);
          this.notificationService.notify('Event not Saved', `${configuration} is not updated`, NotificationType.Error);

          return error;
        })
      );
  }

  resovleSelectedSetting(setting: string, event: Event){
      switch (setting) {
        case 'published':
          return event.configuration.published;
        case 'sendWelcomeEmailOnImport':   
          return event.configuration.sendWelcomeEmailOnImport;
        case 'allowExhibitorInConference':
          return event.configuration.allowExhibitorInConference;
        case 'allowVisitorsInExhibition':
          return event.configuration.allowVisitorsInExhibition;
          case 'allowDashboardForExhibitor':
            return event.configuration.allowDashboardForExhibitor;
          case 'halalEvent':
            return event.configuration.halalEvent;
          case 'automaticallySendFeedbackRequest':
            return event.configuration.automaticallySendFeedbackRequest;
        default:
          return false;
      }
  }


}
