import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'form-builder-debug',
  templateUrl: './builder-debug.component.html',
  styleUrls: ['./builder-debug.component.scss']
})
export class BuilderDebugComponent {
  @Input() form: FormGroup;
  @Input() model: any;
  @Input() submittedValue: any;

  isExpanded = false;
  selectedTab: string = 'FormValue';

  constructor() { }

  toggleOpen(){
    this.isExpanded = !this.isExpanded;
  }

  onTabChange(tag: string){
    this.selectedTab = tag;
  }

  isCurrentTab(tab: string){
    return tab == this.selectedTab;
  }
}
