import { EventNature } from "../enum/event-nature.enum";
import { EventType } from "../enum/event-type.enum";
import { Tag } from "../tag.model";

export class CreateEventRequest{
    public constructor(
        public name: string = '',
        public description: string = '',
        public tags: Tag[] = [],
        public type: EventType  = EventType.Exhibition,
        public nature: EventNature = EventNature.Onsite,
        public communityId: string = '',
    ){}
}