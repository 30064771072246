import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { ConfirmationOfRegistrationComponent } from './emails/confirmation-of-registration/confirmation-of-registration.component';
import { CustomEmailComponent } from './emails/custom-email/custom-email.component';
import { EmailNotificationsComponent } from './emails/email-notifications/email-notifications.component';
import { NotificationMenuComponent } from './notification-menu/notification-menu.component';
import { NotificationsComponent } from './notifications.component';
@NgModule({
  declarations: [
    NotificationsComponent,
    EmailNotificationsComponent,
    CustomEmailComponent,
    NotificationMenuComponent,
    ConfirmationOfRegistrationComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    FormsModule,
  ]
})
export class NotificationsModule { }
