import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { EventService } from '../service/event.service';

@Injectable({
  providedIn: 'root'
})
export class EventEditorGuard  {

  constructor(
    private eventService: EventService,
    private router: Router){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(!this.eventService.selectedEvent.value || this.eventService.selectedEvent.value.id == null || this.eventService.selectedEvent.value.id.length == 0){
        this.router.navigate(['/']);
      }

    return true;
  }
  
}
