import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Form } from 'src/app/shared/model/form/form.model';
import { RegistrationFormService } from 'src/app/shared/service/registration-form.service';

@Component({
  selector: 'event-form',
  templateUrl: './form.component.html',
  styleUrl: './form.component.scss'
})
export class FormComponent implements OnInit {
  @Input('form') form: Form;
  bgColors: string[] = [
    'bg-emerald-500 hover:bg-emerald-300',
    'bg-green-500 hover:bg-green-300',
    'bg-yellow-500 hover:bg-yellow-300',
    'bg-amber-500 hover:bg-amber-300',
    'bg-teal-500 hover:bg-teal-300',
    'bg-sky-500 hover:bg-sky-300'];
    bgColor: string;

  constructor(
    private registrationFormService: RegistrationFormService,
    private router: Router
  ) { }

  ngOnInit(): void {
      this.bgColor = this.getRandomColor();
  }

  edit(form: Form) {
    this.registrationFormService.setFormToEdit(form);
    this.registrationFormService.setSelected(form);
    this.router.navigate(['/event-editor/registration/edit-form']);
  }

  getInitials() {
    if(!this.form.name) this.form.name = 'New Form';
    return this.form.name.split(' ').map(word => word[0].toUpperCase()).join("");
  }

  getRandomColor() {
    const index = Math.floor(Math.random() * 6);
    return this.bgColors[index];
  }
}
