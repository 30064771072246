<div>
  <side-bar-menu></side-bar-menu>
  <div class="relative">
    <nav-bar-menu></nav-bar-menu>
    <main class="relative py-10 mt-7 lg:ml-24 lg:-mr-15 lg:pr-12">
      <div class="mx-4 md:mx-auto max-w-7xl bg-white rounded-2xl border border-slate-200">
        <router-outlet></router-outlet>
      </div>
    </main>
  </div>
</div>


<!-- removed padding px-4 sm:px-6 lg:px-8-->
