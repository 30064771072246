import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, tap } from 'rxjs';
import { Event } from 'src/app/shared/model/event/event.model';
import { CreateEventRequest } from 'src/app/shared/model/request/CreateEvent.request';
import { environment } from '../../../environments/environment';
import { UpdateEventNatureRequest } from '../model/event/update-event-nature.request';
import { UpdateEventTypeRequest } from '../model/event/update-event-type.request';
import { Form } from '../model/form/form.model';
import { UpdateBasicDetailsEvent } from '../model/request/UpdateEventBasicDetails.request';
import { UpdateEventLocationRequest } from '../model/request/UpdateEventLocation.request';
import { UpdateEventSchedule } from '../model/request/UpdateEventSchedule.request';
import { FloatingBannersService, Message } from './../components/floating-notification/floating-banners.service';
import { AppSettingService } from './app-setting.service';


@Injectable({
  providedIn: 'root'
})
export class EventService {
  events: BehaviorSubject<Event[]> = new BehaviorSubject<Event[]>([]);
  pagesize: number = 27;
  page: number = 0;
  isFirst: boolean = true;
  isLast: boolean = false;
  organisationId: string;
  selectedEvent: BehaviorSubject<Event> = new BehaviorSubject<Event>(new Event());
  savingBasicData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  savingLocation: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  savingSchedule: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


  constructor(
    private appSettings: AppSettingService,
    private httpClient: HttpClient,
    private floatingBannersService: FloatingBannersService
  ) { }

  create(request: CreateEventRequest) {
    const url: string = `${this.appSettings.settings.url}/event`;
    const api$ = this.httpClient.post<Event>(url, request);
    return api$;
  }

  fetchEventsByOrganisation(organisationId: string) {
    const url: string = `${this.appSettings.settings.url}/event/organisation/${organisationId}?page=${this.page}&size=${this.pagesize}&sort=updateDate,desc`;
    const api$ = this.httpClient.get(url);
    this.organisationId = organisationId;

    return api$.pipe(
      map((result: any) => {
        this.isFirst = result.first;
        this.isLast = result.last;
        return <Event[]>result.content;
      }),
      map(events => {
        return events.map(event => {
          event = this.setLogoPath(event);
          event = this.setBannerPath(event);
          event = this.setBackgroundPath(event);
          return event;
        })
      }),
      tap(events => this.events.next(events))
    ).subscribe();
  }

  updateBasicDetails(request: UpdateBasicDetailsEvent) {
    this.savingBasicData.next(true);
    request.eventId = this.selectedEvent.value.id;

    const url: string = `${this.appSettings.settings.url}/event/update/basic-details`;
    const api$ = this.httpClient.patch<Event>(url, request);

    api$.subscribe({
      next: event => {
        this.setSelectedEvent(event);
        // this.notificationService.notify('Update Information', 'Event saved', NotificationType.Success);
        const msg: Message = {
          title: 'Event Information',
          text: 'Your event has been updated',
          bgColor: 'bg-lime-500',
          textColor: 'text-white',
          isCloasable: false
        }
        this.floatingBannersService.display(msg);
      },
      error: error => { this.onError(error); },
    });
  }

  updateLocation(request: UpdateEventLocationRequest) {
    this.savingLocation.next(true);
    request.eventId = this.selectedEvent.value.id;

    const url: string = `${this.appSettings.settings.url}/event/update/location`;
    const api$ = this.httpClient.patch<Event>(url, request);

    api$.subscribe({
      next: event => {
        this.setSelectedEvent(event);
        // this.notificationService.notify('Update Location', 'Event saved', NotificationType.Success);
        const msg: Message = {
          title: 'Event Location',
          text: 'Your event has been updated',
          bgColor: 'bg-lime-500',
          textColor: 'text-white',
          isCloasable: false
        }
        this.floatingBannersService.display(msg);
      },
      error: error => { this.onError(error); },
    });
  }

  updateSchedule(request: UpdateEventSchedule) {
    this.savingSchedule.next(true);
    request.eventId = this.selectedEvent.value.id;

    const url: string = `${this.appSettings.settings.url}/event/update/schedule`;
    const api$ = this.httpClient.patch<Event>(url, request);

    api$.subscribe({
      next: event => {
        this.setSelectedEvent(event);
        // this.notificationService.notify('Update Schedule', 'Event saved', NotificationType.Success);
        const msg: Message = {
          title: 'Event Schedule',
          text: 'Your event has been updated',
          bgColor: 'bg-lime-500',
          textColor: 'text-white',
          isCloasable: false
        }
        this.floatingBannersService.display(msg);
      },
      error: error => { this.onError(error); },
    });
  }

  updateEventNature(request: UpdateEventNatureRequest) {
    request.eventId = this.selectedEvent.value.id;
    const url: string = `${this.appSettings.settings.url}/event/update/nature`;
    const api$ = this.httpClient.patch<Event>(url, request);

    api$.subscribe({
      next: event => {
        this.setSelectedEvent(event);
        // this.notificationService.notify('Update Event', 'Event nature saved', NotificationType.Success);
        const msg: Message = {
          title: 'Event Nature',
          text: 'Your event has been updated',
          bgColor: 'bg-lime-500',
          textColor: 'text-white',
          isCloasable: false
        }
        this.floatingBannersService.display(msg);
      },
      error: error => { this.onError(error); },
    });
  }

  updateEventType(request: UpdateEventTypeRequest) {
    request.eventId = this.selectedEvent.value.id;
    const url: string = `${this.appSettings.settings.url}/event/update/type`;
    const api$ = this.httpClient.patch<Event>(url, request);

    api$.subscribe({
      next: event => {
        this.setSelectedEvent(event);
        // this.notificationService.notify('Update Event', 'Event nature saved', NotificationType.Success);
        const msg: Message = {
          title: 'Event Type',
          text: 'Your event has been updated',
          bgColor: 'bg-lime-500',
          textColor: 'text-white',
          isCloasable: false
        }
        this.floatingBannersService.display(msg);
      },
      error: error => { this.onError(error); },
    });
  }

  loadMore() {
    if (this.isLast || !this.organisationId) return;
    this.page = this.page + 1;
    this.fetchEventsByOrganisation(this.organisationId);
  }

  setSelectedEvent(event: Event) {
    event = this.setLogoPath(event);
    event = this.setBannerPath(event);
    event = this.setBackgroundPath(event);
    this.savingBasicData.next(false);
    this.savingLocation.next(false);
    this.savingSchedule.next(false);
    this.selectedEvent.next(event);
  }

  generateEventLink(event: Event) {
    if (!event.name) return "";
    let name: string = event.name.replace(/[&\/\\#,+()$~%.'":*?<>{}-]/g, '');
    const eventRoute = name.trim().split(' ').filter(c => c != '').join('-');
    const eventUrl: string = `${environment.event_url}/${eventRoute}-${event.id}`;
    return eventUrl;
  }

  generateRegistrationLink(event: Event, form: Form) {
    if (!event.name) return "";
    return `${this.generateEventLink(event)}/register/${form.id}`;
  }


  private setLogoPath(event: Event) {
    const logopath: string = event.theme.logo.name || '';
    if (logopath.length > 0 && !logopath.startsWith('/api')) {
      event.theme.logo.name = `${this.appSettings.settings.url}/uploads/${event.theme.logo.name}`;
    }
    return event;
  }

  private setBannerPath(event: Event) {
    const bannerpath: string = event.theme.banner.name || '';
    if (bannerpath.length > 0 && !bannerpath.startsWith('/api')) {
      event.theme.banner.name = `${this.appSettings.settings.url}/uploads/${event.theme.banner.name}`;
    }
    return event;
  }

  private setBackgroundPath(event: Event) {
    const backgroundpath: string = event.theme.background.name || '';
    if (backgroundpath.length > 0 && !backgroundpath.startsWith('/api')) {
      event.theme.background.name = `${this.appSettings.settings.url}/uploads/${event.theme.background.name}`;
    }
    return event;
  }

  private onError(error: any) {
    console.log(error);
    this.savingBasicData.next(false);
    this.savingLocation.next(false);
    this.savingSchedule.next(false);
  }

}
