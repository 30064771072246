import { Component, Input } from '@angular/core';
import { Ticket } from 'src/app/shared/model/ticket/ticket.model';

@Component({
  selector: 'view-vertical',
  templateUrl: './vertical.component.html',
  styleUrls: ['./vertical.component.scss']
})
export class VerticalComponent {
  @Input('ticket') ticket: Ticket;

  get isMostPopular() {
    const index: number = this.ticket.meta_data.findIndex(data => data.key == 'isMostPopular');
    if (index == -1) return false;

    return this.ticket.meta_data[index].value == '1'
  }


  get description() {
    return this.ticket?.description.replace('<p>', '').replace('</p>', '') || '';
  }

  get whatsIncluded(): string[]{
    const index: number = this.ticket.meta_data.findIndex(data => data.key == 'includes');
    if (index == -1) return [];

    return this.ticket.meta_data[index].value;
  }
}
