import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Community } from 'src/app/shared/model/community.model';
import { Event } from 'src/app/shared/model/event/event.model';
import { CommunityService } from 'src/app/shared/service/community.service';
import { EventService } from 'src/app/shared/service/event.service';
import { OrganisationService } from 'src/app/shared/service/organisation.service';

@Component({
  selector: 'app-portal-communities',
  templateUrl: './portal-communities.component.html',
  styleUrls: ['./portal-communities.component.scss']
})
export class PortalCommunitiesComponent implements OnInit, OnDestroy {
  communities: Community[] = [];
  communitiesSub: Subscription | undefined;

  isCommunityModal: boolean = false;
  isEventModal: boolean = false;

  events: Event[] = [];
  eventsSub: Subscription;
  organisationSub: Subscription;

  constructor(
    private communityService: CommunityService,
    private eventService: EventService,
    private organisationService: OrganisationService,
  ) { }

  ngOnInit(): void {
    this.listenForCommunities();
    this.listenForEvents();
    this.organisationSub = this.organisationService.selectedOrg.subscribe({
      next: (organisation) => {
        if(organisation && organisation.id){
          this.communityService.fetchCommunitiesByOrganisation(organisation.id);
          this.eventService.fetchEventsByOrganisation(organisation.id);
        }
      },
    });

  }

  ngOnDestroy(): void {
    if (this.communitiesSub) this.communitiesSub.unsubscribe();
    if (this.organisationSub) this.organisationSub.unsubscribe();
    if (this.eventsSub) this.eventsSub.unsubscribe();
  }

  listenForCommunities() {
    this.communitiesSub = this.communityService.communities.subscribe({
      next: (communities) => this.communities = communities,
    });
  }

  listenForEvents() {
    this.eventsSub = this.eventService.events.subscribe({
      next: events => {this.events = events;},
    });
  }

  openCommunityModal() {
    this.isCommunityModal = true;
  }

  closeCommunityModal() {
    this.isCommunityModal = false;
  }

  openEventModal() {
    this.isEventModal = true;
  }

  closeEventModal() {
    this.isEventModal = false;
  }

}
