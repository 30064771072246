import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { Event } from 'src/app/shared/model/event/event.model';
import { EventService } from 'src/app/shared/service/event.service';
import { LastUpdate, LastUpdatesTrackerService } from 'src/app/shared/service/last-updates-tracker.service';

@Component({
  selector: 'event-information',
  templateUrl: './event-information.component.html',
  styleUrls: ['./event-information.component.scss']
})
export class EventInformationComponent implements OnInit, OnDestroy {
  @Input('event') event: Event = new Event();
  eventSub: Subscription;
  lastUpdates: LastUpdate[] = [];

  constructor(
    private eventService: EventService,
    private updateTracker: LastUpdatesTrackerService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.eventSub = this.eventService.selectedEvent.subscribe({
      next: event => this.event = event,
    });
    this.lastUpdates = this.updateTracker.getLastUpdates();
  }

  ngOnDestroy(): void {
    if (this.eventSub) this.eventSub.unsubscribe();
  }

  sanitizeSVG(svg: string){
    return this.sanitizer.bypassSecurityTrustHtml(svg);
  }

  trackUpdate(menu: string) {
    this.updateTracker.newUpdate(menu);
  }

}
