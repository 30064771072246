<div class="px-4 sm:px-6 lg:px-8">
    <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
            <h1 class="text-base font-semibold leading-6 text-gray-900">Forms</h1>
            <p class="mt-2 text-sm text-gray-700">A list of all the forms in your event including their dates, status
                and other details.</p>
        </div>
        <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
        </div>
    </div>
    <div *ngIf="!loading && forms.length == 0" class="mt-8 rounded-md bg-blue-50 p-4">
        <div class="flex">
            <div class="flex-shrink-0">
                <svg class="h-5 w-5 text-blue-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z"
                        clip-rule="evenodd" />
                </svg>
            </div>
            <div class="ml-3 flex-1 md:flex md:justify-between">
                <p class="text-sm text-blue-700">You don't have any registred form, start be creating one!</p>
                <p class="mt-3 text-sm md:ml-6 md:mt-0">
                    <a (click)="openNewFormModal()"
                        class="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600">
                        Create New Form<span aria-hidden="true"> →</span>
                    </a>
                </p>
            </div>
        </div>
    </div>
    <div class="-mx-4 mt-8 sm:-mx-0">
        <ul class="flex justify-center items-start flex-wrap gap-x-6 gap-y-6">
            @for (form of forms; track $index) {
            <li>
                <event-form [form]="form"></event-form>
            </li>
            }
            <li>
                <a [routerLink]="'../new-form'" class="group">
                    <div class="flex flex-col gap-y-2 justify-start items-center">
                        <div>
                            <div
                                class="w-24 h-24 bg-main shadow-md rounded-md flex justify-center items-center hover:bg-main/80  hover:cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke="currentColor" class="w-9 h-9 stroke-white stroke-1">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                </svg>
                            </div>
                        </div>
                        <div class="text-center">
                            <p class="text-sm font-normal text-gray-700 group-hover:text-gray-900">New Form</p>
                        </div>
                    </div>
                </a>
            </li>
        </ul>
    </div>
</div>