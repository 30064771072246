<div class="mx-4 my-3 md:mx-auto max-w-7xl">
  <div class="pointer-events-none inset-x-0 top-0 sm:pb-5">
    <div
      [ngClass]="bgColor"
      class="pointer-events-auto flex items-center justify-between gap-x-6 px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5">
      <p
        class="leading-6"
        [ngClass]="textColor">
            <a (click)="takeAction()">
                <span class="font-semibold text-base">{{ title }}</span>
                <svg viewBox="0 0 2 2" class="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true"><circle cx="1" cy="1" r="1" /></svg>
                <span class="text-base">{{ text }}<span class="ml-3.5" aria-hidden="true">→</span> </span>
            </a>
        </p>
   <!--   <button
        type="button"
        class="-m-3 flex-none p-3 focus-visible:outline-offset-[-4px]">
        <span class="sr-only">Dismiss</span>
        <svg class="h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
        </svg>
      </button> -->
    </div>
  </div>
</div>
