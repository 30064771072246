import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, Subscription, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import { Community } from 'src/app/shared/model/community.model';
import { CreateEventRequest } from 'src/app/shared/model/request/CreateEvent.request';
import { Tag } from 'src/app/shared/model/tag.model';
import { CommunityService } from 'src/app/shared/service/community.service';
import { EventService } from 'src/app/shared/service/event.service';
import { OrganisationService } from 'src/app/shared/service/organisation.service';

@Component({
  selector: 'portal-new-event',
  templateUrl: './new-event.component.html',
  styleUrls: ['./new-event.component.scss'],
  animations: [
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 }))
      ])
    ]),
    trigger('opacityTranslateY', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(1rem)' }))
      ])
    ]),
  ]
})
export class NewEventComponent implements OnInit, OnDestroy {
  @Input('isModal') isModal: boolean = false;
  @Output('closeModal') close: EventEmitter<boolean> = new EventEmitter(false);

  eventForm: FormGroup = new FormGroup({});
  tags: string[] = [];
  missingTag: boolean = false;
  saving: boolean = false;
  searching: boolean = false;
  communities: Community[] = [];

  searchSub: Subscription | undefined;
  searchText: Subject<string> = new Subject();
  selected: Community = new Community();
  active: Community | null = null;
  showDropdown = false;


  constructor(
    private formBuilder: FormBuilder,
    private communityService: CommunityService,
    private organisationService: OrganisationService,
    private eventService: EventService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(): void {
    if (this.searchSub) this.searchSub.unsubscribe();
  }

  initForm() {
    this.eventForm = this.formBuilder.group({
      community: ['', [Validators.required]],
      name: ['', [Validators.required]],
      description: ['', [Validators.required]],
      tags: [''],
    });
    this.debonceSearch();
  }

  debonceSearch() {
    this.searchSub = this.searchText.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((keyword) => this.search(keyword))
    ).subscribe({
      next: results => {
        this.communities = results;
        this.searching = false;
        if (this.communities.length == 0) this.showDropdown = false;
      },
    });
  }

  search(community: string) {
    this.searching = true;
    const organisationId: string = this.organisationService.organisation.id;
    return this.communityService.searchByKeywordAndOrg(community, organisationId);
  }

  onSubmit() {
    if (this.tags.length == 0) {
      this.missingTag = true;
      return;
    }
    let eventTags: Tag[] = [];
    this.tags.map(tag => eventTags.push(new Tag(tag)));

    const values = this.eventForm.getRawValue();
    const request = new CreateEventRequest(values.name, values.description, eventTags, undefined, undefined, this.selected.id);

    this.eventService.create(request).subscribe({
      next: (event) => {
        this.saving = false;
        this.eventService.setSelectedEvent(event);
        this.router.navigate(['/event-editor']);
      },
      error: _ => {
        this.saving = false;
        console.log(_);
      }
    });

  }

  onKeyUp(input: any) {
    const value: string = input.target.value;
    if (value.endsWith(',')) {
      this.addTag(value.replace(',', ''));
      this.eventForm.controls['tags'].reset();
    }
  }

  toggleModal() {
    this.isModal = !this.isModal;
    this.onClose();
  }

  onClose() {
    this.close.emit(true);
  }

  addTag(tag: string) {
    this.tags.push(tag);
    this.missingTag = false;
  }

  removeTag(toRemove: string) {
    this.tags = this.tags.filter(tag => tag != toRemove);
  }

  getInputValue(input: any) {
    const keyword: string = input.target.value
    if (keyword.length >= 2) {
      this.searchText.next(keyword);
      this.showDropdown = true;
    };
  }

  toggle() {
    this.showDropdown = !this.showDropdown;
  }

  select(option: Community) {
    this.selected = option;
    this.toggle();
  }

}
