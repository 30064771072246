import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Event } from 'src/app/shared/model/event/event.model';
import { EventService } from 'src/app/shared/service/event.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { NotificationService } from 'src/app/shared/components/notifications/notification.service';
import { NotificationType } from 'src/app/shared/model/enum/notification-type.enum';


interface ListItem {
  id: number;
  name: string;
  description: string;
}

@Component({
  selector: 'share-event',
  templateUrl: './share-event.component.html',
  styleUrls: ['./share-event.component.scss'],
  animations: [
    trigger('opacityLeave', [
      transition(':leave', [
        style({ opacity: 1 }),
        animate('100ms ease-in', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class ShareEventComponent implements OnInit {
  event: Event;
  eventSub: Subscription;
  eventUrl: string = '';

  list: ListItem[] = [
    {
      id: 1,
      name: "View Event Page",
      description: "Open the event page in a new tab. Your attendees will visit the same page."
    },
    {
      id: 2,
      name: "Share Event Page",
      description: "Copy the event url to share it any where."
    },
    {
      id: 3,
      name: "Registration Page",
      description: "Copy the registration link to share it any where."
    }
  ]

  isSelect = false;
  current: ListItem = this.list[0];
  highlight: ListItem | undefined;

  constructor(
    private eventService: EventService,
    private clipboard: Clipboard,
    private notification: NotificationService
  ) { }

  ngOnInit(): void {
    this.eventSub = this.eventSub = this.eventService.selectedEvent.subscribe({
      next: event => {
        this.event = event;
        this.generateEventLink();
      },
    })
  }

  toggleSelect() {
    this.isSelect = !this.isSelect;
  }

  select(item: ListItem) {
    this.current = item;
    this.toggleSelect();
    this.onClick();
  }

  onClick(){
    if(this.current.id == 1 ) this.openLink();
    if(this.current.id == 2) this.copyLink();
    if(this.current.id == 3) this.getRegistrationLink();
  }

  setHighlight(item: ListItem) {
    this.highlight = item;
  }

  copyLink() {
    this.clipboard.copy(this.eventUrl);
    this.notification.notify('Url copied', 'You can share the event url.', NotificationType.Success);
  }

  generateEventLink() {
    this.eventUrl = this.eventService.generateEventLink(this.event);
  }

  getRegistrationLink(){
   /* const link: string = this.eventService.generateRegistrationLink(this.event);
    this.clipboard.copy(link);
    this.notification.notify('Registration Link', 'You can share the registraion page.', NotificationType.Success); */
  }

  openLink(){
    window.open(this.eventUrl, '_blank');
  }

}
