import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Profile } from 'src/app/shared/model/user/profile.model';
import { AttendeesService } from 'src/app/shared/service/attendees.service';

export interface workshop {
  id: number,
  label: string,
  date: string
}

@Component({
  selector: 'attendee-workshop',
  templateUrl: './attendee-workshop.component.html',
  styleUrl: './attendee-workshop.component.scss'
})
export class AttendeeWorkshopComponent implements OnChanges {
  @Input('attendee') attendee: Profile;

  loading: number = 0;
  workshops: workshop[] = [];
  availableWs: workshop[] = [
    { id: 134, label: 'Deal Room 1', date: 'Due on May 2, 2024, 12:12' },
    { id: 135, label: 'Deal Room 2', date: 'Due on May 2, 2024, 12:00' },
    { id: 136, label: 'Deal Room 3', date: 'Due on May 2, 2024, 12:30' },
    { id: 137, label: 'Deal Room 4', date: 'Due on May 2, 2024, 15:00' },
    { id: 138, label: 'Deal Room 5', date: 'Due on May 2, 2024, 15:00' },
    { id: 139, label: 'Deal Room 6', date: 'Due on May 2, 2024, 15:00' },
    { id: 140, label: 'Deal Room 7', date: 'Due on May 2, 2024, 15:00' },
  ];

  constructor(
    private attendeesService: AttendeesService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.initWorkshops();
  }

  initWorkshops() {
    this.workshops = [];
    if (this.attendee['workshopIds']) {
      let ws: number[] = this.attendee['workshopIds'];
      ws.map(id => {
        this.workshops.push(this.getWsById(id));
      });
    }
  }

  getWsById(id: number) {
    const index = this.availableWs.findIndex(ws => ws.id == id);
    return this.availableWs[index];
  }

  isRegistred(id: number) {
    const found = this.workshops.filter(ws => ws.id == id);
    return found.length > 0;
  }

  isPresent(id) {
    const index = -1;
    return index != -1;
  }

  setPresence(workshopId: number) {
    this.loading = workshopId;
    this.attendeesService
      .setWorkshopPresence(this.attendee.id, workshopId)
      .subscribe({
        next: _ => {
          this.loading = 0;
        },
        error: _ => {
          this.loading = 0;
        }
      });
  }

  register(workshopId) {
    this.loading = workshopId;
    this.attendeesService
      .registerInWorkshop(this.attendee.id, workshopId)
      .subscribe({
        next: _ => {
          this.loading = 0;
        },
        error: _ => {
          this.loading = 0;
        }
      });
  }


}
