import { Component } from '@angular/core';

@Component({
  selector: 'attendees',
  templateUrl: './attendees.component.html',
  styleUrl: './attendees.component.scss'
})
export class AttendeesComponent {

}
