import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { User } from 'src/app/shared/model/user.model';
import { Profile } from 'src/app/shared/model/user/profile.model';
import { AttendeesService } from 'src/app/shared/service/attendees.service';

@Component({
  selector: 'attendee-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrl: './profile-details.component.scss'
})
export class ProfileDetailsComponent implements OnChanges {
  @Input('attendee') attendee: Profile;
  @Input('user') user: User;
  qrCodeUrl: string = '';
  metaFieldKeys: string[];


  constructor(
    private attendeesService: AttendeesService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.getProfileQRCodeURL();
    this.metaFieldKeys = Object.keys(this.attendee.metaField);
  }

  getProfileQRCodeURL() {
    this.attendeesService.getAttendeeQrCodeURL(this.attendee.id).subscribe({
      next: (result) => {
        this.qrCodeUrl = result.url;
      },
      error: (er) => console.log(er),
    })
  }

}
