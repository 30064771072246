<nav aria-label="Progress" class="overflow-x-auto">
    <ol role="list" class="flex space-x-8 space-y-0 gap-x-3 gap-y-1 whitespace-nowrap">
        <li *ngFor="let step of field.fieldGroup; let index = index; let last = last" class="md:flex-1">
            <a (click)="setCurrentIndex(index)"
                [ngClass]='{"hover:border-main border-main/75": isCompleted(index) || isCurrent(index)}'
                class="group flex flex-col border-l-4  py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4 border-gray-200  hover:border-gray-300">
                <span [ngClass]='{"group-hover:text-main text-main/75": isCompleted(index) || submitted}'
                    class="text-sm font-medium text-gray-500 group-hover:text-gray-700">
                    Step {{ index + 1 }} / {{ step.props.totalSteps }}
                </span>
                <span class="text-sm font-medium">{{ step.props.stepTitle }}</span>
            </a>
        </li>
    </ol>
</nav>

<formly-field [field]="field.fieldGroup[currentIndex]"></formly-field>

<div class="flex justify-end w-full" [ngClass]="{'flex justify-between': currentIndex != 0 }">
    <button (click)="previous()" *ngIf="currentIndex != 0" type="button"
        class="w-40 inline-flex items-center gap-x-1.5 px-3.5 py-2.5 text-sm font-semibold text-slate-600">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
        </svg>
    Previous Step
    </button>

    <button (click)="next()" *ngIf="!isLast()" type="button"  
        class="w-32 inline-flex items-center gap-x-1.5 px-3.5 py-2.5 text-sm font-semibold text-slate-600">
        Next Step
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
         </svg>          
    </button>

   <!-- 
    [disabled]="!isValid(field.fieldGroup[currentIndex])"
    <button *ngIf="isLast()" type="submit" (click)='onSubmit()' [disabled]="!form.valid"
        class="w-32 rounded-md bg-main px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-main/70 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main">
        Submit
    </button> -->
</div>