import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'danger-dialog',
  templateUrl: './danger-dialog.component.html',
  styleUrls: ['./danger-dialog.component.scss'],
  animations: [ 
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({  opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 }))
      ])
    ]),
    trigger('opacityTranslateY', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate('300ms ease-out', style({  opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(1rem)' }))
      ])
    ])
  ]
})
export class DangerDialogComponent {
  @Input('isModal') isModal = false;
  @Input('title') title: string;
  @Input('subTitle') subTitle: string;
  @Input('submitLabel') submitLabel: string = 'Continue';
  @Input('cancelLabel') cancelLabel: string = 'Cancel';

  @Output('onClose') onClose: EventEmitter<boolean> = new EventEmitter(false);

  constructor() { }
  
  toggleModal(status: boolean){
    this.isModal = !this.isModal;
    this.onClose.emit(status);
  }
}
