import { Component, OnInit } from '@angular/core';
import { Form } from 'src/app/shared/model/form/form.model';
import { Ticket } from 'src/app/shared/model/ticket/ticket.model';
import { RegistrationFormService } from 'src/app/shared/service/registration-form.service';
import { TicketService } from 'src/app/shared/service/ticket.service';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent implements OnInit {

  loadingForms: boolean = true;
  forms: Form[] = [];
  selectedForm: Form;

  constructor(
    private registrationFormService: RegistrationFormService,
    private ticketService: TicketService
  ) {

  }

  ngOnInit(): void {
    this.getForms();
  }


  getForms() {
    this.loadingForms = true;
    this.registrationFormService
      .loadForms()
      .subscribe({
        next: forms => {
          this.forms = forms;
          this.forms.map(form => this.loadTickets(form));
          this.loadingForms = false;
        },
        error: _ => {
          this.loadingForms = false;
        }
      })
  }

  isMostPopular(ticket: Ticket) {
    return this.ticketService.isMostPopular(ticket);
  }

  loadTickets(form: Form) {
    form.loadingTickets = true;
    this.ticketService
      .fetchTicketsOfForm(form.id)
      .subscribe({
        next: results => {
          form.tickets = results;
          form.loadingTickets = false;
        },
        error: _ => form.loadingTickets = false,
      })
  }

  updateTicket(ticket: Ticket, form: Form) {
    this.loadTickets(form);

  }
}
