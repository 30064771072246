export class UploadedFile {
    public constructor(
        public name: string = '',
        public originalFileName: string = '',
        public sizeMb: string = '',
    ) { }
}


export class Theme {
    public constructor(
        public logo: UploadedFile = new UploadedFile(),
        public banner: UploadedFile = new UploadedFile(),
        public background: UploadedFile = new UploadedFile(),
    ) { }
}

