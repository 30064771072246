import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Editor } from 'primeng/editor';


@Component({
  selector: 'rich-text-input',
  templateUrl: './rich-text-input.component.html',
  styleUrl: './rich-text-input.component.scss',
  encapsulation: ViewEncapsulation.Emulated
})
export class RichTextInputComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('editor') editorComponent: Editor;
  @Input('defaultTemplate') defaultTemplate: string;
  @Output('onInputChange') onInputChange: EventEmitter<string> = new EventEmitter();
  htmlContent: string;

  ngAfterViewInit() {}

  ngOnInit(): void {
  }

  editorOnInit(event: any){
    const quillInstance = event.editor;
    if(this.defaultTemplate.length > 0){
      quillInstance.container.firstChild.innerHTML = this.defaultTemplate;
    }
  }

  ngOnDestroy(): void {
  }

  onChange() {
    this.onInputChange.emit(this.htmlContent);
  }

}
