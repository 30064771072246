import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Event } from 'src/app/shared/model/event/event.model';
import { EventSettingService } from 'src/app/shared/service/event-setting.service';

@Component({
  selector: 'event-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})
export class SettingComponent implements OnInit {
  @Input('event') event: Event = new Event();
  @Input('setting') setting = '';
  @Input('description') description = '';
  @Input('displayName') displayName = '';
  @Input('icon') icon;
  isEnabled = false;
  isSaving: boolean = false;


  constructor(
    private eventSettingService: EventSettingService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.isEnabled = this.eventSettingService.resovleSelectedSetting(this.setting, this.event);
    this.icon = this.sanitizer.bypassSecurityTrustHtml(this.icon);
  }

  toggle() {
    this.eventSettingService.saveSetting(this.setting).subscribe({
      next: _ => {
        this.isSaving = false;
        this.isEnabled = !this.isEnabled;
      },
      error: _ => this.isSaving = false,
    });
  }
}
