import { Component, OnInit } from '@angular/core';
import { Form } from 'src/app/shared/model/form/form.model';
import { RegistrationFormService } from 'src/app/shared/service/registration-form.service';

@Component({
  selector: 'event-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss']
})
export class FormsComponent implements OnInit {
  forms: Form[] = [];
  loading: boolean = false;

  constructor(
    private registrationFormService: RegistrationFormService
  ) { }

  ngOnInit(): void {
    this.loadForms();
  }

  loadForms() {
    this.loading = true;
    this.registrationFormService
      .loadForms()
      .subscribe({
        next: (result) => {
          this.loading = false;
          this.forms = result;
        },
        error: (error) => {
          this.loading = false;
        }
      });
  }


}
