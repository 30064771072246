import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import { AppSettingService } from './app-setting.service';
import { EventService } from './event.service';

@Injectable({
  providedIn: 'root'
})
export class DataExtractService {

  constructor(
    private appSettings: AppSettingService,
    private httpClient: HttpClient,
    private eventService: EventService
  ) { }


  extractAttendees(){
    const eventId: string = this.eventService.selectedEvent.value.id;
    const fileName: string = 'attendees_' + this.eventService.selectedEvent.value.name + new Date() + '.csv';
    const api = this.httpClient.get(`${this.appSettings.settings.url}/extract/attendees/${eventId}`, { responseType: 'blob' });
    api.subscribe({
      next: r => {FileSaver.saveAs(r, fileName)},
      error: rr => console.log(rr),
    })
  }
}
