 import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';

export interface InputForm { name: string, desc: string, input: string, form: string };


@Injectable({
  providedIn: 'root'
})
export class SchemaBuilderHelper {
  private defaultFieldsKeys = [
    'salutation',
    'firstName',
    'lastName',
    'email',
    'mobilePhone',
    'country',
    'businessCity',
    'address',
    'company',
    'occupation',
    'myIndustry',
    'myOffer',
    'myInterests',
    'lookingFor'
  ];
  currentStepperIndex: number = 0;

  setCurrentStepperIndex(index: number) {
    this.currentStepperIndex = index;
  }

  removeField(schema: any, formFields: FormlyFieldConfig[], fieldKey: string) {
    formFields[0].fieldGroup[this.currentStepperIndex].fieldGroup = formFields[0].fieldGroup[this.currentStepperIndex].fieldGroup.filter(field => field.key != fieldKey);
    schema = this.removeFieldFromStep(schema, fieldKey);
    return {fields: formFields, schema: schema};
  }

  isSchemaField(formFields: FormlyFieldConfig[], keyfield: string){
    let found = -1;
    for (let index = 0; index < 4; index++) {
      const fieldIndex = formFields[0].fieldGroup[this.currentStepperIndex].fieldGroup.findIndex(field => field.key == keyfield);
      if(fieldIndex != -1) found = fieldIndex;
    }

    return found != -1;
  }

  updateOrderUp(schema: any, formFields: FormlyFieldConfig[], key: string) {
    const formIndex = formFields[0].fieldGroup[this.currentStepperIndex].fieldGroup.findIndex(field => field.key == key);
    if (formIndex == 0) return null;

    const currentElement = formFields[0].fieldGroup[this.currentStepperIndex].fieldGroup[formIndex];
    const temp = formFields[0].fieldGroup[this.currentStepperIndex].fieldGroup[formIndex - 1];
    formFields[0].fieldGroup[this.currentStepperIndex].fieldGroup[formIndex - 1] = currentElement;
    formFields[0].fieldGroup[this.currentStepperIndex].fieldGroup[formIndex] = temp;
    this.orderUpOnSchema(schema, key);
    return {fields: formFields, schema: schema};
  }

  updateOrderDown(schema: any, formFields: FormlyFieldConfig[], key: string) {
    const formIndex = formFields[0].fieldGroup[this.currentStepperIndex].fieldGroup.findIndex(field => field.key == key);
    const lastIndex = formFields[0].fieldGroup[this.currentStepperIndex].fieldGroup.length - 1;
    if (formIndex == lastIndex) return null;

    const currentElement = formFields[0].fieldGroup[this.currentStepperIndex].fieldGroup[formIndex];
    const temp = formFields[0].fieldGroup[this.currentStepperIndex].fieldGroup[formIndex + 1];
    formFields[0].fieldGroup[this.currentStepperIndex].fieldGroup[formIndex + 1] = currentElement;
    formFields[0].fieldGroup[this.currentStepperIndex].fieldGroup[formIndex] = temp;
    this.orderDownOnSchema(schema, key);
    return {fields: formFields, schema: schema};
  }

  getCurrentSchemaStep(schema: any) {
    if (this.currentStepperIndex == 0) {
      return schema.step1;
    }

    if (this.currentStepperIndex == 1) {
      return schema.step2;
    }

    if (this.currentStepperIndex == 2) {
      return schema.step3;
    }
  }

  private removeFieldFromStep(schema: any, fieldKey: string) {
    const step = this.getCurrentSchemaStep(schema);
    step.fields = step.fields.filter(field => field.key != fieldKey);
    return schema;
  }

  private orderUpOnSchema(schema: any, fieldKey: string) {
    let step = this.getCurrentSchemaStep(schema);
    const currentIndex = step.fields.findIndex(field => field.key == fieldKey);
    if (currentIndex == 0) return;

    const currentElement = step.fields[currentIndex];
    const temp = step.fields[currentIndex - 1];

    step.fields[currentIndex - 1] = currentElement;
    step.fields[currentIndex] = temp;
  }

  private orderDownOnSchema(schema: any, fieldKey: string) {
    let step = this.getCurrentSchemaStep(schema);
    const currentIndex = step.fields.findIndex(field => field.key == fieldKey);
    const lastIndex = step.fields.length - 1;
    if (currentIndex == lastIndex) return null;

    const currentElement = step.fields[currentIndex];
    const temp = step.fields[currentIndex + 1];

    step.fields[currentIndex + 1] = currentElement;
    step.fields[currentIndex] = temp;
  }

  isDefaultField(key:string){
    return this.defaultFieldsKeys.includes(key);
  }

}
