import { Component } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { SchemaBuilderHelper } from 'src/app/dynamic-form/services/schema-builder-helper.service';

@Component({
    selector: 'formly-stepper-wrapper',
    templateUrl: './stepper-wrapper.component.html',
    styleUrls: ['./stepper-wrapper.component.scss']
})
export class StepperWrapperComponent extends FieldType {
    currentIndex = 0;
    submitted: boolean = false;

    constructor(
        private schemaBuilderHelper: SchemaBuilderHelper,
    ) {
        super();
    }

    isCompleted(stepIndex: number) {
        return this.currentIndex > stepIndex;
    }

    isCurrent(stepIndex: number) {
        return this.currentIndex == stepIndex;
    }

    isUpcoming(stepIndex: number) {
        return this.currentIndex < stepIndex;
    }

    isLast() {
        return (this.currentIndex + 1) == this.field.fieldGroup[this.currentIndex].props.totalSteps;
    }

    next() {
        this.currentIndex = this.currentIndex + 1;
        this.schemaBuilderHelper.setCurrentStepperIndex(this.currentIndex);
    }

    previous() {
        this.currentIndex = this.currentIndex - 1;
        this.schemaBuilderHelper.setCurrentStepperIndex(this.currentIndex);
    }

    setCurrentIndex(index) {
        this.currentIndex = index;
        this.schemaBuilderHelper.setCurrentStepperIndex(this.currentIndex);
    }

    isValid(field: FormlyFieldConfig): boolean {
        if (field.key) {
            return field.formControl.valid;
        }

        return field.fieldGroup ? field.fieldGroup.every((f) => this.isValid(f)) : true;
    }

    onSubmit() {
        this.submitted = true;
    }
}
