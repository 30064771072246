import { Injectable } from '@angular/core';
import { AppSettingService } from './app-setting.service';
import { HttpClient } from '@angular/common/http';
import { NewSponsorRequest, UpdateSponsorRequest } from '../model/event/crud-sponsor.request';
import { ErrorService } from './error.service';
import { catchError, tap } from 'rxjs';
import { EventService } from './event.service';
import { Sponsor } from '../model/event/sponsor.mode';
import { Event } from '../model/event/event.model';
import { FileUploaderService } from './file-uploader.service';
import { NotificationService } from '../components/notifications/notification.service';
import { NotificationType } from '../model/enum/notification-type.enum';
import { defaultImageTypesSupported } from '../model/accepted-upload-formats';

@Injectable({
  providedIn: 'root'
})
export class SponsorService {

  constructor(
    private appSettings: AppSettingService,
    private httpClient: HttpClient,
    private errorService: ErrorService,
    private eventService: EventService,
    private fileUploader: FileUploaderService,
    private notificationService: NotificationService,
  ) { }


  newSponsor(request: NewSponsorRequest) {
    const url: string = `${this.appSettings.settings.url}/sponsor`;
    const api$ = this.httpClient.post<Sponsor>(url, request);

    return api$.pipe(
      tap(sponsor => {
        let event: Event = this.eventService.selectedEvent.value;
        event.sponsors.push(sponsor);
        this.eventService.setSelectedEvent(event);
      }),
      catchError(error => this.errorService.handleError(error, { title: 'Sponsor', message: 'We could not create this sponsor.' }, true)),
    );
  }

  removeSponsor(sponsorId: string){
    const url: string = `${this.appSettings.settings.url}/sponsor?sponsorId=${sponsorId}&eventId=${this.eventService.selectedEvent.value.id}`;
    const api$ = this.httpClient.delete(url);

    return api$.pipe(
      tap( _ => {
       let event = this.eventService.selectedEvent.value;
       event.sponsors = event.sponsors.filter(sponsor => sponsor.id != sponsorId);
       this.eventService.setSelectedEvent(event);
      }),
      catchError(error => this.errorService.handleError(error, { title: 'Sponsor', message: 'We could not remove this sponsor.' }, true))
    );
  }

  updateSponsor(request: UpdateSponsorRequest){
    const url: string = `${this.appSettings.settings.url}/sponsor`;
    const api$ = this.httpClient.patch<Event>(url, request);

    return api$.pipe(
      tap(event => {
        this.eventService.setSelectedEvent(event);
      }),
      catchError(error => this.errorService.handleError(error, { title: 'Sponsor', message: 'We could not create this sponsor.' }, true)),
    );
  }

  uploadLogo(selected, endpoint) {
    return this.fileUploader
      .onFileSelect<Event>(selected, endpoint, this.appSettings.settings.maxImageSizeMo, defaultImageTypesSupported)
      .pipe(
        tap(event => {
          this.eventService.setSelectedEvent(event);
          this.notificationService.notify('Update Cover', 'Event saved', NotificationType.Success);
        }),
        catchError(error => this.errorService.handleError(error, { title: 'Sponsor Logo', message: 'We could not upload this logo.' }, true)),
      );
  }
}
