import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventNature } from 'src/app/shared/model/enum/event-nature.enum';
import { Event } from 'src/app/shared/model/event/event.model';
import { UpdateEventNatureRequest } from 'src/app/shared/model/event/update-event-nature.request';
import { EventService } from 'src/app/shared/service/event.service';
import { LastUpdatesTrackerService } from 'src/app/shared/service/last-updates-tracker.service';

interface ListItem {
  id: string;
  title: string;
  description: string;
}

@Component({
  selector: 'event-nature',
  templateUrl: './event-nature.component.html',
  styleUrls: ['./event-nature.component.scss', '../event-information.component.scss']
})
export class EventNatureComponent implements OnInit, OnDestroy {
  event: Event;
  eventSub: Subscription;

  saving: boolean = false;

  list: ListItem[] = [
    {
      id: EventNature.Onsite,
      title: EventNature.Onsite,
      description: "Experience the event in person with face-to-face interactions and live activities at the venue."
    },
    {
      id: EventNature.Virtual,
      title: EventNature.Virtual,
      description: "Engage with speakers and participants from anywhere in the world through our virtual event platform."
    },
    {
      id: EventNature.Hybrid,
      title: EventNature.Hybrid,
      description: "Enjoy the flexibility of attending either in person or online, combining the best of both worlds."
    }
  ];
  currentId: string | undefined;

  constructor(
    private eventService: EventService,
    private updateTracker: LastUpdatesTrackerService
  ) { }

  ngOnInit(): void {
    this.eventSub = this.eventService.selectedEvent.subscribe({
      next: event => {
        this.event = event;
        this.currentId = this.event.nature;
      },
    });
  }

  ngOnDestroy(): void {
    if (this.eventSub) this.eventSub.unsubscribe();
  }

  select(id: string) {
    this.currentId = id;
    let request: UpdateEventNatureRequest = new UpdateEventNatureRequest('', EventNature[this.currentId]);
    this.eventService.updateEventNature(request);
  }

  trackUpdate(menu: string) {
    this.updateTracker.newUpdate(menu);
  }

}
