<div class="px-2 md:px-7 py-7">
  <div class="mx-auto max-w-5xl">
    <nav class="flex mb-4" aria-label="Breadcrumb">
      <ol role="list" class="flex items-center space-x-4">
        <li>
          <div class="flex items-center justify-start">
            <a routerLink="/event-editor/menu" class="text-sm font-medium text-gray-500 hover:text-gray-700">Event Editor</a>
          </div>
        </li>
        <li>
          <div class="flex items-center">
            <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor"
              aria-hidden="true">
              <path fill-rule="evenodd"
                d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                clip-rule="evenodd" />
            </svg>
            <a [routerLink]="'/event-editor/details'" class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700" aria-current="page">Streamline Event Details</a>
          </div>
        </li>
        <li>
          <div class="flex items-center">
            <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor"
              aria-hidden="true">
              <path fill-rule="evenodd"
                d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                clip-rule="evenodd" />
            </svg>
            <a class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700" aria-current="page">Event Type</a>
          </div>
        </li>
      </ol>
    </nav>
  </div>
  <div class="space-y-12 mt-7">
    <div class="mx-auto max-w-4xl lg:text-center">
      <h2 class="text-base font-semibold leading-7 text-main">Event Type</h2>
      <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl line-clamp-2 text-ellipsis">{{
        event.name }}
      </p>
      <p class="mt-6 text-lg leading-8 text-gray-600">Choose from Exhibition, Conference, or Education to categorize
        your event effectively. Select the type that best suits your event's purpose and audience.</p>
    </div>
  </div>
  <div class="mx-auto max-w-5xl mt-24">
    <div class="mt-10 grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-1">
      <div class="grid max-w-4xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
        <div class="col-span-full">
          <fieldset>
            <div class="grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
              @for (item of list; track $index) {
              <label (click)="select(item.id)" aria-label="Newsletter" aria-description="event-type-label"
                [ngClass]="{'border-green-600 ring-2 ring-green-600': currentId === item.id,  'border-gray-300': currentId !== item.id }"
                class="relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none">
                <input type="radio" name="event-type" [value]="item.title" [checked]="currentId == item.id"
                  class="sr-only">
                <span class="flex flex-1">
                  <span class="flex flex-col">
                    <span class="block text-base font-medium text-gray-900">{{item.title}}</span>
                    <span class="mt-1 flex items-center text-sm text-gray-500">{{item.description}}</span>
                    <span class="mt-6 text-sm font-medium text-gray-900">Click to select</span>
                  </span>
                </span>
                @if(currentId === item.id){
                <svg class="h-5 w-5 text-green-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                    clip-rule="evenodd" />
                </svg>
                }

                <span
                  [ngClass]="{'border border-green-600': currentId === item.id,  'border-2 border-transparent': currentId !== item.id }"
                  class="pointer-events-none absolute -inset-px rounded-lg border-2" aria-hidden="true"></span>
              </label>
              }
            </div>
          </fieldset>
        </div>
      </div>
    </div>
    <div class="mt-12">
      <div class="my-7">
        <p class="leading-7 text-slate-700 text-base">
          Easily proceed to <span class="font-bold">Event Nature</span> by clicking "Manage Nature." This button will guide you through updating nature of your event.
        </p>
      </div>
      <div class="flex">
        <div (click)="trackUpdate('Event Nature')" [routerLink]="'/event-editor/event-nature'" class="flex flex-col borded md:w-1/2">
          <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
            <svg class="h-5 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                d="M5.5 17a4.5 4.5 0 01-1.44-8.765 4.5 4.5 0 018.302-3.046 3.5 3.5 0 014.504 4.272A4 4 0 0115 17H5.5zm3.75-2.75a.75.75 0 001.5 0V9.66l1.95 2.1a.75.75 0 101.1-1.02l-3.25-3.5a.75.75 0 00-1.1 0l-3.25 3.5a.75.75 0 101.1 1.02l1.95-2.1v4.59z"
                clip-rule="evenodd" />
            </svg>
            Event Nature
          </dt>
          <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
            <p class="flex-auto">Select Online, Onsite, or Hybrid to define the nature of your event. Choose the option that best fits your event's format and accessibility requirements.</p>
            <p class="mt-6 md:text-end">
              <button type="button"
                class="rounded-md inline-flex items-center gap-x-1.5 bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                Manage Nature
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="-mr-0.5 h-5 w-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                </svg>
              </button>
            </p>
          </dd>
        </div>
      </div>
    </div>
  </div>
</div>
