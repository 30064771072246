<div @opacity *ngIf="isModal" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div @opacityTranslateY *ngIf="isModal"
                class="relative transform overflow-hidden rounded-lg bg-white px-7 pb-5 pt-6 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
                <div *ngIf="currentStep == 1">
                    <portal-create-organisation></portal-create-organisation>
                </div>
                <div *ngIf="currentStep == 2">
                    <portal-create-community></portal-create-community>
                </div>
                <div *ngIf="currentStep == 3">
                    <portal-create-event></portal-create-event>
                </div>
            </div>
        </div>
    </div>
</div>