import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Event } from 'src/app/shared/model/event/event.model';
import { EventService } from 'src/app/shared/service/event.service';
import { LastUpdatesTrackerService } from 'src/app/shared/service/last-updates-tracker.service';

@Component({
  selector: 'app-update-event-details',
  templateUrl: './update-event-details.component.html',
  styleUrl: './update-event-details.component.scss'
})
export class UpdateEventDetailsComponent implements OnInit, OnDestroy {
  event: Event;
  eventSub: Subscription;

  constructor(
    private eventService: EventService,
    private updateTracker: LastUpdatesTrackerService
  ) { }

  ngOnInit(): void {
    this.eventSub = this.eventService.selectedEvent.subscribe({
      next: event => this.event = event,
    });
  }

  ngOnDestroy(): void {
    if (this.eventSub) this.eventSub.unsubscribe();
  }

  trackUpdate(menu: string) {
    this.updateTracker.newUpdate(menu);
  }
}
