import { Component, Input, OnInit } from '@angular/core';
import { Event } from 'src/app/shared/model/event/event.model';
import { EventService } from 'src/app/shared/service/event.service';


@Component({
  selector: 'portal-event',
  templateUrl: './portal-event.component.html',
  styleUrls: ['./portal-event.component.scss']
})
export class PortalEventComponent implements OnInit {
  @Input('event') event: Event;

  constructor(
    private eventService: EventService
  ) { }

  ngOnInit(): void { }

  onSelect() {
    this.eventService.setSelectedEvent(this.event);
  }

  isLogoExists(){
    return this.event?.theme?.logo?.name?.length > 0;
  }

}
