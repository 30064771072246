import { Component, OnInit } from '@angular/core';
import { CustomFieldHelperService } from 'src/app/dynamic-form/services/custom-field-helper.service';
import { InputForm } from 'src/app/dynamic-form/services/schema-builder-helper.service';

@Component({
  selector: 'form-custom-field',
  templateUrl: './custom-field.component.html',
  styleUrl: './custom-field.component.scss'
})
export class CustomFieldComponent implements OnInit {
  fields: InputForm[] = [];
  selected: InputForm;
  isModal: boolean = false;

  constructor(
    private custonFielHelper: CustomFieldHelperService,
  ) { }

  ngOnInit(): void {
    this.fields = this.custonFielHelper.inputsForms;
  }

  onSelect(input: InputForm) {
    this.selected = input;
    this.isModal = true;
  }

  close() {
    this.isModal = false;
  }

}
