import { Component, Input, OnInit } from '@angular/core';
import { Community } from 'src/app/shared/model/community.model';

@Component({
  selector: 'portal-community',
  templateUrl: './portal-community.component.html',
  styleUrls: ['./portal-community.component.scss']
})
export class PortalCommunityComponent implements OnInit {
  @Input('community') community : Community = new Community();
  
  constructor() { }

  ngOnInit(): void {
  }

}
