
<div>
    <ul role="list" class="mt-3 grid grid-cols-1 gap-y-5">
        @for (field of fields; track $index) {
            <li (click)="onSelect(field)" class="col-span-1 rounded-md">
                <div class="flex flex-1 items-center justify-between truncate bg-white">
                    <div class="flex-1 truncate pr-1.5 py-2 text-sm">
                        <a class="font-medium text-slate-700 hover:text-slate-500">{{ field.name }}</a>
                        <p class="text-slate-500 line-clamp-1">{{ field.desc }}</p>
                    </div>
                    <div class="flex-shrink-0 pr-2">
                        <button type="button" class="inline-flex h-5 w-5 items-center justify-center rounded-full bg-transparent bg-white text-slate-400 hover:text-slate-500 focus:outline-none focus:ring-2 focus:ring-main focus:ring-offset-1">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                              </svg>
                        </button>
                    </div>
                </div>
            </li>
        }
    </ul>
</div>

@if (isModal) {
    <form-input-selector 
    [isModal]="isModal" 
    [customInput]="selected"
    (onClose)="close()">
</form-input-selector>
}