import { Injectable } from '@angular/core';
import { UserService } from './session.service';
import { Organisation } from 'src/app/shared/model/organisation.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrganisationService {
  organisations: BehaviorSubject<Organisation[]> = new BehaviorSubject(<Organisation[]>[]);
  selectedOrg: BehaviorSubject<Organisation> = new BehaviorSubject(new Organisation());

  constructor(
    private userService: UserService
  ) {
    this.fetchProfileOrganisation();
  }

  fetchProfileOrganisation() {
    this.userService.session.subscribe({
      next: (profile) => {
        this.organisations.next(profile.organisations);
        if (!this.selectedOrg.value || !this.selectedOrg.value.id) {
          this.setSelected(this.organisations.value[0]);
        } else {
          this.selectedOrg.next(this.selectedOrg.value);
        }
      },
    });
  }

  reset(){
    this.selectedOrg.next(new Organisation);
  }

  setSelected(organisation: Organisation) {
    this.selectedOrg.next(organisation);
  }

  get organisation(): Organisation {
    return this.selectedOrg.value;
  }
}
