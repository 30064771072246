import { Component, OnInit } from '@angular/core';
import { PortalFlowService } from '../portal-flow.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'portal-create-community',
  templateUrl: './create-community.component.html',
  styleUrls: ['./create-community.component.scss']
})
export class CreateCommunityComponent implements OnInit {
  communityForm: FormGroup = new FormGroup({});

  constructor(
    private portalFlowService: PortalFlowService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.communityForm = this.formBuilder.group({
      community: ['', [Validators.required]],
    });
  }

  onSubmit() {
    const value: string = this.communityForm.controls['community'].value;
    this.portalFlowService.setCommunity(value);
    this.next();
  }

  next() {
    this.portalFlowService.nextStep();
  }

  previous() {
    this.portalFlowService.previousStep();
  }

  toggleModal() {
    this.portalFlowService.toggle();
  }

}
