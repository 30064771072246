import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NotificationService } from 'src/app/shared/components/notifications/notification.service';
import { NotificationType } from 'src/app/shared/model/enum/notification-type.enum';
import { Profile } from 'src/app/shared/model/user/profile.model';
import { AttendeesService } from 'src/app/shared/service/attendees.service';
import { EmailService } from 'src/app/shared/service/email.service';

@Component({
  selector: 'attendee',
  templateUrl: './attendee.component.html',
  styleUrl: './attendee.component.scss',
  animations: [
    trigger('translateX', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('500ms ease-in-out', style({ transform: 'translateX(0)' }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)' }),
        animate('500ms ease-in-out', style({ transform: 'translateX(100%)' }))
      ])
    ]),
    trigger('opacityScale', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(.95)' }),
        animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('75ms ease-in', style({ opacity: 0, transform: 'scale(.95)' }))
      ])
    ]),
    trigger('AnimationTrigger0', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(.95)' }),
        animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('75ms ease-in', style({ opacity: 0, transform: 'scale(.95)' }))
      ])
    ]),
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 }))
      ])
    ]),
    trigger('opacityTranslateY', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(1rem)' }))
      ])
    ])
  ]
})
export class AttendeeComponent implements OnInit, OnChanges {
  @Input('attendee') attendee: Profile;
  @Input('selected') selected: Profile;
  profilePicture: string;
  qrCodeUrl: string;
  metaFieldKeys: string[];

  isMenu = false;
  isSlideOver = false;
  isDropdown = false;
  loading: boolean = false;
  isSendingEmail: boolean = false;
  isRemoving: boolean = false;

  isDialog = false;

  constructor(
    private attendeesService: AttendeesService,
    private emailService: EmailService,
    private notificationService: NotificationService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.getProfilePhotoPath();
    this.getProfileQRCodeURL();
    this.metaFieldKeys = Object.keys(this.attendee.metaField);
  }

  ngOnInit(): void {

  }

  toggleMenu() {
    this.isMenu = !this.isMenu;
  }

  getProfilePhotoPath() {
    this.profilePicture = this.attendee.user?.profilePhoto?.name || null;
  }

  toggleSlideOver() {
    // this.isSlideOver = !this.isSlideOver;
    // this.isMenu = false;
  }

  toggleDropdown() {
    this.isDropdown = !this.isDropdown;
  }

  remove() {
    this.isRemoving = true;
    this.attendeesService
      .removeAttendee(this.attendee.id)
      .subscribe({
        next: _ => {
          this.isRemoving = false;
          this.notificationService.notify('Attendee', 'This attendee is no longuer registred in this event.', NotificationType.Success);
        },
        error: _ => this.isRemoving = false,
      })
  }

  sendUpdatePasswordEmail() {
    this.isSendingEmail = true;
    this.emailService
      .sendUpdatePasswordEmail(this.attendee.user.id)
      .subscribe({
        next: _ => {
          this.isSendingEmail = false;
          this.notificationService.notify('Verification Email', 'The email has been successfully sent.', NotificationType.Success);
        },
        error: _ => this.isSendingEmail = false,
      });
  }

  sendConfirmationEmail() {
    this.isSendingEmail = true;
    this.emailService
      .sendConfirmationEmail(this.attendee.id)
      .subscribe({
        next: _ => {
          this.isSendingEmail = false;
          this.notificationService.notify('Verification Email', 'The email has been successfully sent.', NotificationType.Success);
        },
        error: _ => this.isSendingEmail = false,
      });
  }

  getProfileQRCodeURL() {
    this.attendeesService.getAttendeeQrCodeURL(this.attendee.id).subscribe({
      next: (result) => {
        this.qrCodeUrl = result.url;
      },
      error: (er) => console.log(er),
    })
  }

  toggleDialog() {
    this.isSlideOver = false;
    this.isMenu = false;
    this.isDialog = !this.isDialog;
  }
}
