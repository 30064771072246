import { Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'formly-array-type',
  template: `
    <div class="mb-3 w-full">
      <legend *ngIf="props.label" class="text-slate-700 text-xl">{{ props.label }}</legend>
      <p *ngIf="props.description" class="text-slate-500 text-sm mb-5">{{ props.description }}</p>

      <div class="h-[430px] overflow-y-scroll">
          <div class="alert alert-danger" role="alert" *ngIf="showError && formControl.errors">
            <formly-validation-message [field]="field"></formly-validation-message>
          </div>
          <div *ngFor="let field of field.fieldGroup; let i = index" class="row align-items-start">
            <formly-field class="col" [field]="field"></formly-field>
            <div *ngIf="field.props.removable !== false" class="col-2 text-right">
              <button (click)="remove(i)" type="button" class="rounded-full p-1 text-slate-700">
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>
            </button>
            </div>
          </div>
          <div class="flex justify-center my-5">
                <button (click)="add()" type="button" class="border border-slate-200 w-full inline-flex justify-center items-center gap-x-1.5 rounded-md text-slate-700 px-3 py-2.5 text-sm font-medium">
                    More Options
                    <svg class="size-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" >
                      <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                    </svg>
                </button>
          </div>
      </div>

    </div>
  `,
})
export class ArrayTypeComponent extends FieldArrayType { }
