import { animate, style, transition, trigger } from '@angular/animations';
import { Component } from "@angular/core";
import { FieldWrapper } from "@ngx-formly/core";
import { DefaultFormTemplateService } from "../../services/default-template-form.service";
import { SchemaBuilderService } from "../../services/schema-builder.service";



@Component({
    selector: 'field-wrapper',
    template: `
    <div class="flex justify-start items-center gap-x-3 w-full">
        <div class="w-full">
            <ng-container #fieldComponent></ng-container>
        </div>
        <div  *ngIf="field.key && isSchemaField(field.key)" class="w-12">
            <div *ngIf="field.key" class="flex justify-end items-center gap-x-1.5 text-slate-500">
                <div class="flex flex-col gap-y-1">
                    <button (click)="orderUp(field.key)" type="button" class="border border-slate-100 stroke-slate-500 rounded-md px-1 hover:border-slate-300">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-3">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
                        </svg>
                    </button>
                    <button (click)="orderBottom(field.key)" type="button" class="border border-slate-100 stroke-slate-500 rounded-md px-1 hover:border-slate-300">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-3">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                        </svg>
                    </button>
                </div>
                <button type="button" *ngIf="canRemove()" (click)="toggleModal()">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4 stroke-slate-500 hover:stroke-slate-300">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                    </svg>
                </button>
            </div>
        </div>
    </div>

    <div @opacity *ngIf='isModal' class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div (click)="toggleModal()" class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0">
          <div @opacityTranslateY *ngIf='isModal' class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
            <div class="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
              <button type="button" (click)="toggleModal()" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                <span class="sr-only">Close</span>
                <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div class="sm:flex sm:items-start">
              <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <svg class="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Remove Field</h3>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">Are you sure you want to remove this field? This field be permanently removed from your form. This action cannot be undone.</p>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button type="button" (click)="remove(field.key)" class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto">Remove</button>
              <button type="button" (click)="toggleModal()" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    `,
     animations: [
      trigger('opacity', [
        transition(':enter', [
          style({ opacity: 0 }),
          animate('300ms ease-out', style({  opacity: 1 }))
        ]),
        transition(':leave', [
          style({ opacity: 1 }),
          animate('200ms ease-in', style({ opacity: 0 }))
        ])
      ]),
      trigger('opacityTranslateY', [
        transition(':enter', [
          style({ opacity: 0, transform: 'translateY(1rem)' }),
          animate('300ms ease-out', style({  opacity: 1, transform: 'translateY(0)' }))
        ]),
        transition(':leave', [
          style({ opacity: 1, transform: 'translateY(0)' }),
          animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(1rem)' }))
        ])
      ])
    ]
})
export class CustomFieldWrapper extends FieldWrapper {
  isModal = false;

    constructor(
        private defaultForm: DefaultFormTemplateService,
        private schemaBuilder: SchemaBuilderService,
    ) {
        super();
    }

    isSchemaField(field){
      return this.schemaBuilder.isSchemaField(field);
    }

    canRemove(){
        const key: string = <string> this.field.key || '';
        return this.defaultForm.canRemove(key);
    }

    orderUp(key: string){
        this.defaultForm.orderUp(key);
    }

    orderBottom(key: string){
        this.defaultForm.orderBottom(key);
    }

    remove(key: string){
      this.defaultForm.removeField(key);
      this.toggleModal();
    }

    toggleModal(){
      this.isModal = !this.isModal;
    }
}
