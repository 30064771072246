import { CommunityRegion } from "./community-region.model";
import { Organisation } from "./organisation.model";
import { Tag } from "./tag.model";

export class Community {
    public constructor(
        public id: string = '',
        public name: string = '',
        public description: string = '',
        public banner: string = '',
        public tags: Tag[] = [],
        public createDate: string = '',
        public updateAt: string = '',
        public communityRegion: CommunityRegion = new CommunityRegion(),
        public organisation: Organisation = new Organisation()
    ) { }
}