import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SetupFlowRequest } from 'src/app/shared/model/request/SetupFlow.request';
import { AppSettingService } from 'src/app/shared/service/app-setting.service';
import { OrganisationService } from 'src/app/shared/service/organisation.service';
import { UserService } from 'src/app/shared/service/session.service';

@Injectable({
  providedIn: 'root'
})
export class PortalFlowService {
  hasFlowStarted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  activeStep: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  numberOfSteps: number = 3;

  request: SetupFlowRequest = new SetupFlowRequest();
  isCreating: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private appSettings: AppSettingService,
    private httpClient: HttpClient,
    private userService: UserService,
    private organisationService: OrganisationService
  ) { }

  setup() {
    this.isCreating.next(true);
    const url: string = `${this.appSettings.settings.url}/setup`;
    this.httpClient.post(url, this.request).subscribe({
      next: result => {
        this.isCreating.next(false);
        this.activeStep.next(1);
        this.organisationService.reset();
        this.userService.getCurrentSession().subscribe();
      },
      error: err => {
        console.log(err);
        this.activeStep.next(1);
        this.isCreating.next(false);
      }
    });
  }

  startFlow() {
    this.hasFlowStarted.next(true);
  }

  closeFlow() {
    this.hasFlowStarted.next(false);
  }

  toggle() {
    this.hasFlowStarted.next(!this.hasFlowStarted.value);
  }

  nextStep() {
    if (this.activeStep.value < this.numberOfSteps) this.activeStep.next(this.activeStep.value + 1);
  }

  previousStep() {
    if (this.activeStep.value > 1) this.activeStep.next(this.activeStep.value - 1);
  }

  setOrganisation(organisation: string) {
    this.request.organisation = organisation;
  }

  setCommunity(community: string) {
    this.request.community = community;
  }

  setEvent(event: string) {
    this.request.event = event;
  }

}
