<div class="px-4 sm:px-6 lg:px-8">
    <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
            <h1 class="text-base font-semibold leading-6 text-gray-900">Meeting Configuration</h1>
            <p class="mt-2 text-sm text-gray-700">Meeting configuration including its details.</p>
        </div>
        <div *ngIf="mode == 'create'"  class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button (click)="openModal()" [disabled]="loading" type="button"
                class="inline-flex items-center rounded-md bg-main px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-main/70 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main">
                <svg class="-ml-0.5 mr-1.5 h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path
                        d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
                </svg>
                New Configuration
            </button>
        </div>
    </div>
    <div *ngIf="!loading && mode == 'create'" class="mt-8 rounded-md bg-blue-50 p-4">
        <div class="flex">
            <div class="flex-shrink-0">
                <svg class="h-5 w-5 text-blue-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z"
                        clip-rule="evenodd" />
                </svg>
            </div>
            <div class="ml-3 flex-1 md:flex md:justify-between">
                <p class="text-sm text-blue-700">You don't have any configuration form, start by creating one!</p>
                <p class="mt-3 text-sm md:ml-6 md:mt-0">
                    <a (click)="openModal()" class="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600">
                        Create New Configuration<span aria-hidden="true"> →</span>
                    </a>
                </p>
            </div>
        </div>
    </div>
    <div class="-mx-4 mt-8 sm:-mx-0">
        <table class="min-w-full divide-y divide-gray-300">
            <thead>
                <tr>
                    <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                        Biz Event Id
                    </th>
                    <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                        Biz Event Url
                    </th>
                    <th scope="col"
                        class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                        API Key
                    </th>
                    <th scope="col"
                        class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                        JWT Key
                    </th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Created At
                    </th>
                    <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                        <span class="sr-only">See</span>
                    </th>
                </tr>
            </thead>
            <tbody *ngIf="configuration.bizEventId > 0" class="divide-y divide-gray-200 bg-white">
                <tr>
                    <td
                        class="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0">
                        {{ configuration.bizEventId }}
                        <dl class="font-normal lg:hidden">
                            <dd class="mt-1 truncate text-gray-500 sm:hidden">
                                {{ configuration.createdAt | date:'y, MMM d HH:mm (UTC-0)' }}
                            </dd>
                            <dd class="mt-1 truncate text-gray-500">
                                {{ configuration.bizEventUrl }}
                            </dd>
                            <dt class="sr-only sm:hidden">
                                {{ configuration.apiKey }}
                            </dt>
                            <dt class="sr-only sm:hidden">
                                {{ configuration.jwtKey }}
                            </dt>
                        </dl>
                    </td>
                    <td class="hidden py-4 text-sm text-gray-500 lg:table-cell">
                        {{ configuration.bizEventUrl }}
                    </td>
                    <td class="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                        {{ configuration.apiKey }}
                    </td>
                    <td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                        {{ configuration.jwtKey }}
                    </td>
                    <td class="px-3 py-4 text-sm text-gray-500">
                        {{ configuration.createdAt | date:'MMM d, y, HH:mm (UTC-0)' }}
                    </td>
                    <td class="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                        <a (click)="edit()" class="text-main hover:text-indigo-900">
                            Edit
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div @opacity *ngIf="isModal" class="relative z-[999]" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
    <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
            <div @opacityTranslateY *ngIf="isModal"
                class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 text-left shadow-xl transition-all sm:my-3 max-w-2xl sm:p-6">
                <div>
                    <div class="mt-3 text-start sm:mt-5">
                        <form [formGroup]="form" (submit)="onSubmit()">
                            <div class="space-y-12">
                                <div class="pb-3">
                                    <h2 (click)="toggleModal()" class="text-base font-semibold leading-7 text-gray-900">
                                        New Meeting Configuration uuuuu
                                    </h2>
                                    <p class="mt-1 text-sm leading-6 text-gray-600">Avoid updating this forms if you
                                        dont know what your are doing.</p>
                                    <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-3">
                                        <div class="col-span-full">
                                            <label
                                              for="bizEventId"
                                              class="block text-sm font-medium leading-6 text-gray-900">
                                              Biz Event ID 
                                            </label>
                                            <div class="mt-2">
                                              <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-main">
                                                <input
                                                  type="number"
                                                  name="bizEventId"
                                                  formControlName="bizEventId"
                                                  id="bizEventId"
                                                  class="block flex-1 border-0 bg-transparent text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                  placeholder="Enter your business event ID">
                                              </div>
                                            </div>
                                        </div>

                                        <div class="col-span-full">
                                            <label
                                              for="bizEventUrl"
                                              class="block text-sm font-medium leading-6 text-gray-900">
                                              Biz Event URL 
                                            </label>
                                            <div class="mt-2">
                                              <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-main">
                                                <input
                                                  type="text"
                                                  name="bizEventUrl"
                                                  formControlName="bizEventUrl"
                                                  id="bizEventUrl"
                                                  class="block flex-1 border-0 bg-transparent text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                  placeholder="Enter your business event URL">
                                              </div>
                                            </div>
                                        </div>

                                        <div class="col-span-full">
                                            <label
                                              for="apiKey"
                                              class="block text-sm font-medium leading-6 text-gray-900">
                                              API Key 
                                            </label>
                                            <div class="mt-2">
                                              <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-main">
                                                <input
                                                  type="text"
                                                  name="apiKey"
                                                  formControlName="apiKey"
                                                  id="apiKey"
                                                  class="block flex-1 border-0 bg-transparent text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                  placeholder="Enter your business event URL">
                                              </div>
                                            </div>
                                        </div>

                                        <div class="col-span-full">
                                            <label
                                              for="jwtKey"
                                              class="block text-sm font-medium leading-6 text-gray-900">
                                              JWT Key 
                                            </label>
                                            <div class="mt-2">
                                              <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-main">
                                                <input
                                                  type="text"
                                                  name="jwtKey"
                                                  formControlName="jwtKey"
                                                  id="jwtKey"
                                                  class="block flex-1 border-0 bg-transparent text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                  placeholder="Enter your business event URL">
                                              </div>
                                            </div>
                                        </div>

                                        <div class="col-span-full flex gap-x-2">
                                            <button type="button" (click)="closeModal()"
                                                class="w-full bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 rounded-md px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ">
                                                Cancel
                                            </button>
                                            <button type="submit"
                                                class="w-full rounded-md bg-main px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-main/70 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main">
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>