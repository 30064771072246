<form
  [formGroup]="organisationForm"
  (submit)="onSubmit()">
  <div class="space-y-12">
    <div class>
      <h2 class="text-center text-base font-semibold leading-7 text-slate-900 font-title">Create your organisation</h2>
      <p class="text-center mt-1 text-sm leading-6 text-slate-600 font-text">To begin, please provide additional information
                about your organisation or company.</p>
      <div class="mt-7 grid grid-cols-1 gap-x-6 gap-y-7">
        <div class="col-span-full">
          <label
            for="organisation"
            class="block text-sm font-medium leading-6 text-gray-900">
            Organisation name
          </label>
          <div class="mt-2">
            <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-main">
              <input
                type="text"
                name="organisation"
                formControlName="organisation"
                id="organisation"
                autocomplete="organisation"
                class="placeholder:font-text block font-text flex-1 border-0 bg-transparent text-slate-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                placeholder="Enter your organisation name">
            </div>
          </div>
        </div>
        <div class="col-span-full">
          <div class="sm:flex sm:flex-row-reverse">
            <button
            type="submit"
            [disabled]="!organisationForm.valid"
            class="disabled:bg-opacity-25 inline-flex w-full justify-center rounded-md w-btn px-4 py-2 text-sm font-semibold shadow-sm sm:ml-3 sm:w-auto">
            Next
            </button>
            <button
            (click)="cancel()"
            type="button"
            class="mt-3 inline-flex w-full justify-center px-3 py-2 text-sm font-semibold shadow-sm w-btn-border sm:mt-0 sm:w-auto">
            Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>




