import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';
import { EventService } from '../shared/service/event.service';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrl: './analytics.component.scss'
})
export class AnalyticsComponent implements OnInit {
  eventId: string;
  token: string;
  isStage: string;
  url: string = 'https://analytics.welcome.appsaya.com';
  sanitizedUrl;
  isReady: boolean = false;

  constructor(
    private eventService: EventService,
    private oAuthService: OAuthService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.eventId = this.eventService.selectedEvent.value.id;
    this.token = this.oAuthService.getAccessToken();
    this.isStage = environment.name == 'PROD' ? 'False' : 'True';
    this.url = `${this.url}?isStage=${this.isStage}&eventId=${this.eventId}&token=${this.token}`;
    this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    this.isReady = true;
  }


}
