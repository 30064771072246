<div class="px-4 sm:px-6 lg:px-8">
  <div class="sm:flex sm:items-center">
    <div class="sm:flex-auto">
      <h1 class="text-base font-semibold leading-6 text-gray-900">Tickets</h1>
      <p class="mt-2 text-sm text-gray-700">A list of all the tickets grouped by form including their name, price
                and quantity.</p>
    </div>
  </div>
  <div class="mt-8 flow-root">
    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <table class="min-w-full">
          <thead class="bg-white">
            <tr>
              <th
                scope="col"
                class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                Name
              </th>
              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Price
              </th>
              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Quantity
              </th>
              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Most Popular
              </th>
              <th
                scope="col"
                class="relative py-3.5 pl-3 pr-4 sm:pr-3">
                <span class="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody class="bg-white">
            <ng-container *ngFor="let form of forms">
              <tr class="border-t border-gray-200">
                <th
                  colspan="5"
                  scope="colgroup"
                  class="inline-flex justify-start items-center bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-500 sm:pl-3">
                  {{ form.name }}
                </th>
              </tr>
              <ng-container *ngFor="let ticket of form.tickets">
                <tr class="border-t border-gray-200">
                  <td class="whitespace-nowrap py-4 pl-7 pr-3 text-sm font-medium text-gray-900">
                    {{
                                        ticket.name }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    USD{{
                                        ticket.regular_price }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{
                                        ticket.stock_quantity }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <span
                      *ngIf="!isMostPopular(ticket)"
                      class="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">No</span>
                    <span
                      *ngIf="isMostPopular(ticket)"
                      class="inline-flex items-center rounded-md bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-800">Yes</span>
                  </td>
                  <td class="py-4 pl-3 pr-4 text-right text-sm font-medium flex justify-start items-center gap-x-2 sm:pr-0">
                    <view-ticket [ticket]="ticket"></view-ticket>
                    <new-ticket
                      (click)="selectedForm = form"
                      (afterUpdate)="updateTicket($event, form)"
                      [form]="form"
                      [editTicket]="ticket"></new-ticket>
                  </td>
                </tr>
              </ng-container>
              <ng-container *ngIf="form.loadingTickets">
                <div class="whitespace-nowrap px-3 pl-7 py-2.5 text-sm text-gray-500">
                  <span class>
                                        <svg class="animate-spin h-5 w-5 text-slate-500"
                                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                                stroke-width="4"></circle><path class="opacity-75" fill="currentColor"
                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                      </path>
                    </svg>
                                    </span>
                </div>
              </ng-container>
              <ng-container *ngIf="form?.tickets?.length == 0 && !form.loadingTickets">
                <div class="py-2 pl-7 flex justify-start items-center">
                  <p class="text-sm text-gray-500">No tickets for this form.</p>
                </div>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
