export class UpdateRegistrationFormDetailsRequest{
    public constructor(
        public eventId: string = '',
        public formId: string = '',
        public url: string = '',
        public startAt: string = '',
        public endAt: string = '',
        public name: string = '',
        public purpose: string = ''
    ){}
}