import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SidebarService } from '../services/sidebar.service';
import { User } from 'src/app/shared/model/user.model';
import { UserService } from 'src/app/shared/service/session.service';
import { EventService } from 'src/app/shared/service/event.service';
import { Event } from 'src/app/shared/model/event/event.model';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
@Component({
  selector: 'nav-bar-menu',
  templateUrl: './nav-bar-menu.component.html',
  styleUrls: ['./nav-bar-menu.component.scss'],
  animations: [
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('300ms ease', style({ opacity: 0 }))
      ])
    ]),
    trigger('opacityScale', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(.95)' }),
        animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('75ms ease-in', style({ opacity: 0, transform: 'scale(.95)' }))
      ])
    ]),
    trigger('translateX', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease-in-out', style({ transform: 'translateX(0)' }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)' }),
        animate('300ms ease-in-out', style({ transform: 'translateX(-100%)' }))
      ])
    ]),
    trigger('opacityInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-in-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('300ms ease-in-out', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class NavBarMenuComponent implements OnInit, OnDestroy {
  isMenu: boolean = false;
  isMenuSub: Subscription | undefined;
  user: User;
  userSub: Subscription;

  event: Event;
  eventSub: Subscription;

  eventUrl: string = '';

  constructor(
    private sideBarService: SidebarService,
    private userService: UserService,
    private eventService: EventService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.sideBarService.isMenu.subscribe({
      next: status => this.isMenu = status,
    });

    this.userSub = this.userService.session.subscribe({
      next: profile => this.user = profile
    });

    this.eventSub = this.eventSub = this.eventService.selectedEvent.subscribe({
      next: event => {
        this.event = event;
      },
    })
  }

  ngOnDestroy(): void {
    if (this.isMenuSub) this.isMenuSub.unsubscribe();
    if (this.userSub) this.userSub.unsubscribe();
    if (this.eventSub) this.eventSub.unsubscribe();
  }

  toggleMenu() {
    this.sideBarService.toggleMenu();
  }

  toggleOffCanvasMenu() {
    this.sideBarService.toggleOffCanvasMenu();
  }

  logout(){
    this.authenticationService.signout();
  }

}
