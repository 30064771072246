<div class="px-2 md:px-7 py-7">
  <div class="mx-auto max-w-5xl">
    <nav class="flex mb-4" aria-label="Breadcrumb">
      <ol role="list" class="flex items-center space-x-4">
        <li>
          <div class="flex items-center justify-start">
            <a routerLink="/event-editor/menu" class="text-sm font-medium text-gray-500 hover:text-gray-700">Event
              Editor</a>
          </div>
        </li>
      </ol>
    </nav>
  </div>
  <div class="space-y-12 mt-7">
    <div class="mx-auto mt-7 max-w-7xl">
      <div class="mx-auto max-w-5xl lg:text-center">
        <h2 class="text-base font-semibold leading-7 text-indigo-600">Event Editor</h2>
        <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Customize your event details</p>
        <p class="mt-6 text-lg leading-8 text-gray-600">
          Customize every detail of your event effortlessly with the Event Editor. Enter event information, manage
          registrations, schedules, and personalize branding all in one place. Perfect for organizing seamless and
          memorable events.
        </p>
      </div>

      <div *ngIf="lastUpdates.length > 0" class="mx-auto max-w-5xl mt-24">
        Last Updates
        <div class="mt-3.5 overflow-x-scroll scroll-smooth scroll-m-2 scroll-p-2 py-4">
          <ul role="list" class="flex justify-start gap-6">
            @for (lastUpdate of lastUpdates; track $index) {
            <li (click)="trackUpdate(lastUpdate.title)" [routerLink]="lastUpdate.routerLink"
              class="rounded-lg bg-white border border-slate-200 hover:cursor-pointer hover:bg-slate-200">
              <div class="flex w-full items-center justify-between mr-3 px-4 py-3">
                <div class="flex-1 truncate">
                  <div class="flex items-center space-x-3">
                    <h3 class="truncate text-sm font-medium text-gray-900">{{ lastUpdate.title }}</h3>
                  </div>
                  <p class="mt-1 truncate text-sm text-gray-500 w-32 overflow-ellipsis">{{ lastUpdate.text }}</p>
                </div>
                <div class="text-main" [innerHTML]="sanitizeSVG(lastUpdate.svg)"></div>
              </div>
            </li>
            }
          </ul>
        </div>
      </div>

      <div class="mx-auto max-w-5xl mt-20">
        <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
          <div [routerLink]="'/event-editor/details'" class="flex flex-col borded">
            <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
              <svg class="h-5 w-5 flex-none text-main" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                fill="currentColor">
                <path
                  d="M11.25 4.533A9.707 9.707 0 0 0 6 3a9.735 9.735 0 0 0-3.25.555.75.75 0 0 0-.5.707v14.25a.75.75 0 0 0 1 .707A8.237 8.237 0 0 1 6 18.75c1.995 0 3.823.707 5.25 1.886V4.533ZM12.75 20.636A8.214 8.214 0 0 1 18 18.75c.966 0 1.89.166 2.75.47a.75.75 0 0 0 1-.708V4.262a.75.75 0 0 0-.5-.707A9.735 9.735 0 0 0 18 3a9.707 9.707 0 0 0-5.25 1.533v16.103Z" />
              </svg>
              Edit Event Details
            </dt>
            <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
              <p class="flex-auto">Enter and update event details like name, date, location, and description. Keep
                everything organized in one place.</p>
              <p class="mt-6 md:text-end">
                <button type="button"
                  class="rounded-md inline-flex items-center gap-x-1.5 bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  Update event details
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="-mr-0.5 h-5 w-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                  </svg>
                </button>
              </p>
            </dd>
          </div>
          <div [routerLink]="'/event-editor/settings'" class="flex flex-col borded">
            <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
              <svg class="h-5 w-5 flex-none text-main" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                fill="currentColor">
                <path fill-rule="evenodd"
                  d="M12 6.75a5.25 5.25 0 0 1 6.775-5.025.75.75 0 0 1 .313 1.248l-3.32 3.319c.063.475.276.934.641 1.299.365.365.824.578 1.3.64l3.318-3.319a.75.75 0 0 1 1.248.313 5.25 5.25 0 0 1-5.472 6.756c-1.018-.086-1.87.1-2.309.634L7.344 21.3A3.298 3.298 0 1 1 2.7 16.657l8.684-7.151c.533-.44.72-1.291.634-2.309A5.342 5.342 0 0 1 12 6.75ZM4.117 19.125a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75h-.008a.75.75 0 0 1-.75-.75v-.008Z"
                  clip-rule="evenodd" />
              </svg>
              Set up Event Experience
            </dt>
            <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
              <p class="flex-auto">Customize registration options, ticket types, pricing effortlessly in the Event
                Configuration and Settings section. Ensure your event meets every attendee's expectation.</p>
              <p class="mt-6 md:text-end">
                <button type="button"
                  class="rounded-md inline-flex items-center gap-x-1.5 bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  Update event settings
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="-mr-0.5 h-5 w-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                  </svg>
                </button>
              </p>
            </dd>
          </div>
        </dl>
      </div>


    </div>

    <div *ngIf="event?.theme?.banner?.name" class="mx-auto max-w-7xl sm:px-6 lg:px-8 mt-20">
      <div
        class="mt-24 relative overflow-hidden bg-gray-900 px-6 py-20 shadow-xl sm:rounded-3xl sm:px-10 sm:py-24 md:px-12 lg:px-20">
        <img class="absolute inset-0 h-full w-full object-cover" [src]="event.theme.banner.name | image | async" alt="">

        <div class="absolute inset-0  bg-gradient-to-tr from-gray-900 via-gray-900/40"></div>
        <div class="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10"></div>

        <div class="relative mx-auto max-w-2xl lg:mx-0">
          <p
            class="font-text mt-6 text-lg font-semibold text-white sm:text-xl sm:leading-8 text-truncate overflow-ellipsis line-clamp-4">
            {{ event.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
