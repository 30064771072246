import { Component, OnInit } from '@angular/core';
import { Subject, Subscription, debounceTime, distinctUntilChanged, of, switchMap } from 'rxjs';
import { AttendeesService } from 'src/app/shared/service/attendees.service';

@Component({
  selector: 'search-attendee',
  templateUrl: './search-attendee.component.html',
  styleUrl: './search-attendee.component.scss'
})
export class SearchAttendeeComponent implements OnInit {

  private searchText: Subject<string> = new Subject<string>();
  searchSub: Subscription;

  constructor(
    private attendeeService: AttendeesService,
  ) { }

  ngOnInit(): void {
    this.searchText.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(keyword => this.attendeeService.searchForAttendee(keyword)),
    ).subscribe();
  }

  search(keyword: string) {
    if (!keyword || keyword.length == 0) {
      this.attendeeService.resetSearch(true);
      return;
    };
    this.searchText.next(keyword);
  }

  getInputValue(event) {
    return (event.target as HTMLInputElement).value;
  }

}
