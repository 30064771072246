import { Component, Input, OnInit } from '@angular/core';
import { Ticket } from 'src/app/shared/model/ticket/ticket.model';
import { animate, style, transition, trigger } from '@angular/animations';


@Component({
  selector: 'view-ticket',
  templateUrl: './view-ticket.component.html',
  styleUrls: ['./view-ticket.component.scss'],
  animations: [
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 }))
      ])
    ]),
    trigger('opacityTranslateY', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(1rem)' }))
      ])
    ])
  ]
})
export class ViewTicketComponent implements OnInit {
  @Input('ticket') ticket: Ticket;
  isModal = false;
  currentTab: string = 'vertical';

  constructor() { }

  ngOnInit(): void {

  }
  
  toggleModal() {
    this.isModal = !this.isModal;
  }

  onChange(tab: string){
    this.currentTab = tab; 
  }
}
