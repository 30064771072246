import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CreateCommunityRequest } from 'src/app/shared/model/request/CreateCommunity.request';
import { Tag } from 'src/app/shared/model/tag.model';
import { CommunityService } from 'src/app/shared/service/community.service';

@Component({
  selector: 'portal-new-community',
  templateUrl: './new-community.component.html',
  styleUrls: ['./new-community.component.scss'],
  animations: [
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 }))
      ])
    ]),
    trigger('opacityTranslateY', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(1rem)' }))
      ])
    ])
  ]
})
export class NewCommunityComponent implements OnInit {
  @Input('isModal') isModal: boolean = false;
  @Output('closeModal') close: EventEmitter<boolean> = new EventEmitter(false);

  communityForm: FormGroup = new FormGroup({});
  tags: string[] = [];
  missingTag: boolean = false;

  saving: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private communityService: CommunityService,
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.communityForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      description: ['', [Validators.required]],
      tags: ['']
    });
  }

  onSubmit() {
    if (this.tags.length == 0) {
      this.missingTag = true;
      return;
    }
    this.saving = true;
    let tagLabel: Tag[] = [];

    this.tags.map(tag => {
      tagLabel.push(new Tag(tag));
    });

    const name: string = this.communityForm.controls['name'].value;
    const description: string = this.communityForm.controls['description'].value;

    const request: CreateCommunityRequest = new CreateCommunityRequest(name, description, tagLabel);

    this.communityService
      .createCommunity(request)
      .subscribe({
        next: _ => {
          this.saving = false;
          this.toggleModal();
        },
        error: _ => {
          this.saving = false;
          this.toggleModal();
        }
      });

  }

  onKeyUp(input: any) {
    const value: string = input.target.value;
    if (value.endsWith(',')) {
      this.addTag(value.replace(',', ''));
      this.communityForm.controls['tags'].reset();
    }
  }

  toggleModal() {
    this.isModal = !this.isModal;
    this.onClose();
  }

  onClose() {
    this.close.emit(true);
  }

  addTag(tag: string) {
    this.tags.push(tag);
    this.missingTag = false;
  }

  removeTag(toRemove: string) {
    this.tags = this.tags.filter(tag => tag != toRemove);
  }

}
