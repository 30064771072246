import { Component, OnDestroy, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Form } from 'src/app/shared/model/form/form.model';
import { RegistrationFormService } from 'src/app/shared/service/registration-form.service';
import { Subscription } from 'rxjs';
import { UpdateFormSchema } from 'src/app/shared/model/form/update-form-schema.request';
import { FormDefinition } from 'src/app/shared/model/form/form-definition.model';
import { SchemaBuilderService } from 'src/app/dynamic-form/services/schema-builder.service';

@Component({
  selector: 'form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.scss'],
  animations: [
    trigger('translateX', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('500ms ease-in-out', style({ transform: 'translateX(0)' }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)' }),
        animate('500ms ease-in-out', style({ transform: 'translateX(100%)' }))
      ])
    ])
  ]
})
export class FormBuilderComponent implements OnInit, OnDestroy {
  form: Form;
  formDefinition: FormGroup = new FormGroup({});
  ready: boolean = false;
  submitted: any = null;
  model;
  fields: FormlyFieldConfig[];
  subscription: Subscription;
  saving: boolean = false;
  selectedTab: string = 'custom';
  isSlideOver = false;

  constructor(
    private registrationFormService: RegistrationFormService,
    private schemaBuilder: SchemaBuilderService,
  ) { }

  ngOnInit(): void {
    this.subscription = this.registrationFormService.selected.subscribe({
      next: form => {
        this.form = form;
        this.buildForm();
      },
    });
    this.updateFieldsSub();
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }

  buildForm() {
    this.formDefinition = new FormGroup({});
    this.fields = this.schemaBuilder.build(this.form.formDefinition.schema);
    this.model = this.form.formDefinition.schema.model;
    this.ready = true;
  }

  onSubmit() {
    this.submitted = {
      date: new Date(),
      data: this.formDefinition.getRawValue(),
    };
  }

  updateFieldsSub() {
    this.schemaBuilder.formFields.subscribe({
      next: fields => {
        if (fields && fields.length > 0) {
          this.formDefinition = new FormGroup({});
          this.fields = fields;
          this.model = this.schemaBuilder.getModel();
        }
      }
    });
  }

  submit() {
    this.saving = true;
    const schema = this.schemaBuilder.getSchema();
    const formDefinition = new FormDefinition(schema);
    const request: UpdateFormSchema = new UpdateFormSchema(this.form.id, formDefinition);
    this.registrationFormService
      .updateFormSchema(request)
      .subscribe({
        next: _ => {
          this.saving = false;
        },
        error: _ => {
          this.saving = false;
        }
      });
  }


  onSelectTab(tab: string) {
    this.selectedTab = tab;
  }

  toggleSlideOver(){
    this.isSlideOver = !this.isSlideOver;
  }

}
