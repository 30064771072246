import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FloatingBannersService, Message } from './floating-banners.service';

@Component({
  selector: 'floating-notification',
  templateUrl: './floating-notification.component.html',
  styleUrl: './floating-notification.component.scss'
})
export class FloatingNotificationComponent implements OnInit, OnDestroy {
  statusSub: Subscription;
  status: boolean = false;
  msg: Message;

  constructor(
    private floatingBanner: FloatingBannersService
  ){}

  ngOnInit(): void {
     this.statusSub = this.floatingBanner.status.subscribe({
          next: status => this.statusHandler(status),
      });
  }

  ngOnDestroy(): void {
      if(this.statusSub) this.statusSub.unsubscribe();
  }

  statusHandler(status: boolean) {
    this.status = status;
    if(status){
      this.msg = this.floatingBanner.msg;
    }
  }

  doAction(){

  }

  close(){
    this.floatingBanner.hide();
  }


}
