export class NewTicketRequest{
    public constructor(
        public formId: string = '',
        public name: string = '',
        public description: string = '',
        public regular_price: string = '0',
        public stock_quantity: number = 1,
        public includes: string[] = [],
        public mostPopular: boolean = false,
        public currency: string = 'USD',
    ){}
}