import { Injectable } from '@angular/core';
import { AppSettingService } from './app-setting.service';
import { HttpClient } from '@angular/common/http';
import { EventService } from './event.service';
import { ErrorService } from './error.service';
import { catchError } from 'rxjs';
import { MeetingConfigurationV1 } from '../model/meeting/meeting.model';
import { NewMeetingConfRequest } from '../model/meeting/new-meeting-conf.request';
import { UpdateMeetingConfRequest } from '../model/meeting/update-meeting-conf.request';

@Injectable({
  providedIn: 'root'
})
export class MeetingConfigurationV1Service {

  constructor(
    private appSettings: AppSettingService,
    private httpClient: HttpClient,
    private eventService: EventService,
    private errorService: ErrorService,
  ) { }

  getConfiguration() {
    const eventId: string = this.eventService.selectedEvent.value.id;
    const url: string = `${this.appSettings.settings.url}/meeting-configuration/v1/${eventId}`;

    return this.httpClient
      .get<MeetingConfigurationV1>(url)
      .pipe(
        catchError(error => this.errorService.handleError(error, { title: 'Meeting Configuration', message: 'We couldn\'t load the meeting configuration.' }, true)),
      );
  }

  newConfiguration(request: NewMeetingConfRequest) {
    request.eventId = this.eventService.selectedEvent.value.id;
    const url: string = `${this.appSettings.settings.url}/meeting-configuration/v1`;

    return this.httpClient
      .post<MeetingConfigurationV1>(url, request)
      .pipe(
        catchError(error => this.errorService.handleError(error, { title: 'Meeting Configuration', message: 'We couldn\'t save this configuration.' }, true)),
      );
  }

  updateConfiguration(request: UpdateMeetingConfRequest) {
    const url: string = `${this.appSettings.settings.url}/meeting-configuration/v1`;

    console.log(request);
    return this.httpClient
      .patch<MeetingConfigurationV1>(url, request)
      .pipe(
        catchError(error => this.errorService.handleError(error, { title: 'Meeting Configuration', message: 'We couldn\'t update this configuration.' }, true)),
      );
  }
}
