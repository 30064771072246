<router-outlet></router-outlet>
@if(!isCookieAccepted()) {
<div
  class="z-[60] fixed inset-x-0 bottom-0 flex flex-col justify-between gap-x-8 gap-y-4 bg-white p-6 ring-1 ring-gray-900/10 md:flex-row md:items-center lg:px-8">
  <p class="max-w-4xl text-sm leading-6 text-gray-900">We use cookies to enhance your browsing experience, provide personalized content, and analyze our traffic. By continuing to use our site, you consent to our use of cookies. See our <a href="https://www.appsaya.com/cookies-policy/"
      target="_blank" class="font-semibold text-main hover:text-main/75">privacy policy</a>.</p>
  <div class="flex flex-none items-center gap-x-5">
    <button (click)="acceptCookie()" type="button"
      class="rounded-md bg-main px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-main/75 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main/75">Accept</button>
  </div>
</div>
}
