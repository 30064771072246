<div>
  <div class="space-y-12">
    <div class="grid grid-cols-1 gap-x-8 gap-y-10 pb-12 md:grid-cols-1">
      <div>
        <h2 class="text-base font-semibold leading-7 text-gray-900">Form's Configuration</h2>
        <p class="mt-1 text-sm leading-6 text-gray-600">This information will be displayed publicly so be careful what you share.</p>
      </div>

      <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
        <div class="col-span-full">
          <update-form-status [form]="form"></update-form-status>
        </div>

        <!-- <div class="col-span-full">
          <update-form-rsvp [form]="form"></update-form-rsvp>
        </div>

        <div class="col-span-full">
          <update-form-waitinglist [form]="form"></update-form-waitinglist> 
        </div> -->
      </div>
    </div>
  </div>
</div>
