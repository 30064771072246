import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../service/session.service';
import { User } from '../model/user.model';

@Injectable({
  providedIn: 'root'
})
export class ProfileResolver  {

  constructor(
    private userService: UserService
  ) { }


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User> {
    return this.userService.getCurrentSession();
  }
}
