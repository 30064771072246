<div>
    <label id="listbox-label" class="sr-only">
        Change published status
    </label>
    <div class="relative w-[fit-content]">
        <div class="inline-flex divide-x divide-indigo-700 rounded-md shadow-sm">
            <div (click)="onClick()"
                class="inline-flex items-center gap-x-1.5 rounded-l-md bg-main py-2 px-3 text-white shadow-sm hover:cursor-pointer">
                <svg *ngIf="current.id == 2" class="-ml-0.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5" />
                </svg>
                <svg *ngIf="current.id == 1" class="-ml-0.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                </svg>
                <svg *ngIf="current.id == 3" class="-ml-0.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Zm6-10.125a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0Zm1.294 6.336a6.721 6.721 0 0 1-3.17.789 6.721 6.721 0 0 1-3.168-.789 3.376 3.376 0 0 1 6.338 0Z" />
                </svg>
                <p class="text-sm font-semibold">{{ current.name }}</p>
            </div>
            <button type="button" (click)="toggleSelect()"
                class="inline-flex items-center rounded-l-none rounded-r-md hover:bg-darker-blue bg-main p-2 focus:outline-none focus:ring-2 focus:ring-main focus:ring-offset-2 focus:ring-offset-gray-50"
                aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label">
                <span class="sr-only">Change published status</span>
                <svg class="h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                        d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                        clip-rule="evenodd" />
                </svg>
            </button>
        </div>

        <ul @opacityLeave *ngIf="isSelect"
            class="absolute right-0 z-10 mt-2 w-72 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-option-0">

            <li *ngFor="let item of list" (click)="select(item)" (mouseover)="setHighlight(item)" [ngClass]="{
                'text-white': highlight?.id === item.id,
                'bg-main': highlight?.id === item.id,
                'text-gray-900': highlight?.id !== item.id
                }" class="text-gray-900 cursor-default select-none p-4 text-sm" id="listbox-option-0" role="option">
                <div class="flex flex-col">
                    <div class="flex justify-between">
                        <p [ngClass]="{
                            'font-semibold': current.id === item.id,
                            'font-normal': current.id !== item.id
                            }" class="font-normal">
                            {{ item.name }}
                        </p><span *ngIf="item.id === current.id" [ngClass]="{
          'text-white': highlight?.id === item.id,
          'text-main': highlight?.id !== item.id
          }" class="text-main">
                            <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd"
                                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                    clip-rule="evenodd" />
                            </svg>
                        </span>
                    </div>
                    <p [ngClass]="{
        'text-indigo-200': highlight?.id === item.id,
        'text-gray-500': highlight?.id !== item.id
        }" class="text-gray-500 mt-2">
                        {{ item.description }}
                    </p>
                </div>
            </li>

        </ul>
    </div>
</div>