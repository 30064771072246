import { Component, OnInit } from '@angular/core';
import { EmailTemplatesService } from 'src/app/shared/service/email-templates.service';
import { CustomEmailModel } from 'src/app/shared/service/email.service';

export const confirmationTemplate = '<p>Dear [attendeeName],</p><p> </p><p>We are excited to confirm your registration for the event, <strong>[eventName].</strong></p><p><br></p><h4><strong>Event Details:</strong></h4><ol><li data-list="bullet"><span class="ql-ui" contenteditable="false"></span><strong>Location:</strong> [eventLocation]</li><li data-list="bullet"><span class="ql-ui" contenteditable="false"></span><strong>Event Dates Time:</strong> [eventDatesWithTime]</li></ol><p><br></p><p><strong>Additional Information:</strong></p><p>Please ensure you have the necessary materials ready and arrive at the venue at least 15 minutes before the start time. </p><p>If you have any special requirements or need assistance, do not hesitate to contact us.</p><p><br></p><p>We look forward to seeing you there!</p><p><br></p><p>Best regards,</p><p>[Your Company Name]</p><p>[Contact Information]</p><p>[Website]</p>';


@Component({
  selector: 'app-confirmation-of-registration',
  templateUrl: './confirmation-of-registration.component.html',
  styleUrl: './confirmation-of-registration.component.scss'
})
export class ConfirmationOfRegistrationComponent implements OnInit {
  saving: boolean = false;
  fetching: boolean = true;
  emailSubject: string = 'Your Registration Confirmation for [eventName]';
  htmlContent: string = '';

  isOpen = false;
  isOpen2 = false;


  constructor(
    private emailTemplateService: EmailTemplatesService,
  ) { }

  ngOnInit(): void {
    this.fetchExistingTemplate();
  }

  fetchExistingTemplate() {
    this.fetching = true;
    this.emailTemplateService
      .getConfirmRegistrationTemplate()
      .subscribe({
        next: (result: any) => {
          if (result == null) {
            this.htmlContent = confirmationTemplate;
          } else {
            this.emailSubject = result.content.subject;
            this.htmlContent = result.content.htmlContent;
          }
          this.fetching = false;
        },
        error: _ => this.fetching = false,
      })
  }

  toggleOpen() {
    this.isOpen = !this.isOpen;
  }

  toggleOpen2() {
    this.isOpen2 = !this.isOpen2;
  }

  onEditorChange(htmlContent: string) {
    this.htmlContent = htmlContent;
  }

  onSubmit() {
    this.saving = true;
    const emailContentModel: CustomEmailModel = { subject: this.emailSubject, htmlContent: this.htmlContent };
    this.emailTemplateService
      .updateTemplate(emailContentModel)
      .subscribe({
        next: result => {
          this.saving = false;
        },
        error: _ => {
          this.saving = false;
        }
      })
  }

}
