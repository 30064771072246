<div class="min-h-full">
  <portal-navbar></portal-navbar>

  <div class="py-10 mt-20 mx-4 md:mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 bg-white rounded-2xl border border-slate-200">
    <header>
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div>
          <div class="sm:hidden">
            <label
              for="tabs"
              class="sr-only">
              Select a tab
            </label>
            <select
              id="tabs"
              name="tabs"
              class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-main focus:outline-none focus:ring-main sm:text-sm">
              <option selected>Communities</option>
            </select>
          </div>
          <div class="hidden sm:block">
            <div class="border-b border-gray-200">
              <nav
                class="-mb-px flex space-x-8"
                aria-label="Tabs">
                <a
                  class="border-main text-slate-700 hover:text-slate-500 font-text whitespace-nowrap border-b-2 py-4 px-1 text-lg font-medium"
                  aria-current="page">
                  Communities
                </a>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
    <main class="mt-7">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <router-outlet></router-outlet>
      </div>
    </main>
  </div>
</div>

<portal-flow></portal-flow>
