import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Form } from 'src/app/shared/model/form/form.model';
import { Ticket } from 'src/app/shared/model/ticket/ticket.model';
import { RegistrationFormService } from 'src/app/shared/service/registration-form.service';
import { TicketService } from 'src/app/shared/service/ticket.service';

@Component({
  selector: 'form-tickets',
  templateUrl: './form-tickets.component.html',
  styleUrls: ['./form-tickets.component.scss'],
  animations: [
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 }))
      ])
    ]),
    trigger('opacityTranslateY', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(1rem)' }))
      ])
    ])
  ]
})
export class FormTicketsComponent implements OnInit {
  currentForm: Form;
  tickets: Ticket[] = [];
  loading: boolean = false;
  removing: boolean = false;
  selectedTicket: Ticket;

  isModal = false;
  currentTab: string = 'vertical';

  isModalRemove: boolean = false;

  constructor(
    private registrationFormService: RegistrationFormService,
    private ticketService: TicketService
  ) { }

  ngOnInit(): void {
    this.currentForm = this.registrationFormService.editedForm;
    if (this.currentForm) this.getTickets();
  }

  getTickets() {
    this.loading = true;
    this.ticketService
      .fetchTicketsOfForm(this.currentForm.id)
      .subscribe({
        next: (result) => {
          this.tickets = result;
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        }
      })
  }


  onNewTicketCreated(status: boolean) {
    if (status) this.getTickets();
  }

  isMostPopular(ticket: Ticket) {
    return this.ticketService.isMostPopular(ticket);
  }

  toggleModal(ticket?) {
    if (ticket) {
      this.selectedTicket = ticket;
      this.isModal = !this.isModal;
    } else {
      this.selectedTicket = null;
      this.isModal = false;
    }
  }

  onChange(tab: string) {
    this.currentTab = tab;
  }

  toggleConfirm(ticket: Ticket) {
    if (this.removing) return;
    this.isModalRemove = true;
    this.selectedTicket = ticket;
  }

  closeDialog(status) {
    this.isModalRemove = false;
    if (status && this.selectedTicket) {
      this.onRemove(this.selectedTicket);
    }
  }

  onRemove(ticket: Ticket) {
    this.removing = true;
    this.ticketService
      .removeTicket(ticket.id, this.currentForm.id)
      .subscribe({
        next: () => this.removing = false,
        error: () => this.removing = false,
        complete: () => this.getTickets()
      });
  }

}
