import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs';
import { AppSettingService } from './app-setting.service';
import { ErrorService } from './error.service';
import { EventService } from './event.service';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(
    private appSettings: AppSettingService,
    private eventService: EventService,
    private httpClient: HttpClient,
    private errorService: ErrorService
  ) { }

  registerToEvent(request: any) {
    const url: string = `${this.appSettings.settings.url}/event-registration/new-attendee`;
    const currentEventId: string = this.eventService.selectedEvent.value.id;
    request.eventId = currentEventId;

    console.log(request);

    const api$ = this.httpClient.post(url, request);

    return api$.pipe(
      catchError(error => this.errorService.handleError(error, null, true)),
    );
  }
}
