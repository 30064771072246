<div
    class="group aspect-h-4 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-secondary focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
    <img [src]="'assets/image/default_community_banner.png'"
        alt class="pointer-events-none object-cover group-hover:opacity-75" />
    <button type="button" class="absolute inset-0 focus:outline-none">
        <span class="sr-only">{{ community.name }}</span>
    </button>
</div>
<p class="pointer-events-none mt-2 block truncate text-sm font-text font-medium text-slate-700">
    {{ community.name }}
</p>