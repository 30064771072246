import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { Event } from 'src/app/shared/model/event/event.model';
import { Form } from 'src/app/shared/model/form/form.model';
import { UpdateRegistrationFormDetailsRequest } from 'src/app/shared/model/form/update-form-details.request';
import { AppSettingService } from 'src/app/shared/service/app-setting.service';
import { EventService } from 'src/app/shared/service/event.service';
import { RegistrationFormService } from 'src/app/shared/service/registration-form.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { NotificationService } from 'src/app/shared/components/notifications/notification.service';
import { NotificationType } from 'src/app/shared/model/enum/notification-type.enum';


@Component({
  selector: 'form-details',
  templateUrl: './form-details.component.html',
  styleUrls: ['./form-details.component.scss']
})
export class FormDetailsComponent implements OnInit {
  form: Form;
  event: Event;
  saving: boolean = false;
  startAt: Date = new Date();
  detailsForm: FormGroup;

  constructor(
    private eventService: EventService,
    private formBuilder: FormBuilder,
    private appSettings: AppSettingService,
    private registrationFormService: RegistrationFormService,
    private clipboard: Clipboard,
    private notification: NotificationService
  ) { }

  ngOnInit(): void {
    this.form = this.registrationFormService.editedForm;
    this.event = this.eventService.selectedEvent.value;
    this.initForm();
  }

  initForm() {
    this.detailsForm = this.formBuilder.group({
      url: [this.getRegistrationLink(), [Validators.required]],
      startAt: [this.defaultDate(this.form.startDate), [Validators.required]],
      endAt: [this.defaultDate(this.form.endDate), [Validators.required]],
      name: [this.form.name, [Validators.required]],
      purpose: [this.form.purpose, [Validators.required]]
    });
  }

  onSubmit() {
    if (this.detailsForm.invalid) {
      return;
    }
    this.saving = true;
    const values = this.detailsForm.getRawValue();
    const request: UpdateRegistrationFormDetailsRequest = new UpdateRegistrationFormDetailsRequest(
      '',
      this.form.id,
      values.url,
      this.formatDate(values.startAt),
      this.formatDate(values.endAt),
      values.name,
      values.purpose
    );
  
    this.registrationFormService
      .updateExistingForm(request)
      .subscribe({
        next: (form) => {
          this.form = form;
          this.saving = false;
          this.registrationFormService.setFormToEdit(this.form);
        },
        error: (error) => {
          console.log(error);
          this.saving = false;
        }
      });
  }

  formatDate(date: Date): string {
    return format(date, this.appSettings.settings.dateAPIFormat);
  }

  defaultDate(date: string) {
    if (!this.event?.schedule?.timeZone?.zone) return '';
    return utcToZonedTime(date, this.event.schedule.timeZone.zone);
  }

  getRegistrationLink(){
   return this.eventService.generateRegistrationLink(this.event, this.form);
  }

  copyLink(){
    this.clipboard.copy(this.getRegistrationLink());
    this.notification.notify('Registration link copied', 'You can share the registration link.', NotificationType.Success);
  }

}
