<div
  @opacity
  *ngIf="isModal"
  class="relative z-[10]"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

  <div class="fixed inset-0 z-10 overflow-y-auto">
    <div class="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
      <div
        @opacityTranslateY
        *ngIf="isModal"
        class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 text-left shadow-xl transition-all sm:my-3 min-w-[560px] max-w-7xl sm:p-6">
        <div class="mx-auto w-full lg:flex lg:gap-x-4 space-y-16">
          <form
           class="w-full"
            [formGroup]="form"
            (ngSubmit)="onSubmit()"
            *ngIf="isReady">
            <formly-form
              [model]="model"
              [fields]="fields"
              [form]="form">
            </formly-form>
            <div class="flex justify-between items-end">
              <button
                type="button"
                (click)="close()"
                class="rounded-md bg-white/10 px-3.5 py-2.5 text-sm font-medium text-slate-700 hover:text-slate-500">
                Cancel
              </button>
              <button
                type="submit"
                [disabled]="!form.valid"
                class="w-32 rounded-md bg-main px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-main/75 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
