<div class="px-11 pt-11 pb-20">
  <div class="mb-10">
    <div class="min-w-0 flex-1">
      <nav class="flex mb-4" aria-label="Breadcrumb">
        <ol role="list" class="flex items-center space-x-4">
          <li>
            <div class="flex items-center justify-start">
              <a routerLink="../" class="text-sm font-medium text-gray-500 hover:text-gray-700">Notifications</a>
            </div>
          </li>
          <li>
            <div class="flex items-center">
              <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor"
                aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                  clip-rule="evenodd" />
              </svg>
              <a class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700" aria-current="page">Ensure Engagement</a>
            </div>
          </li>
        </ol>
      </nav>
      <h2 class="mt-2 text-2xl font-bold leading-9 text-slate-700 sm:text-3xl sm:tracking-tight">Ensure Engagement</h2>
      <div class="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
        <div class="mt-2 flex items-center text-sm text-gray-500">
          Send an email notification inviting participants to access the platform to ensure everyone is ready and
          engaged for the event.
        </div>
      </div>
    </div>
  </div>

  <div class="mt-11">
    <fieldset>
      <legend class="text-base font-semibold leading-6 text-slate-700">Select email to send</legend>
      <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
        <label *ngFor="let item of list; index as i" [ngClass]="{
            'border-transparent': currentId === item.id,
            'border-gray-300': currentId !== item.id,
            'ring-2': currentId === item.id,
            'ring-main': currentId === item.id,
            'border-main': currentId === item.id
          }" class="relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none">
          <input type="radio" (click)="select(item.id)" name="project-type" value="{{ item.title }}" class="sr-only"
            attr.aria-labelledby="project-type-{{i}}-label"
            attr.aria-describedby="project-type-{{i}}-description-{{i}} project-type-{{i}}-description-1">
          <span class="flex flex-1">

            <span class="flex flex-col">

              <span id="project-type-{{i}}-label" class="block text-sm font-medium text-gray-900">{{
                item.title }}</span>

              <span id="project-type-{{i}}-description-0" class="mt-1 flex items-center text-sm text-gray-500">{{
                item.lastMsg }}</span>

              <span id="project-type-{{i}}-description-1" class="mt-6 text-sm font-medium text-gray-900">{{
                item.quantity }}
                participants</span>
            </span>
          </span>

          <svg *ngIf="currentId === item.id" class="h-5 w-5 text-main" viewBox="0 0 20 20" fill="currentColor"
            aria-hidden="true">
            <path fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
              clip-rule="evenodd" />
          </svg>

          <span [ngClass]="{
              'border': currentId === item.id,
              'border-2': currentId !== item.id,
              'border-main': currentId === item.id,
              'border-transparent': currentId !== item.id
            }" class="pointer-events-none absolute -inset-px rounded-lg border-2" aria-hidden="true"></span>
        </label>
      </div>
      <div class="mt-9 w-full">
        <button type="button" [disabled]="isSending" (click)="onClick()"
          class="w-full inline-flex justify-center items-center gap-x-2 rounded-md bg-main px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-main/70 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main">
          @if(isSending){
          <svg class="animate-spin -ml-0.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
            </circle>
            <path class="opacity-75" fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
            </path>
          </svg>
          }@else {
          <svg class="-ml-0.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
            stroke-width="1.5" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5" />
          </svg>
          }
          Send Emails
        </button>
      </div>
    </fieldset>
  </div>

</div>
