import { Component } from '@angular/core';

@Component({
  selector: 'app-last-update-shortcuts',
  templateUrl: './last-update-shortcuts.component.html',
  styleUrl: './last-update-shortcuts.component.scss'
})
export class LastUpdateShortcutsComponent {

}
