import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { SidebarService } from '../services/sidebar.service';
import { Subscription } from 'rxjs';
import { EventService } from 'src/app/shared/service/event.service';
import { Event } from 'src/app/shared/model/event/event.model';

@Component({
  selector: 'side-bar-menu',
  templateUrl: './side-bar-menu.component.html',
  styleUrls: ['./side-bar-menu.component.scss'],
  animations: [
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('300ms ease', style({ opacity: 0 }))
      ])
    ]),
    trigger('opacityScale', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(.95)' }),
        animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('75ms ease-in', style({ opacity: 0, transform: 'scale(.95)' }))
      ])
    ]),
    trigger('translateX', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease-in-out', style({ transform: 'translateX(0)' }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)' }),
        animate('300ms ease-in-out', style({ transform: 'translateX(-100%)' }))
      ])
    ]),
    trigger('opacityInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-in-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('300ms ease-in-out', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class SideBarMenuComponent implements OnInit, OnDestroy {
  isOffCanvasMenu = false;
  isOffCanvasMenuSub: Subscription | undefined;
  isOffCanvasMenuDialog = false;
  isOffCanvasMenuDialogSub: Subscription | undefined;

  event: Event = new Event();
  eventSub: Subscription;

  constructor(
    private sidebarService: SidebarService,
    private eventService: EventService
  ) { }

  ngOnInit(): void {
    this.sidebarService.isOffCanvasMenu.subscribe({
      next: status => this.isOffCanvasMenu = status,
    });

    this.sidebarService.isOffCanvasMenuDialog.subscribe({
      next: status => this.isOffCanvasMenuDialog = status,
    });

    this.eventSub = this.eventService.selectedEvent.subscribe({
      next: status => this.event = status,
    })
  }

  ngOnDestroy(): void {
    if (this.isOffCanvasMenuSub) this.isOffCanvasMenuSub?.unsubscribe();
    if (this.isOffCanvasMenuDialogSub) this.isOffCanvasMenuDialogSub.unsubscribe();
    if (this.eventSub) this.eventSub.unsubscribe();
  }

  toggleOffCanvasMenu() {
    this.sidebarService.toggleOffCanvasMenu();
  }

  get logo(): string {
    if(this.event.theme.logo.name){
      return this.event.theme.logo.name;
    }
    return 'assets/logo/logo.png';
  }
}
