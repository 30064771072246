import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


export interface Message {
    title: string,
    text: string,
    bgColor: string,
    textColor: string,
    isCloasable: boolean
}


@Injectable({
  providedIn: 'root'
})
export class FloatingBannersService {
  msg: Message;
  status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  display(message: Message){
    this.msg = message;
    this.status.next(true);
    setTimeout(() => {
      this.hide();
    }, 3000);
  }

  hide(){
    this.status.next(false);
  }

  toggle(){
    this.status.next(!this.status.value);
  }
}
