import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Form } from 'src/app/shared/model/form/form.model';
import { NewTicketRequest } from 'src/app/shared/model/ticket/NewTicketRequest';
import { RegistrationFormService } from 'src/app/shared/service/registration-form.service';
import { TicketService } from 'src/app/shared/service/ticket.service';
import { Ticket } from 'src/app/shared/model/ticket/ticket.model';
import { EditTicketRequest } from 'src/app/shared/model/ticket/EditTicketRequest';

@Component({
  selector: 'new-ticket',
  templateUrl: './new-ticket.component.html',
  styleUrls: ['./new-ticket.component.scss'],
  animations: [
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 }))
      ])
    ]),
    trigger('opacityTranslateY', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(1rem)' }))
      ])
    ])
  ]
})
export class NewTicketComponent implements OnInit {
  @Input('form') form: Form;
  @Input('editTicket') editTicket: Ticket;
  @Output('afterSubmit') afterSubmit: EventEmitter<boolean> = new EventEmitter();
  @Output('afterUpdate') afterUpdate: EventEmitter<Ticket> = new EventEmitter();
  editMode: boolean = false;
  currentForm: Form;
  ticketForm: FormGroup;
  saving: boolean = false;
  isModal = false;


  constructor(
    private registrationFormService: RegistrationFormService,
    private ticketService: TicketService,
    private formBuilder: FormBuilder,
  ) { }


  ngOnInit(): void {
    if (this.editTicket) this.editMode = true;
    this.currentForm = this.registrationFormService.editedForm || this.form || null;
    this.initForm();
  }


  initForm() {
    this.ticketForm = this.formBuilder.group({
      name: [this.editMode ? this.editTicket.name : '', [Validators.required]],
      description: [this.editMode ? this.description : '', [Validators.required]],
      includes: [this.editMode ? this.whatsIncluded.join() : '', [Validators.required]],
      regular_price: [this.editMode ? this.editTicket.regular_price : '1', [Validators.required]],
      stock_quantity: [this.editMode ? this.editTicket.stock_quantity : 1, [Validators.required]],
      mostPopular: [this.editMode ? this.isMostPopular : false, [Validators.required]],
      currency: [{ value: 'USD', disabled: true }, [Validators.required]]
    });
  }

  onSubmit() {
    if (this.ticketForm.invalid) {
      return;
    }

    this.saving = true;

    if (!this.editMode) this.save();
    if (this.editMode) this.edit();
    this.isModal = false;
  }

  save() {
    const values = this.ticketForm.getRawValue();
    const request: NewTicketRequest = new NewTicketRequest(
      this.currentForm.id,
      values.name,
      values.description,
      values.regular_price,
      values.stock_quantity,
      values.includes.split('.').filter((item: string) => item.length > 0),
      values.mostPopular,
      values.currency
    );

    this.ticketService
      .newTicket(request)
      .subscribe({
        next: () => {
          this.saving = false;
          this.ticketForm.reset();
          this.afterSubmit.emit(true);
          this.isModal = false;
        },
        error: (error) => {
          console.log(error);
          this.afterSubmit.emit(false);
          this.saving = false;
        }
      });
  }

  edit() {
    const values = this.ticketForm.getRawValue();
    const updateRequest: EditTicketRequest = new EditTicketRequest(
      this.currentForm.id,
      this.editTicket.id,
      values.name,
      values.description,
      values.regular_price,
      values.stock_quantity,
      values.includes.split('.').filter((item: string) => item.length > 0),
      values.mostPopular,
      values.currency
    );

    this.ticketService
      .editTicket(updateRequest)
      .subscribe({
        next: (ticket) => {
          this.saving = false;
          this.ticketForm.reset();
          this.afterSubmit.emit(true);
          this.afterUpdate.emit(ticket);
          this.isModal = false;
        },
        error: (error) => {
          this.saving = false;
          this.afterSubmit.emit(false);
        }
      });
  }

  toggleModal() {
    this.isModal = !this.isModal;
    if (this.isModal) this.initForm();
  }


  get description() {
    return this.editTicket?.description.replace('<p>', '').replace('</p>', '') || '';
  }

  get whatsIncluded(): string[] {
    const index: number = this.editTicket?.meta_data.findIndex(data => data.key == 'includes');
    if (index == -1) return [];
    return this.editTicket.meta_data[index].value;
  }

  get isMostPopular() {
    const index: number = this.editTicket?.meta_data?.findIndex(data => data.key == 'isMostPopular') || -1;
    if (index == -1) return false;

    return this.editTicket.meta_data[index].value == '1'
  }


}
