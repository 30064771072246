<div class="">
   <dl class="mt-2 space-y-3 divide-y divide-gray-900/10">
      <div class="pt-3">
        <dt>
          <button type="button" (click)="toggleOpen()" class="flex w-full items-start justify-between text-left text-slate-700" aria-controls="faq-0" aria-expanded="false">
            <span class="text-lg font-medium leading-7">Form Inspector</span>
            <span class="ml-6 flex h-7 items-center">
              <svg *ngIf="!isExpanded" class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
              </svg>
              <svg *ngIf="isExpanded" class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
              </svg>
            </span>
          </button>
        </dt>
        <dd *ngIf="isExpanded" class="mt-2 pr-12" id="faq-0">
            <div>
                <div class="sm:hidden">
                  <label for="tabs" class="sr-only">Select a tab</label>
                  <select  id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-main/70 focus:outline-none focus:ring-main/70 sm:text-sm">
                    <option (click)="onTabChange('FormValue')"  selected>Form Value</option>
                    <option (click)="onTabChange('Model')">Model</option>
                    <option (click)="onTabChange('Submitted')">Submitted</option>
                  </select>
                </div>
                <div class="hidden sm:block">
                  <div class="border-b border-gray-200">
                    <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                      <a 
                        (click)="onTabChange('FormValue')" 
                        [ngClass]="{'border-main/70 text-main' : isCurrentTab('FormValue'), 'border-transparent text-gray-500': !isCurrentTab('FormValue')}" 
                        class="hover:border-gray-300 hover:text-gray-700 whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium">
                        Form Value</a>
                      <a 
                        (click)="onTabChange('Model')" 
                        [ngClass]="{'border-main/70 text-main' : isCurrentTab('Model'), 'border-transparent text-gray-500': !isCurrentTab('Model')}" 
                        class="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium">Model</a>
                      <a 
                        (click)="onTabChange('Submitted')" 
                        [ngClass]="{'border-main/70 text-main' : isCurrentTab('Submitted'), 'border-transparent text-gray-500': !isCurrentTab('Submitted')}" 
                        class="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium" aria-current="page">
                        Submitted</a>
                    </nav>
                  </div>
                </div>
            </div>
            
            <div *ngIf="selectedTab == 'FormValue'">
                <pre *ngIf="form">{{ form.value| json }}</pre>
                <p *ngIf="!form">
                    Your form is not set.
                </p>
            </div>
            <div *ngIf="selectedTab == 'Model'">
                <pre *ngIf="model">{{ model | json }}</pre>
                <p *ngIf="!model" class="text-xs text-slate-500 text-left">
                    Your model has not data.
                </p>
            </div>
            <div *ngIf="selectedTab == 'Submitted'">
                <div *ngIf="submittedValue">
                    <p class="mb-3">Date of submit: {{ submittedValue.date | date: 'short' }}</p>
                    <pre>{{ submittedValue.data | json }}</pre>
                </div>
                <div *ngIf="!submittedValue">
                    <p class="text-xs text-slate-500 text-left">
                        You did not submit anything yet.
                    </p>
                </div>
            </div>
        </dd>
      </div>
    </dl>
  </div>