import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { Subscription } from 'rxjs';
import { Event } from 'src/app/shared/model/event/event.model';
import { UpdateEventSchedule } from 'src/app/shared/model/request/UpdateEventSchedule.request';
import { TimezoneOffset } from 'src/app/shared/model/timezone.model';
import { AppSettingService } from 'src/app/shared/service/app-setting.service';
import { EventService } from 'src/app/shared/service/event.service';
import { LastUpdatesTrackerService } from 'src/app/shared/service/last-updates-tracker.service';
import { TimezoneService } from 'src/app/shared/service/timezone.service';



@Component({
  selector: 'event-schedule',
  templateUrl: './event-schedule.component.html',
  styleUrls: ['./event-schedule.component.scss', '../event-information.component.scss'],
})
export class EventScheduleComponent implements OnInit, OnDestroy {
  event: Event;
  eventSub: Subscription;
  saving: boolean = false;
  savingSub: Subscription;
  date: any;

  timezones: TimezoneOffset[] = [];
  selectedTimezone: TimezoneOffset = new TimezoneOffset('', '', '');
  showDropdownTimezone: boolean = false;
  activeTimezone: TimezoneOffset | null;
  startAt: Date = new Date();
  endAt: Date = new Date();

  form: FormGroup;

  constructor(
    private timezoneService: TimezoneService,
    private formBuilder: FormBuilder,
    private appSettings: AppSettingService,
    private eventService: EventService,
    private updateTracker: LastUpdatesTrackerService
  ) { }

  ngOnInit(): void {
    this.startAt.setHours(9, 0, 0);
    this.endAt.setHours(19, 0, 0);

    this.timezoneService.getTimezones().subscribe({
      next: zones => {
        this.timezones = zones;
        this.setSelectedTimezone();
      },
    });

    this.eventSub = this.eventService.selectedEvent.subscribe({
      next: event => {
        this.event = event;
        this.initForm();
      },
    });

    this.savingSub = this.eventService.savingSchedule.subscribe({
      next: status => this.saving = status,
    });
  }

  ngOnDestroy(): void {
    if (this.eventSub) this.eventSub.unsubscribe();
    if (this.savingSub) this.savingSub.unsubscribe();
  }

  initForm() {
    this.form = this.formBuilder.group({
      from: [this.defaultDate(this.event.schedule?.from), [Validators.required]],
      to: [this.defaultDate(this.event.schedule?.to), [Validators.required]],
      timezone: [this.selectedTimezone.label, Validators.required],
    });
  }

  onSubmit() {
    if (this.form.invalid) return;

    const values = this.form.getRawValue();
    const start: Date = values.from;
    const end: Date = values.to;

    const request: UpdateEventSchedule = new UpdateEventSchedule('', this.formatDate(start), this.formatDate(end), this.selectedTimezone);
    this.eventService.updateSchedule(request);
  }

  onSelectTimezone(timezone: TimezoneOffset) {
    this.selectedTimezone = timezone;
    this.form.controls['timezone'].setValue(this.selectedTimezone.label);
    this.toggleTimezone();
  }

  toggleTimezone() {
    this.showDropdownTimezone = !this.showDropdownTimezone;
  }

  filterZones(input: any) {
    const keyword: string = input.target.value;
    if (keyword.length > 0) {
      this.timezones = this.timezoneService.filterZones(keyword);
    } else {
      this.timezones = this.timezoneService.timezones;
    }
  }

  formatDate(date: Date): string {
    return format(date, this.appSettings.settings.dateAPIFormat);
  }

  defaultDate(date: string) {
    if (!this.event?.schedule?.timeZone?.zone) return '';
    return utcToZonedTime(date, this.event.schedule.timeZone.zone);
  }

  setSelectedTimezone() {
    if (this.event?.schedule?.timeZone?.label?.length > 0) {
      const selected = this.event.schedule.timeZone.zone;
      this.selectedTimezone = this.timezones.filter(zone => zone.zone == selected)[0];
      this.activeTimezone = this.selectedTimezone;
    }
  }

  trackUpdate(menu: string) {
    this.updateTracker.newUpdate(menu);
  }
}
