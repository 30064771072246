import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'custom-checkbox',
  templateUrl: './custom-checkbox.component.html',
  styleUrls: ['./custom-checkbox.component.scss']
})
export class CustomCheckboxComponent extends FieldType<FieldTypeConfig> {
  selected: string[] = [];

  onSelect(value: string) {
    const index = this.selected.findIndex(element => element == value);
    if (index == -1) {
      this.selected.push(value);
      this.formControl.setValue([...this.selected]);
    } else {
      this.removeSelected(value);
    }
  }

  removeSelected(value) {
    this.selected = this.selected.filter(element => element != value);
    this.formControl.setValue([...this.selected]);
  }
}
