<div class="">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto max-w-5xl lg:mx-0">
      <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Registration Management</h2>
      <p class="mt-6 text-lg leading-8 text-gray-600">
        Manage all aspects of event registration, including Forms, Tickets, and Badges. Easily customize and streamline
        the registration process to ensure a smooth experience for attendees.
      </p>
    </div>
    <div class="mx-auto mt-16 max-w-2xl lg:max-w-none">
      <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
        <div routerLink="../forms"
          class="flex flex-col border border-slate-200 px-4 py-6 rounded-md hover:cursor-pointer hover:bg-slate-100">
          <dt class="text-base font-semibold leading-7 text-gray-900">
            <div class="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-main">
              <svg class="h-6 w-6 text-white" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M4 7C4 5.11438 4 4.17157 4.58579 3.58579C5.17157 3 6.11438 3 8 3H16C17.8856 3 18.8284 3 19.4142 3.58579C20 4.17157 20 5.11438 20 7V15C20 17.8284 20 19.2426 19.1213 20.1213C18.2426 21 16.8284 21 14 21H10C7.17157 21 5.75736 21 4.87868 20.1213C4 19.2426 4 17.8284 4 15V7Z" stroke="#ffffff" stroke-width="2"></path> <path d="M15 18L15 21M9 18L9 21" stroke="#ffffff" stroke-width="2" stroke-linecap="round"></path> <path d="M9 8L15 8" stroke="#ffffff" stroke-width="2" stroke-linecap="round"></path> <path d="M9 12L15 12" stroke="#ffffff" stroke-width="2" stroke-linecap="round"></path> </g></svg>
            </div>
            Forms
          </dt>
          <dd class="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
            <p class="flex-auto">Customize and manage event registration forms to collect essential attendee information
              efficiently.</p>
            <p class="mt-6">
              <a routerLink="../forms" class="text-sm font-semibold leading-6 text-main">Get started here
                <span aria-hidden="true">→</span></a>
            </p>
          </dd>
        </div>
        <div routerLink="../tickets"
          class="flex flex-col border border-slate-200 px-4 py-6 rounded-md hover:cursor-pointer hover:bg-slate-100">
          <dt class="text-base font-semibold leading-7 text-gray-900">
            <div class="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-main">
              <svg class="size-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 0 1 0 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 0 1 0-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375Z" />
              </svg>
            </div>
            Tickets
          </dt>
          <dd class="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
            <p class="flex-auto">Easily create, manage, and distribute event tickets. Customize ticket types and pricing
              to fit your event needs.</p>
            <p class="mt-6">
              <a routerLink="../tickets" class="text-sm font-semibold leading-6 text-main">Get started here <span
                  aria-hidden="true">→</span></a>
            </p>
          </dd>
        </div>
        <div routerLink="../badges"
          class="flex flex-col border border-slate-200 px-4 py-6 rounded-md hover:cursor-pointer hover:bg-slate-100">
          <dt class="text-base font-semibold leading-7 text-gray-900">
            <div class="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-main">
              <svg class="size-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0 1 10.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0 .229 2.523a1.125 1.125 0 0 1-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0 0 21 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 0 0-1.913-.247M6.34 18H5.25A2.25 2.25 0 0 1 3 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 0 1 1.913-.247m10.5 0a48.536 48.536 0 0 0-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5Zm-3 0h.008v.008H15V10.5Z" />
              </svg>
            </div>
            Print Badges
          </dt>
          <dd class="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
            <p class="flex-auto">Streamline the printing of attendee badges, ensuring high-quality, accurate, and
              ready-to-use badges for seamless event check-ins.</p>
            <p class="mt-6">
              <a routerLink="../badges" class="text-sm font-semibold leading-6 text-main">Get started here <span
                  aria-hidden="true">→</span></a>
            </p>
          </dd>
        </div>
        <div routerLink="../checkpoints"
          class="flex flex-col border border-slate-200 px-4 py-6 rounded-md hover:cursor-pointer hover:bg-slate-100">
          <dt class="text-base font-semibold leading-7 text-gray-900">
            <div class="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-main">
              <svg class="size-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
              </svg>
            </div>
            CheckPoints
          </dt>
          <dd class="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
            <p class="flex-auto">Verify participants as they pass through checkpoints to ensure smooth event flow.</p>
            <p class="mt-6">
              <a routerLink="../badges" class="text-sm font-semibold leading-6 text-main">Get started here <span
                  aria-hidden="true">→</span></a>
            </p>
          </dd>
        </div>
      </dl>
    </div>
  </div>
</div>
