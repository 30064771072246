import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, tap } from 'rxjs';
import { NotificationService } from 'src/app/shared/components/notifications/notification.service';
import { defaultBrochureTypeSupported } from 'src/app/shared/model/accepted-upload-formats';
import { NotificationType } from 'src/app/shared/model/enum/notification-type.enum';
import { Event } from 'src/app/shared/model/event/event.model';
import { UploadedFile } from 'src/app/shared/model/theme.model';
import { AppSettingService } from 'src/app/shared/service/app-setting.service';
import { ErrorService } from 'src/app/shared/service/error.service';
import { EventService } from 'src/app/shared/service/event.service';
import { FileUploaderService } from 'src/app/shared/service/file-uploader.service';

@Injectable({
  providedIn: 'root'
})
export class EventBrochureService {

  constructor(
    private appSettings: AppSettingService,
    private httpClient: HttpClient,
    private errorService: ErrorService,
    private eventService: EventService,
    private fileUploader: FileUploaderService,
    private notificationService: NotificationService,
  ) { }

  uploadBrochure(selected, endpoint) {
    endpoint = endpoint + `/${this.eventService.selectedEvent.value.id}`;
    return this.fileUploader
      .onFileSelect<Event>(selected, endpoint, this.appSettings.settings.maxImageSizeMo, defaultBrochureTypeSupported)
      .pipe(
        tap(event => {
          this.eventService.setSelectedEvent(event);
          this.notificationService.notify('Update Cover', 'Event saved', NotificationType.Success);
        }),
        catchError(error => this.errorService.handleError(error, { title: 'Sponsor Logo', message: 'We could not upload this logo.' }, true)),
      );
  }

  removeBrochure(brochureName: string) {
    const url: string = `${this.appSettings.settings.url}/event/brochure/remove?brochureName=${brochureName}&eventId=${this.eventService.selectedEvent.value.id}`;
    const api$ = this.httpClient.delete(url);

    return api$.pipe(
      tap(_ => {
        let event = this.eventService.selectedEvent.value;
        event.brochures = event.brochures.filter(brochure => brochure.name != brochureName);
        this.eventService.setSelectedEvent(event);
      }),
      catchError(error => this.errorService.handleError(error, { title: 'Brochure', message: 'We could not remove this brochure.' }, true))
    );
  }

}
