import { Component, OnInit } from '@angular/core';
import { EmailService } from 'src/app/shared/service/email.service';
import { RegistrationAnalyticsService } from 'src/app/shared/service/registration-analytics.service';


interface ListItem {
  id: number;
  title: string;
  lastMsg: string;
  quantity: number;
}


@Component({
  selector: 'email-notifications',
  templateUrl: './email-notifications.component.html',
  styleUrl: './email-notifications.component.scss'
})
export class EmailNotificationsComponent implements OnInit {
  list: ListItem[] = [
    { id: 0, title: "Verification Email", lastMsg: "Send an email to any participant who did who's account is not verified.", quantity: 0 },
    { id: 1, title: "Confirmation Email", lastMsg: "Send an email to all participants to confirm there registrations.", quantity: 0 },
  ]
  currentId: number | undefined;

  totalParticipants: number = 0;
  totalInactivateAccount: number = 0;

  isSending: boolean = false;

  constructor(
    private statsService: RegistrationAnalyticsService,
    private emailService: EmailService
  ) { }

  ngOnInit(): void {
    this.getTotalParticipants();
  }

  getTotalParticipants() {
    this.statsService
      .countAttendees()
      .subscribe({
        next: result => {
          this.totalParticipants = result;
          this.list[1].quantity = this.totalParticipants;
          this.getInactivatedAccount();
        },
      })
  }

  getInactivatedAccount() {
    this.statsService
      .countActivatedAccount()
      .subscribe({
        next: result => {
          this.totalInactivateAccount = this.totalParticipants - result;
          this.list[0].quantity = this.totalInactivateAccount;
        }
      })
  }

  select(id: number) {
    this.currentId = id;
  }

  onClick() {
    if (this.currentId == 1) {
      this.sendConfirmationEmail();
    } else if (this.currentId == 0) {
      this.sendVerifyEmail();
    }
  }

  sendConfirmationEmail() {
    this.isSending = true;
    this.emailService
      .sendConfirmationEmailToAttendees()
      .subscribe({
        next: status => {
          this.isSending = false;
        },
        error: status => {
          this.isSending = false;
        }
      });
  }

  sendVerifyEmail() {
    this.isSending = true;
    this.emailService
      .sendUpdatePasswordToNonVerifiedAttendees()
      .subscribe({
        next: status => {
          this.isSending = false;
        },
        error: status => {
          this.isSending = false;
        }
      });
  }

}
