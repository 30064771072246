<div class="relative mt-1">
  <div class="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-main">
    <label
      [for]="props.label"
      class="block text-xs font-medium text-gray-900">
      {{ props.label }}
      <span
      *ngIf="props.required"
      class="text-red-600 text-xs ml-0.5">*</span>
    </label>
    <input
      id="combobox"
      type="text"
      (keyup)="search($event)"
      [value]="selected.label"
      (click)="toggle()"
      role="combobox"
      aria-controls="options"
      aria-expanded="false"
      class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent">
    <button
      type="button"
      (click)="toggle()"
      class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
      <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd"
                    d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                    clip-rule="evenodd" />
      </svg>
    </button>
  </div>

  <ul
    *ngIf="showDropdown"
    class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
    id="options"
    role="listbox">
    <li
      *ngFor="let option of options$ | async; let i = index"
      (click)="select(option)"
      class="hover:text-white hover:bg-main relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900"
      id="option-{{ i }}"
      role="option"
      tabindex="-1">
            <span [ngClass]="{'font-semibold' : selected.id == option.id }"
        class="block truncate capitalize">{{
                option.label | lowercase }}</span>
            <span
        *ngIf="selected.id == option.id"
        [ngClass]="{'text-main': active?.id !== option.id, 'text-white': active?.id == option.id }"
        class="absolute inset-y-0 right-0 flex items-center pr-4">
                <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fill-rule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clip-rule="evenodd" />
        </svg>
            </span>
        </li>
  </ul>
</div>
