import { Injectable } from '@angular/core';
import { Setting } from 'src/app/shared/model/setting.model';

@Injectable({
  providedIn: 'root'
})
export class AppSettingService {
  private appSettings: Setting = new Setting();

  constructor() {
    this.init();
  }

  private init() {
    this.appSettings = {
      url: '/api',
      maxTag: 7,
      maxImageSizeMo: 25,
      maxFileSizeMo: 25,
      dateAPIFormat: 'yyyy-MM-dd\'T\'HH:mm'
    };
  }

  get settings() {
    return this.appSettings;
  }
}
