export interface ConfigurationOfEvent {
    name: string,
    description: string,
}


export const availableEventConfiguration: ConfigurationOfEvent[] = [
    { name: 'published', description: 'Control if this event is accessible to the participants through the login page. Participants who have their join link can access the platform.' },
    { name: 'sendWelcomeEmailOnImport', description: 'You can disbale this option to avoid sending welcome emails and other transcations emails to participants.' },
    { name: 'allowExhibitorInConference', description: 'If enabled, exhibitors tab will be displayed in Conference event.' },
    { name: 'allowVisitorsInExhibition', description: 'Enable the display of visitors (buyers, attendees) in an exhibition event type. Exhibitors will be allowed to see visitors and book meetings with them.' },
    { name: 'allowDashboardForExhibitor', description: 'Enable the dashboard for exhibitors.' },
    { name: 'halalEvent', description: 'Enable the halal integration with this event.' },
    { name: 'automaticallySendFeedbackRequest', description: 'If enabled, feedback survey will automatically be sent to participants.' },
]; 
