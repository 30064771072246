import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NewSponsorRequest, UpdateSponsorRequest } from 'src/app/shared/model/event/crud-sponsor.request';
import { Event } from 'src/app/shared/model/event/event.model';
import { Sponsor } from 'src/app/shared/model/event/sponsor.mode';
import { EventService } from 'src/app/shared/service/event.service';
import { LastUpdatesTrackerService } from 'src/app/shared/service/last-updates-tracker.service';
import { SponsorService } from 'src/app/shared/service/sponsor.service';

@Component({
  selector: 'event-sponsors',
  templateUrl: './event-sponsors.component.html',
  styleUrls: ['./event-sponsors.component.scss', '../event-information.component.scss'],
  animations: [
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 }))
      ])
    ]),
    trigger('opacityTranslateY', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(1rem)' }))
      ])
    ]),
  ]
})
export class EventSponsorsComponent implements OnInit {
  event: Event;
  eventSub: Subscription;

  isModal: boolean = false;
  confirmationModal = false;

  form: FormGroup;
  saving: boolean;

  selectedSponsor: Sponsor;
  updateMode: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private sponsorService: SponsorService,
    private eventService: EventService,
    private updateTracker: LastUpdatesTrackerService
  ) { }

  ngOnInit(): void {
    this.eventSub = this.eventService.selectedEvent.subscribe({
      next: event => {
        this.event = event;
        this.initForm();
      },
    });
  }

  initForm() {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      link: [''],
      description: [''],
    });
  }

  openUpdateSponsor() {
    this.form = this.formBuilder.group({
      name: [this.selectedSponsor.name, [Validators.required]],
      link: [this.selectedSponsor.link.replace('https://', '')],
      description: [this.selectedSponsor.description],
    });
    this.updateMode = true;
    this.isModal = true;
  }

  openModal() {
    this.initForm();
    this.isModal = true;
  }

  closeModal() {
    this.isModal = false;
  }

  onSubmit() {
    if (this.form.invalid) return;
    if (!this.updateMode)
      this.newSponsor();
    else
      this.updateSponsor(this.selectedSponsor);
  }

  updateSponsor(sponsor: Sponsor) {
    const values = this.form.getRawValue();

    const request: UpdateSponsorRequest = {
      sponsorId: sponsor.id,
      name: values.name,
      link: 'https://' + values.link,
      description: values.description
    };

    this.sponsorService
      .updateSponsor(request)
      .subscribe({
        next: (_) => {
          this.saving = false;
          this.isModal = false;
          this.form.reset();
          this.updateMode = false;
        },
        error: (_) => {
          this.saving = false;
          this.isModal = false;
          this.form.reset();
          this.updateMode = false;
        }
      });
  }

  newSponsor() {
    const values = this.form.getRawValue();

    const request: NewSponsorRequest = {
      eventId: this.event.id,
      name: values.name,
      link: 'https://' + values.link,
      description: values.description
    };

    this.sponsorService
      .newSponsor(request)
      .subscribe({
        next: (_) => {
          this.saving = false;
          this.isModal = false;
          this.form.reset();
        },
        error: (_) => {
          this.saving = false;
          this.isModal = false;
          this.form.reset();
        }
      });
  }

  onSelectLogo(selected, sponsor) {
    this.saving = true;
    const endpoint: string = `sponsor/upload-logo/${sponsor.id}`;
    this.sponsorService
      .uploadLogo(selected, endpoint)
      .subscribe({
        next: (_) => this.saving = false,
        error: (_) => this.saving = false,
      });
  }

  deleteSponsor() {
    if (!this.selectedSponsor) return;
    this.saving = true;
    this.sponsorService
      .removeSponsor(this.selectedSponsor.id)
      .subscribe({
        next: (_) => { this.saving = false; this.confirmationModal = false; },
        error: (_) => { this.saving = false; this.confirmationModal = false; },
      });
  }

  confirmDelete() {
    this.confirmationModal = true;
  }

  cancelDelete() {
    this.confirmationModal = false;
  }

  trackUpdate(menu: string) {
    this.updateTracker.newUpdate(menu);
  }
}
