export class MeetingConfigurationV1 {
    public constructor(
        public id: string = '',
        public bizEventId: number = 0,
        public bizEventUrl: string = '',
        public apiKey: string = '',
        public jwtKey: string = '',
        public eventId: string = '',
        public createdAt: string = '',
        public updatedAt: string = '',
    ) { }
}
