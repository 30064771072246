import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

interface ListItem {
  id: string;
  name: string;
  description: string;
}
@Component({
  selector: 'shared-select-menu',
  templateUrl: './select-menu.component.html',
  styleUrls: ['./select-menu.component.scss'],
  animations: [
    trigger('opacityLeave', [
      transition(':leave', [
        style({ opacity: 1 }),
        animate('100ms ease-in', style({ opacity: 0 }))
      ])
    ]),
  ]
})
export class SelectMenuComponent implements OnInit {
  @Input('list') list: ListItem[] = []
  @Output('select') onSelect: EventEmitter<ListItem> = new EventEmitter();

  isSelect = false;
  current: ListItem = this.list[0];
  highlight: ListItem | undefined;

  constructor() { }

  ngOnInit(): void {
    this.current = this.list[0];
  }

  toggleSelect() {
    this.isSelect = !this.isSelect;
  }

  select(item: ListItem) {
    this.current = item;
    this.onSelect.emit(item)
    this.toggleSelect();
  }

  setHighlight(item: ListItem) {
    this.highlight = item;
  }

}
