<div class="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-main">
  <label
    [for]="props.label"
    class="block text-xs font-medium text-gray-900">
    {{ props.label }}
    <span
      *ngIf="props.required"
      class="text-red-600 text-xs ml-0.5">*</span>
  </label>
  <input
    [type]="props.type"
    [name]="props.label"
    [id]="id"
    [formControl]="formControl"
    [formlyAttributes]="field"
    [autocomplete]="props.label"
    class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent">
</div>
<div
  *ngIf="formControl.invalid && (formControl.dirty || formControl.touched)"
  class="py-1.5 px-3 text-xs text-red-500 font-semibold">
  <div *ngIf="formControl.errors?.['required']">{{ props.label }} is required.</div>
  <div *ngIf="formControl.errors?.['minlength']">Please set a valid value.</div>
  <div *ngIf="formControl.errors?.['maxlength']">Please set a valid value.</div>
</div>
