export class FormConfiguration{
    public constructor(
        public formStatus: FormStatus = FormStatus.Disabled,
        public allowRSVP: boolean = false,
        public allowWaitingList: boolean  = false,
        public useDefaultFormTemplate: boolean = false
    ){}
}

export enum FormStatus{
    Disabled = 'Disabled',
    Enabled = 'Enabled'
}
