import { Component, OnDestroy, OnInit } from '@angular/core';
import { PortalFlowService } from '../portal-flow.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'portal-create-event',
  templateUrl: './create-event.component.html',
  styleUrls: ['./create-event.component.scss']
})
export class CreateEventComponent implements OnInit, OnDestroy {
  eventForm: FormGroup = new FormGroup({});
  saving: boolean = false;
  savingSub: Subscription | undefined;

  constructor(
    private portalFlowService: PortalFlowService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.portalFlowService.isCreating.subscribe({
      next: status => this.saving = status,
    });
  }

  ngOnDestroy(): void {
    if (this.savingSub) this.savingSub.unsubscribe();
  }

  initForm() {
    this.eventForm = this.formBuilder.group({
      event: ['', [Validators.required]],
    });
  }

  onSubmit() {
    const value: string = this.eventForm.controls['event'].value;
    this.portalFlowService.setEvent(value);
    this.portalFlowService.setup();
    this.toggleModal();
  }

  toggleModal() {
    this.portalFlowService.toggle();
  }

  previous() {
    this.portalFlowService.previousStep();
  }

}
