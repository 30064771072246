import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NewTicketRequest } from 'src/app/shared/model/ticket/NewTicketRequest';
import { AppSettingService } from 'src/app/shared/service/app-setting.service';
import { Ticket } from '../model/ticket/ticket.model';
import { NotificationService } from '../components/notifications/notification.service';
import { catchError, tap } from 'rxjs';
import { NotificationType } from '../model/enum/notification-type.enum';
import { ErrorService } from './error.service';
import { EditTicketRequest } from '../model/ticket/EditTicketRequest';

@Injectable({
  providedIn: 'root'
})
export class TicketService {

  constructor(
    private appSettings: AppSettingService,
    private httpClient: HttpClient,
    private errorService: ErrorService,
    private notificationService: NotificationService,
  ) { }


  fetchTicketsOfForm(formId: string) {
    const url: string = `${this.appSettings.settings.url}/ticket/of/${formId}`;
    const api$ = this.httpClient.get<Ticket[]>(url);

    return api$
      .pipe(
        catchError( error => {
          const notify = {title: 'Ticket', message: 'An error happend while trying to load the tickets.'}
          return this.errorService.handleError(error, notify, true );
        })
      );
  }

  newTicket(request: NewTicketRequest) {
    const url: string = `${this.appSettings.settings.url}/ticket`;
    const api$ = this.httpClient.post(url, request);

    return api$.pipe(
      tap( _ => this.notificationService.notify('Success', 'The ticket has been added to your form.', NotificationType.Success))
    );
  }

  editTicket(request: EditTicketRequest){
    const url: string = `${this.appSettings.settings.url}/ticket/update`;
    const api$ = this.httpClient.patch<Ticket>(url, request);

    return api$.pipe(
      tap( _ => this.notificationService.notify('Success', 'The ticket has been updated.', NotificationType.Success)),
      catchError( error => {
        const notify = {title: 'Ticket', message: 'The ticket has not been updated.'}
        return this.errorService.handleError(error, notify, true );
      })
    );
  }

  removeTicket(ticketId: number, formId: string) {
    const url: string = `${this.appSettings.settings.url}/ticket/delete/${ticketId}/from/${formId}`;
    const api$ = this.httpClient.delete(url);

    return api$.pipe(
      tap( _ => this.notificationService.notify('Removed', 'The ticket has been removed from your form.', NotificationType.Success)),
      catchError( error => {
        const notify = {title: 'Ticket', message: 'The ticket has not been removed from your form.'}
        return this.errorService.handleError(error, notify, true );
      })
    );
  }

  isMostPopular(ticket: Ticket) {
    const index: number = ticket.meta_data.findIndex(data => data.key == 'isMostPopular');
    if (index == -1) return false;

    return ticket.meta_data[index].value == '1'
  }

}
