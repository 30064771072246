import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Event } from 'src/app/shared/model/event/event.model';
import { EventBrochureService } from 'src/app/shared/service/event-brochure.service';
import { EventService } from 'src/app/shared/service/event.service';

@Component({
  selector: 'event-brochures',
  templateUrl: './event-brochures.component.html',
  styleUrls: ['./event-brochures.component.scss', '../event-information.component.scss'],
  animations: [
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 }))
      ])
    ]),
    trigger('opacityTranslateY', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(1rem)' }))
      ])
    ]),
  ]
})
export class EventBrochuresComponent implements OnInit {
  event: Event;
  eventSub: Subscription;

  confirmationModal = false;
  saving: boolean;

  constructor(
    private brochureService: EventBrochureService,
    private eventService: EventService
  ) { }

  ngOnInit(): void {
    this.eventSub = this.eventService.selectedEvent.subscribe({
      next: event => {
        this.event = event;
      },
    });
  }

  onSelectFile(selected) {
    this.saving = true;
    const endpoint: string = `event/brochure/new`;
    this.brochureService
      .uploadBrochure(selected, endpoint)
      .subscribe({
        next: (_) => this.saving = false,
        error: (_) => this.saving = false,
      });
  }

  deleteBrochre(name: string) {
    if (!name) return;
    this.saving = true;
    this.brochureService
      .removeBrochure(name)
      .subscribe({
        next: (_) => { this.saving = false; this.confirmationModal = false; },
        error: (_) => { this.saving = false; this.confirmationModal = false; },
      });

  }

  confirmDelete() {
    this.confirmationModal = false;
  }

  cancel() {
    this.confirmationModal = false;
  }

}
