import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-custom-input',
  templateUrl: './formly-custom-input.component.html',
  styleUrls: ['./formly-custom-input.component.scss']
})
export class FormlyCustomInputComponent extends FieldType<FieldTypeConfig> {

  constructor() {
      super();
  }


}
