import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettingService } from './app-setting.service';
import { EventService } from './event.service';

@Injectable({
  providedIn: 'root'
})
export class RegistrationAnalyticsService {

  constructor(
    private appSetting: AppSettingService,
    private httpClient: HttpClient,
    private eventService: EventService
  ) { }

  countAttendees() {
    const eventId: string = this.eventService.selectedEvent.value.id;
    const url: string = `${this.appSetting.settings.url}/registration-analytics/count-attendees?eventId=${eventId}`;
    const api = this.httpClient.get<number>(url);

    return api.pipe();
  }

  countActivatedAccount() {
    const eventId: string = this.eventService.selectedEvent.value.id;
    const url: string = `${this.appSetting.settings.url}/registration-analytics/count-activated-account?eventId=${eventId}`;
    const api = this.httpClient.get<number>(url);

    return api.pipe();
  }

  countByCreateDate() {
    const eventId: string = this.eventService.selectedEvent.value.id;
    const url: string = `${this.appSetting.settings.url}/registration-analytics/count-by-creation-date?eventId=${eventId}`;
    const api = this.httpClient.get<any[]>(url);

    return api.pipe();
  }


}
