<fieldset class="flex gap-x-5">
  <legend class="text-sm font-semibold leading-3 text-slate-700">{{ props.label }}</legend>
  <div class="flex flex-wrap justify-start items-center gap-x-3 gap-y-4">
    <div *ngFor="let option of to.options; let i = index" class="flex items-center gap-x-3">
      <input id="check-{{ option.label }}" [name]="option.label" [value]="option.label"
        (change)="onSelect(option.label)" type="checkbox" class="h-4 w-4 border-gray-300 text-main focus:ring-main">
      <label for="check-{{ props.label }}" class="block text-sm font-medium leading-6 text-gray-900">
        {{ option.label }}
      </label>
    </div>
  </div>
</fieldset>
