import { Component } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { SchemaBuilderService } from './dynamic-form/services/schema-builder.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'organiser';
  cookieKey: string = 'Cookie_Accepted';

  constructor(
    private schemaBuilder: SchemaBuilderService,
    private cookieService: CookieService
  ) {
  }

  acceptCookie() {
    this.cookieService.set(this.cookieKey, '1');
  }

  isCookieAccepted() {
    const value = this.cookieService.get(this.cookieKey);
    return value == '1';
  }

}
