import { ElementRef, Injectable } from '@angular/core';
import html2canvas from 'html2canvas';
import printJS from 'print-js';

@Injectable({
  providedIn: 'root'
})
export class PrinterService {

  constructor() { }

  downloadImage(elementRef: ElementRef, width: number, height: number, fileName: string) {
    fileName = fileName.split(' ').join('');
    fileName = fileName + '.png';

    html2canvas(elementRef.nativeElement, {
      width: width,
      height: height,
      useCORS: true,
      allowTaint: true
    }).then(function (canvas) {
      canvas.toBlob(function (blob) {
        let link = document.createElement('a');
        link.setAttribute('download', fileName);
        link.setAttribute('href', canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
        link.click();

        printJS({
          printable: link.href,
          type: "image",
          showModal: true,
          modalMessage: "Printing Qr Code...",
          style: "img { display: block; margin-left: auto; margin-right: auto; margin-top: auto; margin-bottom: auto;}"
        });
      }, 'image/png');
    }
    );
  }
}
