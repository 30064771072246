import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable, map } from 'rxjs';
import { AppSettingService } from '../service/app-setting.service';

@Pipe({
  name: 'image'
})
export class ImagePipe implements PipeTransform {

  constructor(
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer,
    private appSettings: AppSettingService,
  ) { }


  transform(url: string): Observable<SafeUrl> {
    if (!url.startsWith(`${this.appSettings.settings.url}/uploads/`)) {
      url = `${this.appSettings.settings.url}/uploads/${url}`;
    }
    
    return this.httpClient
      .get(url, { responseType: 'blob' })
      .pipe(
        map(val => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val))),
      )
  }

}
