<div class="mx-auto w-full flex flex-1">
  <aside class="w-96 py-7 flex-shrink-0 border-r border-gray-200 xl:order-first xl:flex xl:flex-col">
    <div class="px-5 pb-4">
      <h2 class="text-lg font-medium text-gray-900">Directory</h2>
      <p *ngIf="totalAttendees > 0" class="mt-1 text-sm text-gray-600">Search directory of {{ totalAttendees }}
        attendees</p>
      <div class="mt-6">
        <search-attendee></search-attendee>
      </div>
      <div class="grid grid-cols-3 gap-x-3">
        <button (click)="extract()" type="button"
          class="mt-2 inline-flex items-center justify-center w-full gap-x-1.5 rounded-md bg-main3 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-main3/75 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main3/70">
          Extract
          <svg class="-mr-2.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
            stroke-width="1.5" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
          </svg>
        </button>
        <new-attendee class="w-full"></new-attendee>
        <upload-attendees class="w-full mt-2"></upload-attendees>
      </div>
    </div>
    <!-- Directory list -->
    <nav class="min-h-0 flex-1 overflow-y-auto" aria-label="Directory">
      <div class="relative">
        <div class="sticky top-0 border-b border-t border-gray-200 bg-gray-50 py-2.5 text-sm font-medium text-gray-500">
          <h3></h3>
        </div>
        <ul role="list" class="mt-1.5 px-3 divide-y divide-gray-200 h-[500px] overflow-y-auto ">
          @for (attendee of attendees; track $index) {
          <li (click)="onSelectAttendee(attendee)">
            <attendee [attendee]="attendee" [selected]="selected"></attendee>
          </li>
          }
          <li class="flex justify-center items-start w-full">
            <div class="whitespace-nowrap px-3 pl-7 py-2.5 text-sm text-main">
              <span *ngIf="searching">
                <svg class="animate-spin h-5 w-5 text-main" xmlns="http://www.w3.org/2000/svg" fill="none"
                  viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                  </circle>
                  <path class="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                  </path>
                </svg>
              </span>
              <button *ngIf="!searching" (click)="loadMore()" type="button"
                class="w-64 rounded-md bg-main/10 px-3.5 py-2.5 text-sm font-semibold text-main shadow-sm hover:bg-main/30">
                Load More
              </button>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </aside>
  <div class="bg-white w-full">
    @if (selected) {
    <attendee-details [attendee]="selected"></attendee-details>
    }
  </div>
</div>
