import { Country } from "./country.model";

export class Location {
    public constructor(
        public country: Country = new Country(),
        public state: string = '',
        public city: string = '',
        public postcode: string = '',
        public address: string = ''
    ) { }
}