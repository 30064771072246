import { Language } from "../language.model";
import { Tag } from "../tag.model";

export class UpdateBasicDetailsEvent {
    public constructor(
        public eventId: string = '',
        public name: string = '',
        public description: string = '',
        public language: Language = new Language(),
        public tags: Tag[] = []
    ) { }
}