import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormConfiguration } from 'src/app/shared/model/form/form-configuration.model';
import { Form } from 'src/app/shared/model/form/form.model';
import { RegistrationFormService } from 'src/app/shared/service/registration-form.service';

@Component({
  selector: 'form-configuration',
  templateUrl: './form-configuration.component.html',
  styleUrls: ['./form-configuration.component.scss']
})
export class FormConfigurationComponent implements OnInit, OnDestroy {
  form: Form;
  formSub: Subscription;
  configuration: FormConfiguration;

  constructor(
    private registrationFormService: RegistrationFormService
  ) { }

  ngOnInit(): void {
    this.formSub = this.registrationFormService.selected.subscribe({
      next: form => {
        this.form = form;
        this.configuration = this.form.formConfiguration;
      },
    });
  }

  ngOnDestroy(): void {
    if (this.formSub) this.formSub.unsubscribe();
  }

}
