<button (click)="toggleModal()" type="button"
  class="mt-2 inline-flex items-center justify-center w-full gap-x-1.5 rounded-md bg-main px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-main/75 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main/70">
  New
  <svg class="-mr-2.5 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
    class="size-6">
    <path
      d="M5.25 6.375a4.125 4.125 0 1 1 8.25 0 4.125 4.125 0 0 1-8.25 0ZM2.25 19.125a7.125 7.125 0 0 1 14.25 0v.003l-.001.119a.75.75 0 0 1-.363.63 13.067 13.067 0 0 1-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 0 1-.364-.63l-.001-.122ZM18.75 7.5a.75.75 0 0 0-1.5 0v2.25H15a.75.75 0 0 0 0 1.5h2.25v2.25a.75.75 0 0 0 1.5 0v-2.25H21a.75.75 0 0 0 0-1.5h-2.25V7.5Z" />
  </svg>
</button>

<div *ngIf="isModal" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div @opacity *ngIf='isModal' class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true">
  </div>

  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div *ngIf='isModal' (click)="toggleModal()" class="fixed inset-0"></div>
    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div @opacityTranslateY *ngIf='isModal'
        class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-3/4 sm:max-w-4xl sm:p-6">
        <div *ngIf="!uploading" class="absolute top-2 right-3 hover:cursor-pointer">
          <div (click)="toggleModal()"
            class="size-7 flex justify-center items-center rounded-full bg-slate-100 hover:bg-slate-200">
            <svg class="size-6 text-slate-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              stroke-width="1.5" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
            </svg>
          </div>
        </div>
        <div class="mx-auto max-w-2xl px-6 lg:px-8">
          <form [formGroup]="formGroup" (submit)="onSubmit()">
            <div class="">
              <div class="">
                <h2 class="text-3xl font-bold tracking-tight text-slate-700 sm:text-4xl">New Attendee</h2>
                <div class="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div class="sm:col-span-full">
                    <label for="salutation"
                      class="block text-sm font-medium leading-6 text-slate-600">Salutation</label>
                    <div class="mt-2">
                      <input type="text" formControlName="salutation" name="salutation" id="salutation"
                        autocomplete="salutation"
                        class="block w-full rounded-md border-0 py-1.5 text-slate-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-main sm:text-sm sm:leading-6">
                    </div>
                  </div>

                  <div class="sm:col-span-full">
                    <label for="first-name" class="block text-sm font-medium leading-6 text-slate-600">First name
                      *</label>
                    <div class="mt-2">
                      <input type="text" formControlName="firstName" name="first-name" id="first-name"
                        autocomplete="given-name"
                        class="block w-full rounded-md border-0 py-1.5 text-slate-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-main sm:text-sm sm:leading-6">
                    </div>
                  </div>

                  <div class="sm:col-span-full">
                    <label for="last-name" class="block text-sm font-medium leading-6 text-slate-600">Last name
                      *</label>
                    <div class="mt-2">
                      <input type="text" formControlName="lastName" name="last-name" id="last-name"
                        autocomplete="family-name"
                        class="block w-full rounded-md border-0 py-1.5 text-slate-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-main sm:text-sm sm:leading-6">
                    </div>
                  </div>

                  <div class="sm:col-span-full">
                    <label for="email" class="block text-sm font-medium leading-6 text-slate-600">Email address
                      *</label>
                    <div class="mt-2">
                      <input id="email" formControlName="email" name="email" type="email" autocomplete="email"
                        class="block w-full rounded-md border-0 py-1.5 text-slate-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-main sm:text-sm sm:leading-6">
                    </div>
                  </div>

                  <div class="col-span-full">
                    <label for="company" class="block text-sm font-medium leading-6 text-slate-600">Company</label>
                    <div class="mt-2">
                      <input type="text" formControlName="company" name="company" id="company" autocomplete="company"
                        class="block w-full rounded-md border-0 py-1.5 text-slate-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-main sm:text-sm sm:leading-6">
                    </div>
                  </div>

                  <div class="sm:col-span-full">
                    <label for="occupation" class="block text-sm font-medium leading-6 text-slate-600">Occupation /
                       Job Title / Designation</label>
                    <div class="mt-2">
                      <input type="text" formControlName="occupation" name="occupation" id="occupation"
                        autocomplete="occupation"
                        class="block w-full rounded-md border-0 py-1.5 text-slate-600 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-main sm:text-sm sm:leading-6">
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-6 flex items-center justify-end gap-x-6">
              @if(!saving){
              <button (click)="toggleModal()" type="button"
                class="text-sm w-44 font-semibold leading-6 text-slate-600">Cancel</button>
              }

              <button type="submit" [disabled]="saving || !formGroup.valid"
                class="inline-flex justify-center items-center px-4 py-2 rounded-md bg-main w-44 text-sm font-semibold text-white shadow-sm hover:bg-main/75 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main">
                @if(saving){
                <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                  viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                  </path>
                </svg>
                Processing...
                } @else {
                Save
                }
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>


<div *ngIf="isOptionsModal" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div @opacity *ngIf='isOptionsModal' class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
    aria-hidden="true"></div>

  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div *ngIf='isOptionsModal' (click)="toggleModal()" class="fixed inset-0"></div>
    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

      <div @opacityTranslateY *ngIf='isOptionsModal'
        class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-3/4 sm:max-w-4xl sm:p-6">
        <div *ngIf="!uploading" class="absolute top-2 right-3 hover:cursor-pointer">
          <div (click)="toggleModalOption()"
            class="size-7 flex justify-center items-center rounded-full bg-slate-100 hover:bg-slate-200">
            <svg class="size-6 text-slate-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              stroke-width="1.5" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
            </svg>
          </div>
        </div>
        <div>
          <div class="bg-white">
            <div class="mx-auto max-w-7xl px-6 lg:px-8">
              <div class="mx-auto max-w-2xl lg:mx-0">
                <h2 class="text-3xl font-bold tracking-tight text-slate-700 sm:text-4xl">Attendee's Profile Created
                  Successfully</h2>
                <p class="mt-6 text-lg leading-8 text-gray-600">
                  The attendee has been created. What would you like to do next? Choose from the options below to either
                  send a welcome email or print a badge for this attendee.
                </p>
              </div>
              <div class="mx-auto mt-10 max-w-2xl lg:max-w-none">
                <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
                  <div (click)="sendConfrimRegistration()"
                    class="flex flex-col items-center p-5 border border-slate-100 rounded-md hover:bg-slate-100 hover:cursor-pointer">
                    <dt class="flex flex-col items-center text-base font-semibold text-center leading-7 text-gray-900">
                      <div class="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-main">
                        <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                          fill="currentColor">
                          <path
                            d="M3.478 2.404a.75.75 0 0 0-.926.941l2.432 7.905H13.5a.75.75 0 0 1 0 1.5H4.984l-2.432 7.905a.75.75 0 0 0 .926.94 60.519 60.519 0 0 0 18.445-8.986.75.75 0 0 0 0-1.218A60.517 60.517 0 0 0 3.478 2.404Z" />
                        </svg>
                      </div>
                      Send Welcome Email
                    </dt>
                    <dd class="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
                      <p class="flex-auto">Send a personalized welcome email to the new profile, introducing them to our
                        platform and providing essential information.</p>
                      <p class="mt-6 flex justify-center">
                        @if(sendingEmail){
                        <svg class="animate-spin h-5 w-5 text-main" xmlns="http://www.w3.org/2000/svg" fill="none"
                          viewBox="0 0 24 24">
                          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                          </circle>
                          <path class="opacity-75" fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                          </path>
                        </svg>
                        }
                      </p>
                    </dd>
                  </div>
                  <div
                    [routerLink]="'../registration/badges/' + newProfile.id"
                    class="flex flex-col items-center p-5 border border-slate-100 rounded-md hover:bg-slate-100 hover:cursor-pointer">
                    <dt class="flex flex-col items-center text-base font-semibold text-center leading-7 text-gray-900">
                      <div class="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-main">
                        <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                          fill="currentColor">
                          <path fill-rule="evenodd"
                            d="M7.875 1.5C6.839 1.5 6 2.34 6 3.375v2.99c-.426.053-.851.11-1.274.174-1.454.218-2.476 1.483-2.476 2.917v6.294a3 3 0 0 0 3 3h.27l-.155 1.705A1.875 1.875 0 0 0 7.232 22.5h9.536a1.875 1.875 0 0 0 1.867-2.045l-.155-1.705h.27a3 3 0 0 0 3-3V9.456c0-1.434-1.022-2.7-2.476-2.917A48.716 48.716 0 0 0 18 6.366V3.375c0-1.036-.84-1.875-1.875-1.875h-8.25ZM16.5 6.205v-2.83A.375.375 0 0 0 16.125 3h-8.25a.375.375 0 0 0-.375.375v2.83a49.353 49.353 0 0 1 9 0Zm-.217 8.265c.178.018.317.16.333.337l.526 5.784a.375.375 0 0 1-.374.409H7.232a.375.375 0 0 1-.374-.409l.526-5.784a.373.373 0 0 1 .333-.337 41.741 41.741 0 0 1 8.566 0Zm.967-3.97a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H18a.75.75 0 0 1-.75-.75V10.5ZM15 9.75a.75.75 0 0 0-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 0 0 .75-.75V10.5a.75.75 0 0 0-.75-.75H15Z"
                            clip-rule="evenodd" />
                        </svg>
                      </div>
                      Print Badge
                    </dt>
                    <dd class="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
                      <p class="flex-auto">Instantly print a badge for the new profile, perfect for event access or
                        identification purposes.</p>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
