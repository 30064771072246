import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { debounceTime, distinctUntilChanged, Observable, Subject, switchMap } from 'rxjs';
import { AttendeesService } from 'src/app/shared/service/attendees.service';
import { BadgeService } from 'src/app/shared/service/badge.service';
import { PrinterService } from 'src/app/shared/service/printer.service';

@Component({
  selector: 'badges',
  templateUrl: './badges.component.html',
  styleUrl: './badges.component.scss',
  animations: [
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 }))
      ])
    ]),
    trigger('opacityTranslateY', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(1rem)' }))
      ])
    ]),
  ]
})
export class BadgesComponent implements OnInit, AfterViewInit {
  @ViewChild('badge') badge: ElementRef<HTMLElement>;
  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;
  searchText$ = new Subject<string>();
  isEditModal = false;

  keyword: string = '';
  selectedProfile;
  qrCodeUrl;
  result$!: Observable<any>;

  base64Image: string;
  base64String: string;

  salutation: string;
  firstName: string;
  lastName: string;
  company: string;
  occupation: string;
  country: string;

  constructor(
    private route: ActivatedRoute,
    private badgeService: BadgeService,
    private printer: PrinterService,
    private attendeeService: AttendeesService) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.searchInput.nativeElement.focus();
      this.searchInput.nativeElement.select();
    }, 1000);

    this.result$ = this.searchText$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(keyword =>
        this.badgeService.search(keyword))
    );

  }

  ngAfterViewInit(): void {
    const profileId = this.route.snapshot.paramMap.get('profileId');
    if (profileId && profileId.length > 1) {
      this.searchInput.nativeElement.value = profileId;
      this.search(profileId);
    }
  }

  getValue(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }

  search(keyword) {
    this.keyword = keyword;
    this.searchText$.next(keyword);
  }

  onSelect(profile) {
    this.selectedProfile = profile;
    this.salutation = this.selectedProfile.salutation;
    this.firstName = this.selectedProfile.firstName;
    this.lastName = this.selectedProfile.lastName;
    this.occupation = this.selectedProfile.occupation;
    this.company = this.selectedProfile.company;
    this.country = this.selectedProfile.country?.name;

    this.qrCodeUrl = '';
    this.getProfileQRCodeURL();
  }

  getProfileQRCodeURL() {
    this.attendeeService.getAttendeeQrCodeURL(this.selectedProfile.id).subscribe({
      next: (result) => {
        this.qrCodeUrl = result.url;
        const urls: string[] = this.qrCodeUrl.split('/');
        const qrId = urls[urls.length - 1];
        this.qrCodeUrl = `${window.location.origin}/api/uploads/qr_codes/${qrId}`;
      },
      error: (er) => console.log(er),
    })
  }

  back() {
    this.selectedProfile = null;
    this.searchText$.next(this.keyword);
  }

  print(attendee) {
    this.onSelect(attendee);
    const width = 377.95;
    const height = 529.13;
    const fileName = `${this.selectedProfile.firstName}${this.selectedProfile.lastName}`
    this.printer.downloadImage(this.badge, width, height, fileName);
  }

  toggleModal() {
    this.isEditModal = !this.isEditModal;
  }

  save() {
    this.toggleModal();
  }

  cancel() {
    this.salutation = this.selectedProfile.salutation;
    this.firstName = this.selectedProfile.firstName;
    this.lastName = this.selectedProfile.lastName;
    this.occupation = this.selectedProfile.occupation;
    this.company = this.selectedProfile.company;
    this.country = this.selectedProfile.country?.name;
    this.toggleModal();
  }
}
