<div class="pt-11 pb-20 px-11">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto max-w-5xl lg:mx-0">
      <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Keep your participants engaged</h2>
      <p class="mt-6 text-lg leading-8 text-gray-600">
        Ensuring attendees are kept informed and engaged, we send instant notifications regarding event details and any schedule changes.
        Using email communication channel, ensures that participants receive updates in their preferred format.
      </p>
    </div>
    <div class="mx-auto mt-16 max-w-2xl lg:max-w-none">
      <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
        <div routerLink="../email-notifications" class="flex flex-col border border-slate-200 px-4 py-6 rounded-md hover:cursor-pointer hover:bg-slate-100">
          <dt class="text-base font-semibold leading-7 text-gray-900">
            <div class="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-main">
              <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="m7.875 14.25 1.214 1.942a2.25 2.25 0 0 0 1.908 1.058h2.006c.776 0 1.497-.4 1.908-1.058l1.214-1.942M2.41 9h4.636a2.25 2.25 0 0 1 1.872 1.002l.164.246a2.25 2.25 0 0 0 1.872 1.002h2.092a2.25 2.25 0 0 0 1.872-1.002l.164-.246A2.25 2.25 0 0 1 16.954 9h4.636M2.41 9a2.25 2.25 0 0 0-.16.832V12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 12V9.832c0-.287-.055-.57-.16-.832M2.41 9a2.25 2.25 0 0 1 .382-.632l3.285-3.832a2.25 2.25 0 0 1 1.708-.786h8.43c.657 0 1.281.287 1.709.786l3.284 3.832c.163.19.291.404.382.632M4.5 20.25h15A2.25 2.25 0 0 0 21.75 18v-2.625c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125V18a2.25 2.25 0 0 0 2.25 2.25Z" />
              </svg>
            </div>
            Ensure Engagement
          </dt>
          <dd class="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
            <p class="flex-auto">Send an email notification inviting participants to access the platform to ensure everyone is ready and engaged for the event.</p>
            <p class="mt-6">
              <a routerLink="../email-notifications" class="text-sm font-semibold leading-6 text-main">Get started here <span aria-hidden="true">→</span></a>
            </p>
          </dd>
        </div>
        <div routerLink="../custom-email" class="flex flex-col border border-slate-200 px-4 py-6 rounded-md hover:cursor-pointer hover:bg-slate-100">
          <dt class="text-base font-semibold leading-7 text-gray-900">
            <div class="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-main">
              <svg class="size-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5" />
              </svg>
            </div>
            Boost Engagement
          </dt>
          <dd class="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
            <p class="flex-auto">Sending personalized emails will make each participant feel valued and more connected to the event.</p>
            <p class="mt-6">
              <a routerLink="../custom-email" class="text-sm font-semibold leading-6 text-main">Get started here <span aria-hidden="true">→</span></a>
            </p>
          </dd>
        </div>
      </dl>
    </div>

    <div class="mt-16 mx-auto max-w-5xl lg:mx-0">
      <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Editing Default Email Templates</h2>
      <p class="mt-6 text-lg leading-8 text-gray-600">
        Editing default email templates allows event organizers to tailor their communications to better suit the unique needs and tone of each event. This customization ensures that all messages remain relevant, engaging, and aligned with the event's branding and objectives.
      </p>
    </div>
    <div class="mx-auto mt-16 max-w-2xl lg:max-w-none">
      <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
        <div routerLink="../confirmation-of-registration" class="flex flex-col border border-slate-200 px-4 py-6 rounded-md hover:cursor-pointer hover:bg-slate-100">
          <dt class="text-base font-semibold leading-7 text-gray-900">
            <div class="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-main">
              <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
              </svg>
            </div>
            Confirmation Of Registration Email
          </dt>
          <dd class="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
            <p class="flex-auto">Enables event organizers to provide attendees with precise and personalized details.</p>
            <p class="mt-6">
              <a routerLink="../confirmation-of-registration" class="text-sm font-semibold leading-6 text-main">Get started here <span aria-hidden="true">→</span></a>
            </p>
          </dd>
        </div>
      </dl>
    </div>
  </div>
</div>
