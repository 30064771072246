import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  isMenu : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isOffCanvasMenu : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isOffCanvasMenuDialog : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  toggleOffCanvasMenu(){
    this.isOffCanvasMenu.next(!this.isOffCanvasMenu.value);
    
    if (this.isOffCanvasMenuDialog.value){
      setTimeout(() => {
        this.isOffCanvasMenuDialog.next(!this.isOffCanvasMenuDialog.value);
      },400)
    } else {
      this.isOffCanvasMenuDialog.next(!this.isOffCanvasMenuDialog.value);
    }
  }

  toggleMenu(){
    this.isMenu.next(!this.isMenu.value);
  }


}
