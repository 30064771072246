import { Component, OnDestroy, OnInit } from '@angular/core';
import { PortalFlowService } from '../portal-flow/portal-flow.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { OrganisationService } from 'src/app/shared/service/organisation.service';
import { Organisation } from 'src/app/shared/model/organisation.model';
import { Observable, Subscription } from 'rxjs';
import {  UserService } from 'src/app/shared/service/session.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';

@Component({
  selector: 'portal-navbar',
  templateUrl: './portal-navbar.component.html',
  styleUrls: ['./portal-navbar.component.scss'],
  animations: [
    trigger('opacityScale', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(.95)' }),
        animate('200ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('75ms ease-in', style({ opacity: 0, transform: 'scale(.95)' }))
      ])
    ]),
    trigger('opacityTranslateXY', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translate(-50%,0.25rem)' }),
        animate('200ms ease-out', style({ opacity: 1, transform: 'translate(-50%,0)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translate(-50%,0)' }),
        animate('150ms ease-in', style({ opacity: 0, transform: 'translate(-50%,0.25rem)' }))
      ])
    ]),
  ]
})
export class PortalNavbarComponent implements OnInit, OnDestroy {
  isMenu = false;
  isFlyoutMenu = false;

  organisations: Organisation[] = [];
  organisationsSub: Subscription | undefined;

  selectedOrg: Observable<Organisation> | undefined;

  constructor(
    private portalFlowService: PortalFlowService,
    private organisationService: OrganisationService,
    private authenication: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.loadOrganisations();
    this.selectedOrg = this.organisationService.selectedOrg;
  }

  ngOnDestroy(): void {
    if (this.organisationsSub) this.organisationsSub.unsubscribe();
  }

  loadOrganisations() {
    this.organisationService.organisations.subscribe({
      next: (orgs) => {
        if (orgs.length == 0) {
          this.togglePortalFlow();
        } else {
          this.organisations = orgs
        }
      },
    });
  }

  onChangeOrganisation(organisation: Organisation) {
    this.organisationService.setSelected(organisation);
    this.toggleFlyoutMenu();
  }

  toggleMenu() {
    this.isMenu = !this.isMenu;
  }

  toggleFlyoutMenu() {
    this.isFlyoutMenu = !this.isFlyoutMenu;
  }

  togglePortalFlow() {
    this.isFlyoutMenu = false;
    this.portalFlowService.toggle();
  }

  logout(){
    this.authenication.signout();
  }

}
