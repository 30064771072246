import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ItemSelect } from 'src/app/dynamic-form/custom/models/select-item.model';
import { WantedDeal } from '../model/shared/wanted-deal.model';

@Injectable({
  providedIn: 'root'
})
export class WantedDealsService {

  wantedDeals: WantedDeal[] = [
    { id: 'joint_venture', label: 'Joint Venture', description: 'I want to find a business with complimentary strenths to form a Joint venture.'},
    { id: 'purchase_buying', label: 'Purchase/Buying', description: 'I\'m looking for good or services to purchase.'},
    { id: 'outsourcing', label: 'Outsourcing', description: 'I want to hire another company to be responsible for a business activity that could be done internally.'},
    { id: 'financing_facility', label: 'Financing Facility', description: 'I\'m looking for solutions to finance the growth of my company.'},
    { id: 'supply_selling', label: 'Supply/Selling', description: 'I want to sell my products and services.'},
    { id: 'experience_sharing', label: 'Experience Sharing', description: 'I want to share my experience and learn from other participant\'s experiences.'},
  ];


  constructor() { }



  getWantedDeals(){
    let items: ItemSelect[] = [];

    this.wantedDeals.map(item => {
      items.push({
        id: item.id, 
        label: item.label, 
        subElements: [],
      })
    });

    return of(items);
  }

  filterWantedDealsByKeyword(keyword: string): Observable<any> {
    const result = this.wantedDeals.filter(wantedDeal => wantedDeal.label.toLowerCase().includes(keyword.toLowerCase()));
    return of(result);
  }
}
