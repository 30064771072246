import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NotificationService } from 'src/app/shared/components/notifications/notification.service';
import { NotificationType } from 'src/app/shared/model/enum/notification-type.enum';
import { User } from 'src/app/shared/model/user.model';
import { Profile } from 'src/app/shared/model/user/profile.model';
import { AttendeesService } from 'src/app/shared/service/attendees.service';
import { EmailService } from 'src/app/shared/service/email.service';

@Component({
  selector: 'attendee-actions',
  templateUrl: './attendee-actions.component.html',
  styleUrl: './attendee-actions.component.scss',
  animations: [
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 }))
      ])
    ]),
    trigger('opacityTranslateY', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(1rem)' }))
      ])
    ])
  ]
})
export class AttendeeActionsComponent implements OnChanges {
  @Input('attendee') attendee: Profile;
  @Input('user') user: User;

  isSendingEmail: string = '';
  isRemoveDialog: boolean = false;
  isRemoving: boolean = false;
  loading: string = '';

  constructor(
    private emailService: EmailService,
    private notificationService: NotificationService,
    private attendeesService: AttendeesService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
  }

  sendUpdatePasswordEmail() {
    this.isSendingEmail = 'verification';
    this.emailService
      .sendUpdatePasswordEmail(this.attendee.user.id)
      .subscribe({
        next: _ => {
          this.isSendingEmail = '';
          this.notificationService.notify('Verification Email', 'The email has been successfully sent.', NotificationType.Success);
        },
        error: _ => this.isSendingEmail = '',
      });
  }

  sendConfirmationEmail() {
    this.isSendingEmail = 'confirmation';
    this.emailService
      .sendConfirmationEmail(this.attendee.id)
      .subscribe({
        next: _ => {
          this.isSendingEmail = '';
          this.notificationService.notify('Verification Email', 'The email has been successfully sent.', NotificationType.Success);
        },
        error: _ => this.isSendingEmail = '',
      });
  }

  remove() {
    this.isRemoving = true;
    this.attendeesService
      .removeAttendee(this.attendee.id)
      .subscribe({
        next: _ => {
          this.isRemoving = false;
          this.toggleRemoveDialog();
          this.notificationService.notify('Attendee', 'This attendee is no longuer registred in this event.', NotificationType.Success);
        },
        error: _ => {
          this.toggleRemoveDialog();
          this.isRemoving = false;
        },
      })
  }

  toggleRemoveDialog() {
    this.isRemoveDialog = !this.isRemoveDialog;
  }

  setEventPresence() {
    this.loading = 'event_presence';
    this.attendeesService
      .setEventPresence(this.attendee.id)
      .subscribe({
        next: profile => {
          this.attendee.presentAtEventVenue = true;
          this.loading = '';
        },
        error: _ => {
          this.loading = '';
        }
      });
  }

}
