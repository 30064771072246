import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventType } from 'src/app/shared/model/enum/event-type.enum';
import { Event } from 'src/app/shared/model/event/event.model';
import { UpdateEventTypeRequest } from 'src/app/shared/model/event/update-event-type.request';
import { EventService } from 'src/app/shared/service/event.service';
import { LastUpdatesTrackerService } from 'src/app/shared/service/last-updates-tracker.service';

interface ListItem {
  id: string;
  title: string;
  description: string;
}

@Component({
  selector: 'event-type',
  templateUrl: './event-type.component.html',
  styleUrls: ['./event-type.component.scss', '../event-information.component.scss']
})
export class EventTypeComponent implements OnInit, OnDestroy {
  event: Event;
  saving: boolean = false;
  eventSub: Subscription;


  list: ListItem[] = [
    {
      id: EventType.Exhibition,
      title: EventType.Exhibition,
      description: "Ideal for companies to showcase products, services, or artworks to a large audience, often featuring booths."
    },
    {
      id: EventType.Conference,
      title: EventType.Conference,
      description: "Perfect for bringing together professionals to discuss industry trends, share knowledge, and network."
    }
  ]

  currentId: string | undefined;

  constructor(
    private eventService: EventService,
    private updateTracker: LastUpdatesTrackerService
  ) { }

  ngOnInit(): void {
    this.eventSub = this.eventService.selectedEvent.subscribe({
      next: event => {
        this.event = event;
        this.currentId = this.event.type;
      },
    });
  }

  ngOnDestroy(): void {
    if (this.eventSub) this.eventSub.unsubscribe();
  }

  select(id: string) {
    this.currentId = id;
    let request: UpdateEventTypeRequest = new UpdateEventTypeRequest('', EventType[this.currentId]);
    this.eventService.updateEventType(request);
  }

  trackUpdate(menu: string) {
    this.updateTracker.newUpdate(menu);
  }


}
