<div class="grid sm:grid-cols-1 grid-flow-col-dense gap-3 md:grid-cols-2">
    <div>
        <a (click)="openCommunityModal()"
            class="font-medium inline-flex text-main hover:text-darker-blue items-center gap-x-1 text-xs md:text-base md:pl-3 hover:cursor-pointer">
            <svg class="w-3 h-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
            New community
        </a>
        <ul role="list" class="mt-5 grid grid-cols-1 gap-x-1 gap-y-10 md:pl-3">
            <li *ngFor="let community of communities" class="relative">
                <portal-community [community]="community"></portal-community>
            </li>
        </ul>
    </div>

    <div role="list" class="flex flex-col gap-y-5 px-4">
        <div class="flex items-center justify-between">
            <a class="text-xs text-gray-400 font-light">
                Last Updates
            </a>
            <a (click)="openEventModal()" class="font-medium inline-flex text-main hover:text-darker-blue items-center gap-x-1 text-xs md:text-base pr-1 hover:cursor-pointer line-clamp-1">
                <svg class="w-3 h-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                    stroke-width="1.5" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                </svg>
                New Event
            </a>
        </div>
        <div *ngFor="let event of events">
            <portal-event [event]="event"></portal-event>
        </div>
    </div>
</div>


<portal-new-community [isModal]="isCommunityModal" (closeModal)="closeCommunityModal()"></portal-new-community>
<portal-new-event [isModal]="isEventModal" (closeModal)="closeEventModal()"></portal-new-event>