import { Component, EventEmitter, OnDestroy } from "@angular/core";
import { FieldType, FieldTypeConfig } from "@ngx-formly/core";
import { Subject, debounceTime, distinctUntilChanged, filter, startWith, switchMap, takeUntil } from "rxjs";
import { ItemSelect } from "../../models/select-item.model";

@Component({
  selector: 'formly-offer-input',
  templateUrl: './formly-offer-input.component.html',
  styleUrls: ['./formly-offer-input.component.scss']
})
export class FormlyOfferInputComponent extends FieldType<FieldTypeConfig> implements OnDestroy {
  onDestroy$ = new Subject<void>();
  search$ = new EventEmitter();
  showDropdown: boolean = false;
  selected: ItemSelect[] = [];
  options$;

  ngOnInit() {
      this.options$ = this.search$.pipe(
          takeUntil(this.onDestroy$),
          startWith(''),
          filter(v => v !== null),
          debounceTime(200),
          distinctUntilChanged(),
          switchMap(this.to.search$),
      );
      this.options$.subscribe();
  }

  search($event) {
      this.search$.emit($event.target.value);
  }

  ngOnDestroy(): void {
      this.onDestroy$.complete();
  }

  toggle() {
      this.showDropdown = !this.showDropdown;
  }

  select(option: ItemSelect) {
      const index = this.selected.findIndex(element => element.id == option.id);
      if (index == -1) {
          this.selected.push(option);
          this.formControl.setValue([...this.selected.map(item => item.id)]);
      } else {
          this.removeSelected(option);
      }

      this.toggle();
  }

  removeSelected(item) {
      this.selected = this.selected.filter(element => element.id != item.id);
      this.formControl.setValue([...this.selected.map(item => item.id)]);
  }

  isSelected(item) {
      const index = this.selected.findIndex(element => element.id == item.id);
      return index != -1;
  }

}