import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PortalRoutingModule } from './portal-routing.module';
import { PortalComponent } from './portal.component';
import { PortalCommunitiesComponent } from './portal-communities/portal-communities.component';
import { PortalCommunityComponent } from './portal-communities/portal-community/portal-community.component';
import { PortalEventComponent } from './portal-communities/portal-event/portal-event.component';
import { PortalFlowComponent } from './portal-flow/portal-flow.component';
import { CreateOrganisationComponent } from './portal-flow/create-organisation/create-organisation.component';
import { CreateCommunityComponent } from './portal-flow/create-community/create-community.component';
import { CreateEventComponent } from './portal-flow/create-event/create-event.component';
import { SharedModule } from '../shared/shared.module';
import { PortalNavbarComponent } from './portal-navbar/portal-navbar.component';
import { NewCommunityComponent } from './portal-communities/new-community/new-community.component';
import { NewEventComponent } from './portal-communities/new-event/new-event.component';


@NgModule({
  declarations: [
    PortalComponent,
    PortalCommunitiesComponent,
    PortalCommunityComponent,
    PortalEventComponent,
    PortalFlowComponent,
    CreateOrganisationComponent,
    CreateCommunityComponent,
    CreateEventComponent,
    PortalNavbarComponent,
    NewCommunityComponent,
    NewEventComponent
  ],
  imports: [
    CommonModule,
    PortalRoutingModule,
    SharedModule
  ]
})
export class PortalModule { }
