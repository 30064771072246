import { Component, OnDestroy, OnInit, EventEmitter, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { Event } from 'src/app/shared/model/event/event.model';
import { EventService } from 'src/app/shared/service/event.service';

@Component({
  selector: 'nav-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {
  isEditorMenu: boolean = false;
  isRegistrationMenu: boolean = false;
  isMeetingMenu: boolean = false;
  isNotificationMenu: boolean = false;
  
  event: Event = new Event();
  eventSub: Subscription;
  @Output() toggleOffCanvasMenuEvent = new EventEmitter<void>();

  constructor(
    private eventService: EventService
  ) { }

  ngOnInit(): void {
    this.eventSub = this.eventService.selectedEvent.subscribe({
      next: event => this.event = event,
    })
  }

  ngOnDestroy(): void {
    if (this.eventSub) this.eventSub.unsubscribe();
  }

  toggleOffCanvasMenu() {
    this.toggleOffCanvasMenuEvent.emit();
  }

  toggleEditorMenuMenu() {
    this.isEditorMenu = !this.isEditorMenu;
  }

  toggleRegistrationMenu() {
    this.isRegistrationMenu = !this.isRegistrationMenu;
  }

  toggleMeetingMenu() {
    this.isMeetingMenu = !this.isMeetingMenu;
  }

  toggleNotificationMenu() {
    this.isEditorMenu = false;
    this.isRegistrationMenu = false;
    this.isMeetingMenu = false;
    this.isNotificationMenu = !this.isNotificationMenu;
  }

}
