import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';
import { CustomFieldHelperService } from 'src/app/dynamic-form/services/custom-field-helper.service';
import { FieldBuilderService } from 'src/app/dynamic-form/services/field-builder.service';
import { SchemaBuilderHelper, InputForm } from 'src/app/dynamic-form/services/schema-builder-helper.service';
import { SchemaBuilderService } from 'src/app/dynamic-form/services/schema-builder.service';
import { NotificationService } from 'src/app/shared/components/notifications/notification.service';
import { NotificationType } from 'src/app/shared/model/enum/notification-type.enum';

@Component({
  selector: 'form-input-selector',
  templateUrl: './input-selector.component.html',
  styleUrl: './input-selector.component.scss',
  animations: [
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 }))
      ])
    ]),
    trigger('opacityTranslateY', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(1rem)' }))
      ])
    ])
  ]
})
export class InputSelectorComponent implements OnInit {
  @Input('isModal') isModal: boolean = false;
  @Input('customInput') customInput: InputForm;
  @Output('onClose') onClose: EventEmitter<boolean> = new EventEmitter(false);
  isReady: boolean = false;

  form: FormGroup;
  options: FormlyFormOptions;
  fields: FormlyFieldConfig[];
  model: {};

  constructor(
    private customFieldHelper: CustomFieldHelperService,
    private schemaBuilderHelper: SchemaBuilderHelper,
    private fieldBuilder: FieldBuilderService,
    private schemaBuilder: SchemaBuilderService,
    private formlyJsonschema: FormlyJsonschema,
    private notification: NotificationService
  ) { }

  ngOnInit(): void {
    this.setForm();
  }

  setForm() {
    this.isReady = false;
    this.customFieldHelper
      .getFieldSchemaByInput(this.customInput.name)
      .subscribe({
        next: ({ schema, model }) => {
          this.form = new FormGroup({});
          this.options = {};
          this.fields = [this.formlyJsonschema.toFieldConfig(schema)];
          this.model = model;
          this.isReady = true;
        }
      });
  }

  onSubmit() {
    const currentStep: number = this.schemaBuilderHelper.currentStepperIndex;
    if (currentStep == 3) {
      this.notification.notify('New field aborted', 'You cannot add a field in this step!', NotificationType.Warning);
      this.close();
      return;
    }
    const field = this.fieldBuilder.attacheNewField(this.schemaBuilder.getFormFields(), this.model, this.customInput.input, currentStep);
    this.schemaBuilder.updateSchema(this.schemaBuilder.getFormFields(), field, currentStep);
    this.close();
  }

  close() {
    this.isModal = false;
    this.reset();
    this.onClose.emit(true);
  }

  reset() {
    this.form = new FormGroup({});
    this.options = {};
    this.model = null;
    this.isReady = false;
  }

}
