<p-editor #editor [(ngModel)]="htmlContent" (ngModelChange)="onChange()" (onInit)="editorOnInit($event)"
  [style]="{ height: '320px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }">
  <p-header>
    <span class="ql-formats">
      <select class="ql-header" tabindex="-1">
        <option value="1" tabindex="-1">Heading</option>
        <option value="2" tabindex="-1">Subheading</option>
        <option value="3" tabindex="-1">Subheading 3</option>
        <option value="4" tabindex="-1">Subheading 4</option>
        <option value="5" tabindex="-1">Subheading 5</option>
        <option value="6" tabindex="-1">Subheading 6</option>
        <option selected tabindex="-1">Normal</option>
      </select>
      <select class="ql-font" tabindex="-1">
        <option selected tabindex="-1">Sans Serif</option>
        <option value="serif" tabindex="-1">Serif</option>
        <option value="monospace" tabindex="-1">Monospace</option>
      </select>
    </span>
    <span class="ql-formats">
      <button class="ql-bold" aria-label="Bold" tabindex="-1"></button>
      <button class="ql-italic" aria-label="Italic" tabindex="-1"></button>
      <button class="ql-underline" aria-label="Underline" tabindex="-1"></button>
      <button class="ql-strike" aria-label="Strike" tabindex="-1"></button>
    </span>
    <span class="ql-formats">
      <select class="ql-color" tabindex="-1"></select>
      <select class="ql-background" tabindex="-1"></select>
    </span>
    <span class="ql-formats">
      <button class="ql-direction" value="rtl" aria-label="Direction" tabindex="-1"></button>
    </span>
    <span class="ql-formats">
      <button class="ql-indent" value="+1" aria-label="Indent +1" tabindex="-1"></button>
      <button class="ql-indent" value="-1" aria-label="Indent -1" tabindex="-1"></button>
    </span>
    <span class="ql-formats">
      <button class="ql-list" value="ordered" aria-label="Ordered List" tabindex="-1"></button>
      <button class="ql-list" value="bullet" aria-label="Unordered List" tabindex="-1"></button>
      <select class="ql-align" tabindex="-1">
        <option selected tabindex="-1"></option>
        <option value="center" tabindex="-1"></option>
        <option value="right" tabindex="-1"></option>
        <option value="justify" tabindex="-1"></option>
      </select>
    </span>
    <span class="ql-formats">
      <button class="ql-link" aria-label="Insert Link" tabindex="-1"></button>
      <!-- <button class="ql-image" aria-label="Insert Image" tabindex="-1"></button> -->
      <button class="ql-blockquote" aria-label="Insert BLock Quote" tabindex="-1"></button>
    </span>
    <span class="ql-formats">
      <button class="ql-clean" aria-label="Remove Styles" tabindex="-1"></button>
    </span>
  </p-header>
</p-editor>
