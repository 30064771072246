import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { AppSettingService } from './app-setting.service';
import { EventService } from './event.service';

@Injectable({
  providedIn: 'root'
})
export class BadgeService {

  constructor(
    private httpClient: HttpClient,
    private eventService: EventService,
    private appSettings: AppSettingService
  ) { }

  search(idKeyword: string) {
    if (idKeyword.length < 3) return of([]);
    const eventId: string = this.eventService.selectedEvent.value.id;
    const url: string = `${this.appSettings.settings.url}/profile/search-for-badge?profileId=${idKeyword}&eventId=${eventId}`;
    const api$ = this.httpClient.get(url);
    return api$.pipe();
  }

}
