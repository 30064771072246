import { Component, Input, OnInit } from '@angular/core';
import { Event } from 'src/app/shared/model/event/event.model';
import { EventConfigurationService } from 'src/app/shared/service/event-configuration.service';

@Component({
  selector: 'event-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent implements OnInit {
  @Input('event') event: Event = new Event();
  @Input('configuration') configuration = '';
  @Input('description') description = '';
  isEnabled = false;
  isSaving: boolean = false;
  

  constructor(
    private eventConfigService: EventConfigurationService,
  ) { }

  ngOnInit(): void {
    this.isEnabled = this.eventConfigService.resovleSelectedSetting(this.configuration, this.event);
  }

  toggle() {
    this.eventConfigService.saveConfiguration(this.configuration).subscribe({
      next: _ => { 
        this.isSaving = false; 
        this.isEnabled = !this.isEnabled;
      },
      error: _ => this.isSaving = false,
    })
  }

}
