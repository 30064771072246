<a
  (click)="toggleModal()"
  class="text-slate-700 hover:text-main">
  <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
    <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
  </svg>
  <span class="sr-only">, {{ ticket.name }}</span>
</a>

<div
  @opacity
  *ngIf="isModal"
  class="relative z-[999]"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
  <div class="fixed inset-0 z-[999] overflow-y-auto">
    <div class="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
      <div
        @opacityTranslateY
        *ngIf="isModal"
        class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 w-full md:w-2/3 sm:p-6">

        <div>
          <div>
            <div class="sm:hidden">
              <label
                for="tabs"
                class="sr-only">
                Select a tab
              </label>
              <select
                id="tabs"
                name="tabs"
                class="block w-full rounded-md border-gray-300 focus:border-main/70 focus:ring-main/70">
                <option
                  (click)="onChange('vertical')"
                  selected>
                  Vertical
                </option>
                <option (click)="onChange('horizonal')">Horizonal</option>
              </select>
            </div>
            <!-- Current: "bg-gray-100 text-gray-700", Default: "text-gray-500 hover:text-gray-700" -->
            <div class="hidden sm:block">
              <nav
                class="flex justify-center space-x-5"
                aria-label="Tabs">
                <a
                  [ngClass]="{'bg-gray-100 text-gray-700': currentTab == 'vertical'}"
                  (click)="onChange('vertical')"
                  class="text-gray-500 hover:text-gray-700 rounded-md px-3 py-2 text-sm font-medium">
                  Vertical
                </a>
                <a
                  [ngClass]="{'bg-gray-100 text-gray-700': currentTab == 'horizonal'}"
                  (click)="onChange('horizonal')"
                  class="text-gray-500 hover:text-gray-700 rounded-md px-3 py-2 text-sm font-medium">
                  Horizonal
                </a>
              </nav>
            </div>
          </div>

          <div class="mt-4 p-3">
            <div *ngIf="currentTab == 'vertical'">
              <view-vertical [ticket]="ticket"></view-vertical>
            </div>

            <div *ngIf="currentTab == 'horizonal'">
              <view-horizontal [ticket]="ticket"></view-horizontal>
            </div>
          </div>
        </div>

        <div class="mt-5 py-1.2 w-full flex justify-center">
          <button
            (click)="toggleModal()"
            type="button"
            class="w-2/3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
